export default {
    /**
     * get all users
     * @param {*} state
     */
    all(state) {
        return state.users;
    },

    dashboardData(state) {
        return state.data.dashboard;
    },

    profile(state) {
        return state.data.profile;
    },

    downlines(state) {
        return state.data.downlines;
    },

    walletSummary(state) {
        return state.data.wallet.summary;
    },

    walletAnalysis(state) {
        return state.data.wallet.analysis;
    },

    withdrawalHistory(state) {
        return state.data.withdrawal_history;
    },


    levels(state) {
        return state.data.levels;
    },

    voucherClaimStatuses(state) {
        return state.data.voucher_claim_statuses;
    },

    incentiveClaimStatuses(state) {
        return state.data.incentive_claim_statuses;
    },


    totalWithdrawals(state) {
        return state.data.withdrawal_history.total;
    },

    withdrawalStatus(state) {
        return state.data.withdrawal_status;
    },

    pendingPinRegApprovals(state) {
        return state.data.pending_pin_reg_approvals;
    },

    pinPurchaseHistory(state) {
        return state.data.pin_purchase_history;
    },

    loanRequestHistory(state){
        return state.loanRequestHistory
    },
    loanEligibility(state){
        return state.loanEligibility
    },
    recentLoan(state){
        return state.recentLoan
    },

    pinMerchants(state){
        return state.pinMerchants
    },
    pinRegistrationStatus(state){
        return state.pinRegistrationStatus
    },
    pinRegistrationHistory(state){
        return state.pinRegistrationHistory
    },

    entryPaymentStatus(state){
        return state.entryPaymentStatus
    },

    grantClaimStatuses(state){
        return state.grantClaimStatuses
    },

    incentives(state){
        return state.incentives
    },

    qrCode(state){
        return state.qrCode
    },
    twoFactorAuthEnabled(state){
        return state.twoFactorAuthEnabled
    },

    genealogy(state){
        return state.genealogy
    },

    walletBalance(state){
        return state.walletBalance
    },

    loanBalance(state){
        return state.loanBalance
    },

    loanPaymentHistory(state){
        return state.loanPaymentHistory
    },
};