<template>
    <div>
        <top-bar :sideMenu="false"/>
        <div class="page has-sidebar-left p-1 ml-0">
            <b-card v-if="fetchingUser">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <div v-else class="row m-2 ">
                <div v-if="!user.is_verified" class="invoice white offset-md-3 col-md-6">
                    <div class=" p-5 ">
                        <div class="row">
                            <div class="">
                                <i class="icon-featured far fa-envelope m-0"></i>
                                <div class="divider divider-small divider-small-center">
                                </div>
                                <h2 class="font-weight-normal text-6"><strong class="font-weight-extra-bold">You did not recieve an email?</strong></h2>
                                <p>
                                    A message has been sent to your registered email address concerning your <b>Email Id Verification</b> Please read the instructions carefully.
                                </p>
                            </div>
                        </div>
                    </div>
                    <form method="POST" class="col-md-6 offset-md-3" id="resend-verification-form" action="/resendVerificationEmail">
                        <button type="submit" class="form-control btn btn-primary resend" id="resend-username">Resend</button>
                    </form>
                </div>
            </div>

            <template v-if="paymentIsApproved() || user.is_approved">
                <div class="alert alert-success text-center">Your payment has been approved, please 
                    <router-link :to="{name:'login'}" class="btn btn-primary">login</router-link> 
                    to cotinue
                </div>
            </template>
            
            <div v-else class="container invoice white shadow">
                <div class="row">
                    <div class="col-12 text-center">
                        <p> <img class="w-500px mb-4" src="/assets/img/welcome1.png" style="-webkit-filter: drop-shadow(3px 3px 3px #222); filter: drop-shadow(3px 3px 3px #222); max-width: 100%;" alt=""><br><img  src="assets/img/line.png"  style= "max-width: 100%;" alt=""></p>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <p ><strong class="font-weight-bold">Please Note that 48hours (2 Days) is the time given to complete your registration to avoid forfeiture.</strong></p>
                </div> 
                <hr>
                <br>

                <b-card v-if="fetchingUser">
                    <b-skeleton animation="throb" width="85%"></b-skeleton>
                    <b-skeleton animation="throb" width="55%"></b-skeleton>
                    <b-skeleton animation="throb" width="70%"></b-skeleton>
                </b-card>
                <update-bank v-else :user="user"/>

                <div class="col-12 text-center">
                    <img  src="assets/img/option1.png"  style="-webkit-filter: drop-shadow(3px 3px 3px #222); filter: drop-shadow(3px 3px 3px #222); max-width: 100%;" alt=""><br><br>
                    <p ><strong class="font-weight-bold">To complete your registration, Simply copy the bank details below to make your payment, then submit your POP below for verification.</strong></p>
                </div> 
                <div class="container-fluid my-3">
                    <div class="row row-eq-height my-3">
                        <!-- Product Widget Start -->
                        <bank-list v-for="i of 3" :key="i" /> 
                    </div>
                
                    <div v-if="pendingPaymentApproval() || pending" class="alert alert-info">
                        <h3 class="text-center">You have a pending payment approval</h3>
                    </div>
                    <upload-pop v-else @reg-submitted="update()" />
                    
                </div>
                <br>
                <hr>
                <div class="col-12 text-center">
                    <img  src="assets/img/option2.png"  style="-webkit-filter: drop-shadow(3px 3px 3px #222); filter: drop-shadow(3px 3px 3px #222); max-width: 100%;" alt=""><br><br>
                    <img  src="assets/img/paystack.png" width="50%" alt="">
                    <p><strong class="font-weight-bold">Complete your payment with paystack.</strong></p>
                    <div class="col-md-12 mb-3">
                        <button class="btn btn-success" type="submit" ><i class="icon-arrow_forward mr-2"></i>Proceed to Payment</button>
                    </div>
                </div> 

                <br>
                <hr>
                <br>			
                <div class="col-12 text-center">
                    <img  src="/assets/img/option3.png" style="-webkit-filter: drop-shadow(3px 3px 3px #222); filter: drop-shadow(3px 3px 3px #222); max-width: 100%;" alt=""><br><br>
                    <img  src="/assets/img/pin_reg.png"  alt="">
                    <p ><strong class="font-weight-bold">To Complete a Pin Registration Process, Please Follow the Steps Below</strong></p>
                </div>
                <div class="col-12 text-center">
                    <img  src="/assets/img/figures1.png" style="-webkit-filter: drop-shadow(3px 3px 3px #222); filter: drop-shadow(3px 3px 3px #222); max-width: 100%;" alt="">
                </div>				
                <!-- info row -->
                <div class="row my-3 ">
                    <div class="col-sm-4">
                        <address>
                        Contact any <strong>Memeber/Partner</strong><br>
                        that has bought Pins and pay<br>
                            the <strong>registration fee $2.4</strong><br>
                            in exchange for a pin.
                        </address>
                        
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-4">

                        <address>
                        The member you pay to will <br>
                        <strong>complete your registration by<br>
                        activating your backoffice with<br>
                            a Pin</strong> from his/her backoffice.
                        </address>
                    </div>

                    <!-- /.col -->
                    <div class="col-sm-4 font-bold">
                        <address>
                        You will recieve a <strong>notification email that<br>
                        your account is now active.</strong><br>
                        Then you can login to view your backoofice.
                        </address>

                    </div>
                    <!-- /.col -->
                </div>

                <div class="row my-3 ">
                    <div class="offset-md-3 col-6">
                        <div v-if="pendingPaymentApproval() || pending" class="alert alert-info">
                            <h3>You have a pending payment approval</h3>
                        </div>
                        <pin-registration @reg-submitted="update()" v-else :user="user"/>
                    </div>
                </div>
                
                <div class="col-12 text-center">
                    <h4 class="font-weight-bold"> Disclaimer... </h4>
                    <p >Please note that the price for each pin is $2.4 Members should comply with this. We will note be held responsible for any high or low payment on pin transactions between members.</p>
                </div>
            </div>
        </div>
        
    </div>
</template>
<style scoped>
    .loader {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #F5F8FA;
        z-index: 9998;
        text-align: center;
    }

    .plane-container {
        position: absolute;
        top: 50%;
        left: 50%;
    }
		
    .body{
        text-align: center;
        background: #00ECB9;
        font-family: sans-serif;
        font-weight: 100;
    }

    .h1{
        color: #396;
        font-weight: 100;
        font-size: 40px;
        margin: 40px 0px 20px;
    }

    #clockdiv{
        font-family: sans-serif;
        color: #fff;
        display: inline-block;
        font-weight: 100;
        text-align: center;
        font-size: 30px;
    }

    #clockdiv > div{
        padding: 10px;
        border-radius: 3px;
        background: #00BF96;
        display: inline-block;
    }

    #clockdiv div > span{
        padding: 15px;
        border-radius: 3px;
        background: #00816A;
        display: inline-block;
    }

    .smalltext{
        padding-top: 5px;
        font-size: 16px;
    }
</style>

<script>
import TopBar from '@/components/user/TopBar'
import UpdateBank from '@/components/user/unapproved/UserBankDetails'
import BankList from '@/components/user/unapproved/BankList'
import UploadPop from '@/components/user/unapproved/UploadPop'
import {mapState,mapActions,mapGetters} from 'vuex'
import PinRegistration from '@/components/user/unapproved/PinRegistration'
export default {
    components:{
        TopBar,
        UpdateBank,
        BankList,
        UploadPop,
        PinRegistration
    },
    data(){
        return{
            pending:false,
            approved:false
        }
    },
    computed:{
        ...mapState({
            processing:state=>state.processing
        }),
        ...mapGetters(['user']),

        ...mapGetters('userStore',['pinRegistrationStatus','entryPaymentStatus']),

        fetchingUser: function(){return Object.entries(this.user).length==0 ? true: false},
        
    },
    created(){
        if(Object.entries(this.user).length==0){
            this.getUser()
        }

        if(Object.entries(this.pinRegistrationStatus).length==0){
            this.getPinRegistrationStatus()
        }

        if(Object.entries(this.entryPaymentStatus).length==0){
            this.getEntryPaymentStatus()
        }
    },
    methods:{
        ...mapActions('userStore',['getUser','getPinRegistrationStatus','getEntryPaymentStatus']),

        pinRegistrationIsPending(){
            if(this.pinRegistrationStatus && this.pinRegistrationStatus.status == 'pending'){
                return true
            }
            return false
        },

        entryPaymentIsPending(){
            if(this.entryPaymentStatus && this.entryPaymentStatus.status == 'pending'){
                return true
            }
            return false
        },

         /**
         * check if payment is pending approval
         */
        pendingPaymentApproval(){
            if(this.pinRegistrationIsPending() || this.entryPaymentIsPending()){
                return false
            }else{
                return false
            }    
        },

        pinRegistrationIsApproved(){
            if(this.pinRegistrationStatus && this.pinRegistrationStatus.status == 'approved'){
                return true
            }
            return false
        },

        entryPaymentIsApproved(){
            if(this.entryPaymentStatus && this.entryPaymentStatus.status == 'approved'){
                return true
            }
            return false
        },

        /**
         * check if payment is approved
         */
        paymentIsApproved(){
            if(this.pinRegistrationIsApproved() || this.entryPaymentIsApproved()){
                return false
            }else{
                return false
            } 
        },

        update(){
            this.pending = true
        }
        
    }

}
</script>