<template>
    <div class="tab-pane fade" id="v-pills-Withdrawal-History" role="tabpanel" aria-labelledby="v-pills-Withdrawal-History-tab">
        <div class="row">
            <div class="col-md-12">
                <div class="card no-b">
                    <div class="card-header bg-white">
                        <h4 class="green-text"><strong class="font-weight-bold">Recent Loan Payback</strong></h4>
                    </div>
                    <div class="collapse show" id="invoiceCard">
                        <div class="card-body p-0">
                            <div class="card no-b  no-r">
                                <div class="card-body">
                                    <div class="form-row" style="overflow-x:auto;">
                                            <table id="example2" class="table table-bordered table-hover data-tables"
                                                data-options='{ "paging": false; "searching":false}'>
                                                <thead>
                                                <tr>
                                                    <th scope="col">S/N</th>
                                                    <th scope="col">Payment Mode</th>
                                                    <th scope="col">Loan Paid</th>
                                                    <th scope="col">Date</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="loading">
                                                        <td colspan="4"><b-card >
                                                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                                                            <b-skeleton animation="throb" width="55%"></b-skeleton>
                                                            <b-skeleton animation="throb" width="70%"></b-skeleton>
                                                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                                                        </b-card></td>
                                                    </tr>
                                                    <template v-else>
                                                        <tr v-for="payment,index in loanPaymentHistory" :key="index">
                                                            <td>{{++index}}</td>
                                                            <td>{{payment.payment_mode}}</td>
                                                            <td>{{payment.amount}}</td>
                                                            <td>{{payment.created_at}}</td>
                                                        </tr>
                                                    
                                                        <tr><td colspan="6"><span class="font-weight-bold text-green">Loan Payback Status</span></td></tr>																
                                                        <tr><td class="font-weight-light text-white bg-success" colspan="2">Loan Amount</td><td >${{recentLoan.loan_amount}}</td>
                                                        <td class="font-weight-light text-white bg-success" colspan="2">Total Loan Paid</td><td>${{recentLoan.loan_amount - loanBalance}}</td></tr>
                                                        <tr><td class="font-weight-light text-white bg-success" colspan="2">Loan Amount Balance</td><td>${{loanBalance}}</td>
                                                        <td class="font-weight-light text-white bg-success" colspan="2">Loan Status</td>
                                                        <td><span class="badge text-white bg-red">Still owing</span></td></tr>
                                                    </template>
                                                </tbody>
                                        </table>												
                                    </div>
                                </div>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>	
        
        <div class="row">
            <div class="col-md-12">
                <div class="card no-b">
                    <div class="card-header bg-white">
                        <h4 class="green-text"><strong class="font-weight-bold">Loan Transaction History</strong></h4>
                    </div>
                    <div class="collapse show" id="invoiceCard">
                        <div class="card-body p-0">
                            <div class="card no-b  no-r">
                                <div class="card-body">
                                    <div class="form-row" style="overflow-x:auto;">
                                        <table id="example2" class="table table-bordered table-hover data-tables"
                                                data-options='{ "paging": true; "searching":true}'>
                                            <thead>
                                                <tr>
                                                    <th scope="col">S/N</th>
                                                    <th scope="col">Level</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Loan Transaction Status</th>
                                                    <th scope="col">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="loading">
                                                    <td colspan="4"><b-card >
                                                        <b-skeleton animation="throb" width="85%"></b-skeleton>
                                                        <b-skeleton animation="throb" width="55%"></b-skeleton>
                                                        <b-skeleton animation="throb" width="70%"></b-skeleton>
                                                        <b-skeleton animation="throb" width="85%"></b-skeleton>
                                                    </b-card></td>
                                                </tr>
                                                <tr v-else v-for="loan,index in loanRequestHistory" :key="index">
                                                    <td>{{++index}}</td>
                                                    <td>{{loan.level_id}}</td>
                                                    <td>${{loan.loan_amount}}</td>
                                                    <td><span class="badge text-white bg-green">{{loan.loan_status}}</span></td>
                                                    <td>{{loan.created_at}}</td>
                                                </tr>																
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions,mapGetters,mapState} from 'vuex'
import toastr from 'toastr'
export default {

    computed:{
        ...mapState({
            loading:state=>state.loading
        }),
        ...mapGetters('userStore',['recentLoan','loanPaymentHistory','loanRequestHistory','loanBalance']),
        
    },
    
    created(){
        if(this.loanRequestHistory.length == 0){
            this.getLoanRequestHistory()
        }

        if(Object.entries(this.recentLoan).length == 0){
            this.getRecentLoan().then(res=>{
                this.getLoanPaymentHistory(res.id)
                this.getLoanBalance(res.id)
            }).catch(err=>{
                console.log(err)
                this.$store.commit('loaded',null,{root:true})
                toastr.error('An internal error occured')
            })
        }
    },

    methods:{
        ...mapActions('userStore',['getLoanPaymentHistory','getLoanRequestHistory','getRecentLoan','getLoanBalance'])
    }
}
</script>