<template>
    <aside class="main-sidebar fixed offcanvas shadow" data-toggle='offcanvas'>
        <section class="sidebar">
            <div class="w-80px mt-3 mb-3 ml-3 ">
                <img src="/assets/img/basic/logo6.png" alt="">
            </div>
            <div class="relative">
                <a data-toggle="collapse" href="#userSettingsCollapse" role="button" aria-expanded="false"
                aria-controls="userSettingsCollapse" class="btn-fab btn-fab-sm absolute fab-right-bottom fab-top btn-success shadow1 ">
                    <i class="icon icon-arrow-down"></i>
                </a>
                <div class="user-panel p-3 light mb-2">
                    <div>
                        <div class="float-left image">
                            <img class="user_avatar" src="/assets/img/dummy/u2.png" alt="User Image">
                        </div>
                        <div class="float-left info">
                            <h6 class="font-weight-light mt-2 mb-1">Admin</h6>
                            <a class="text-success" href="#" ><i class="icon-circle text-success blink"></i> Online</a>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="collapse multi-collapse" id="userSettingsCollapse">
                        <div class="list-group mt-3 shadow">
                            <a href="index.html" class="list-group-item list-group-item-action ">
                                <i class="mr-2 icon icon-sign-out text-green"></i><span class="green-text">Logout</span>
                            </a>
                            <a href="#" class="list-group-item list-group-item-action"><i
                                    class="mr-2 icon-key5 text-green"></i><span class="green-text">Change Password</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="sidebar-menu">
                <li class="treeview active">
                    <router-link :to="{name:'admin-dashboard'}" >
                        <i class="icon icon-barometer2 green-text s-18"></i>
                        <span class="green-text">Dashboard</span> 
                    </router-link>
                </li>
                <li class="treeview">
                    <router-link :to="{name:'admin-profile'}">
                        <i class="icon icon-user green-text s-18"></i>
                        <span class="green-text">Profile</span>
                    </router-link>
                </li>
                <li class="treeview">
                    <router-link :to="{name:'users'}">
                        <i class="icon icon-people green-text s-18"></i>
                        <span class="green-text">Users</span>
                    </router-link>
                </li>
            
                <li class="treeview ">
                    <router-link :to="{name:'system-settings'}">
                        <i class="icon icon-sitemap green-text s-18"></i>
                        <span class="green-text">System Settings</span>
                    </router-link>
                </li>

                <li class="treeview">
                    <a class="green-text"><i class="icon icon-account_balance green-text s-18"></i>Entry Payments<i
                        class="icon icon-angle-left s-18 pull-right"></i></a>
                    <ul class="treeview-menu">
                        <li>
                            <router-link :to="{name:'pending-entry-payments'}">
                                <i class="icon icon-account_balance_wallet"></i>Pending Entry Payments 
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'entry-payment-history'}">
                                <i class="icon icon-account_balance_wallet"></i>Entry Payment History 
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class="treeview">
                    <a class="green-text"><i class="icon icon-account_balance green-text s-18"></i>Loans<i
                        class="icon icon-angle-left s-18 pull-right"></i></a>
                    <ul class="treeview-menu">
                        <li>
                            <router-link :to="{name:'pending-loan-request-approvals'}">
                                <i class="icon icon-account_balance_wallet"></i>Pending Loan Approvals 
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'loan-request-history'}">
                                <i class="icon icon-account_balance_wallet"></i>Loan request history 
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'pending-loan-payment-approvals'}">
                                <i class="icon icon-account_balance_wallet"></i>Pending loan payments 
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'loan-payment-history'}">
                                <i class="icon icon-account_balance_wallet"></i>Loan Payments History
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class="treeview">
                    <a class="green-text"><i class="icon icon-account_balance green-text s-18"></i>Grant<i
                        class="icon icon-angle-left s-18 pull-right"></i></a>
                    <ul class="treeview-menu">
                        <li>
                            <router-link :to="{name:'user-wallet'}">
                                <i class="icon icon-account_balance_wallet"></i>Pending Grant
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'user-loan'}">
                                <i class="icon icon-money-bag"></i>Grant claim history
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class="treeview">
                    <a class="green-text"><i class="icon icon-account_balance green-text s-18"></i>Incentive<i
                        class="icon icon-angle-left s-18 pull-right"></i></a>
                    <ul class="treeview-menu">
                        <li>
                            <router-link :to="{name:'user-wallet'}">
                                <i class="icon icon-account_balance_wallet"></i>Pending incentive Claim
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'user-loan'}">
                                <i class="icon icon-money-bag "></i>Incentive claim history
                            </router-link>
                        </li>
                    </ul>
                </li>
                
                <li class="treeview">
                    <a class="green-text"><i class="icon icon-account_balance green-text s-18"></i>Pin Transactions<i
                        class="icon icon-angle-left s-18 pull-right"></i></a>
                    <ul class="treeview-menu">
                        <li>
                            <router-link :to="{name:'pending-pin-purchases'}">
                                <i class="icon icon-account_balance_wallet"></i>Pending Pin Purchase
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'admin.pin-purchase-history'}">
                                <i class="icon icon-account_balance_wallet"></i>Pin Purchase History
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'admin.pin-registration-history'}">
                                <i class="icon icon-money-bag "></i>Pin Registration History
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="treeview">
                    <router-link :to="{name:'admin-security'}">
                        <i class="icon icon-lock3 green-text s-18"></i>
                        <span class="green-text">Security</span>
                    </router-link>
                </li>
                <li class="treeview active ">
                    <a class="green-text" href="#">
                        <i class="icon icon-payment green-text s-18 "></i> <span class="green-text">PayInsta</span>
                        <i class="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul class="treeview-menu">
                        <li><a class="green-text" href="#"><i class="icon icon-wifi"></i>Data Purchase History</a>
                        </li>
                        <li><a class="green-text" href="#"><i class="icon icon-phone_in_talk"></i>Airtime Purchase History</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
    </aside>
</template>
<script>
export default {
    
}
</script>