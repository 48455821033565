<template>
    <div class="row">
        <div class="col-md-4">
            <div class="card no-b">
                <div class="card-header bg-white">
                    <h4 class="green-text"><strong class="font-weight-bold">Request for cash</strong></h4>
                    <small>Make a withdrawal request with the form below</small>
                </div>
                <div class="collapse show text-center" id="invoiceCard">
                    <div class="col-md-4">
                        <div class="card-body p-0">
                            <img class="img-center" src="/assets/img/withdraw.png">
                        </div>
                    </div>
                    <div class="card-body p-">
                        <form class="form-horizontal form-materia" @submit.prevent="submit">
                            <div class="form-group">
                                <div class="col-md-12">  
                                    <slot></slot>                                                                                                                                                     <input type="hidden" name="level_id" value="2">
                                    <input type="text" v-model="form.amount" class="form-control form-control-line" placeholder="Enter amount">
                                    <div class="form-group"><br>
                                        <div class="col-sm-12">
                                            <b-button v-if="inProcess" variant="primary" class="btn btn-success" disabled>
                                                <b-spinner small type="grow"></b-spinner>
                                                Processing...
                                            </b-button>
                                            <button v-else type="submit" class="btn btn-sm btn-success" id="cash-req"><i class="icon-arrow_forward mr-2"></i>Cash request</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>  
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card no-b">
                <div class="card-header bg-white">
                    <h4 class="green-text"><strong class="font-weight-bold">Notification</strong></h4>
                    <small>We pay out at certain periods in a month</small>
                </div>
                <div class="collapse show text-center" id="invoiceCard">
                    <div class="col-md-4">
                        <div class="card-body p-0">
                            <img class="img-center" src="/assets/img/message.png">
                        </div>
                    </div>
                        <div class="card-body  text-center">
                        <small>We honour withdrawals 2 times in a month.<br> There are no minimum withdraw.<br> Thank You.</small>
                    </div>                                            
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card no-b">
                <div class="card-header bg-white">
                    <h4 class="green-text"><strong class="font-weight-bold">Wallet</strong></h4>
                    <small>Your current wallet balance</small>
                </div>
                <div class="collapse show text-center" id="invoiceCard">
                    <div class="col-md-4">
                        <div class="card-body p-0">
                            <img src="/assets/img/withdraw3.png">
                        </div>
                    </div>
                    <div class="card-body text-center">
                        <small>Wallet Account</small>
                        <h1 class="font-weight-bold text-green">${{user.wallet}}</h1>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import toastr from 'toastr'
//import {mapState} from 'vuex'
export default {
    props:{
        inProcess:{
            type:Boolean,
            required:true
        },
        user:{
            type:Object,
            required:true
        }
    },
    data() {
        return {
            form:{
                amount:''
            }
        }
    },  

    computed:{
       
    },
    
    methods:{
        submit(){
            if(this.form.amount.length==0){
                toastr.error('Amount field cannot be empty')
                return
            }
            this.$emit('cash-request',this.form)
        }
    }
}
</script>