<template>
    <main>
        <div id="primary" class="green4 p-t-b-100 height-full responsive-phone">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <img class="responsive" src="assets/img/loginimage.png" alt="">
                    </div>
                    <div class="col-lg-6 p-t-100">
                        <div class="text-white text-center text-weight-normal">
                            <img src="assets/img/loginimage2.png" alt="">
                        </div><br><br>
                        <form method="post" @submit.prevent>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group has-icon"><i class="icon-envelope-o"></i>
                                        <input type="text" v-model="form.username" class="form-control form-control-lg no-b"
                                            placeholder="Email Address">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group has-icon"><i class="icon-lock2"></i>
                                        <input type="password" v-model="form.password" class="form-control form-control-lg no-b"
                                            placeholder="Password">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <b-button v-if="processing" variant="primary" class="btn-success btn-lg btn-block" disabled>
                                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        Processing...
                                    </b-button>
                                    <input v-else type="submit" @click="submit()" class="btn btn-success btn-lg btn-block" value="Login">
                                    <p class="forget-pass text-white text-center">Have you forgot your username or password ? 
                                        <a href="#" class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapseOne">
                                            <b>Retrieve Here</b>
                                        </a>
                                        <br>Don't Have an Account?
                                        <router-link :to="{name:'register'}"> 
                                            <b>Register Now!</b>
                                        </router-link>
                                    </p>
                                </div>
                                <div id="collapse1" class="collapse hidden col-lg-12" aria-labelledby="headingOne" data-parent="#accordionExample" style="">
                                    <div class="col-lg-12">
                                        <div class="form-group has-icon"><i class="icon-envelope-o"></i>
                                            <input type="text" class="form-control form-control-lg no-b"
                                                placeholder="Enter Your Email">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 text-center">
                                        <input type="submit"  class="btn btn-success btn-lg btn-block" value="Reset Password">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- #primary -->
    </main>
</template>

<style scoped>
    .plane-container {
        position: absolute;
        top: 50%;
        left: 50%;
    }
    
    .green4 {
    background-color: #52BE80!important;
    }
    
    .responsive {
    max-width: 100%;
    }
</style>

<script>
import {mapActions, mapState} from 'vuex'
import toastr from 'toastr';
    export default{
        name:'login',
        data() {
            return {
                form:{
                    username:'',
                    password:''
                }
            }
        },
        computed:{
            ...mapState({
                processing: state => state.processing
            })
        },
        methods: {
            ...mapActions('authStore',['login']),

            submit(){
                this.login(this.form).catch(err => {
                    if (err.response && err.response.status === 422) {
                        this.errors = err.response.data.errors;
                        toastr.warning("Ivalid input")
                        console.log("error-" + JSON.stringify(err.response.data));
                    } else {
                        console.log('')
                        toastr.error("An erorr occured, please ty again")
                    }
                    this.$store.commit("processed", null, { root: true });
                });
            }
        },
    }

</script>
