<template>
    <div>
        <div class="card no-b">
            <div class="card-header white">
                <h4 class="card-title"><strong class="font-weight-bold">System Configuration</strong></h4>
                <small class="card-subtitle mb-2 text-muted">Administrator System Settings</small>
            </div>
            <div class="collapse show" id="invoiceCard">
                <b-card v-if="loading">
                    <b-skeleton animation="throb" width="85%"></b-skeleton>
                    <b-skeleton animation="throb" width="55%"></b-skeleton>
                    <b-skeleton animation="throb" width="70%"></b-skeleton>
                </b-card>
                <div v-else class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <pin-setting :systemSettings="systemSettings"/>
                        </div>
                        <div class="col-md-4">
                            <entry-payment :systemSettings="systemSettings"/>
                        </div>
                        <div class="col-md-4">
                            <withdrawal-limit :systemSettings="systemSettings"/>
                        </div>
                    </div>

                    <br/>

                    <div class="row">
                        <div class="col-md-4">
                            <welcome-bonus :systemSettings="systemSettings"/>
                        </div>

                        <div class="col-md-4">
                            <exchange-rate :systemSettings="systemSettings"/>
                        </div>
                    </div>

                    <br/>

                    <div class="row">
                        <div class="col-md-4">
                            <contact :systemSettings="systemSettings"/>
                        </div>
                        <div class="col-md-4">
                            <bank-setting :systemSettings="systemSettings"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BankSetting from '@/components/admin/system-setting/BankSetting'
import PinSetting from '@/components/admin/system-setting/PinSetting'
import EntryPayment from '@/components/admin/system-setting/RegistrationFee'
import WithdrawalLimit from '@/components/admin/system-setting/WithdrawalLimit'
import WelcomeBonus from '@/components/admin/system-setting/WelcomeBonus'
import ExchangeRate from '@/components/admin/system-setting/ExchangeRate'
import Contact from '@/components/admin/system-setting/Contact'
import {mapState,mapActions,mapGetters} from 'vuex'
export default {
    components:{
        BankSetting,
        PinSetting,
        EntryPayment,
        WithdrawalLimit,
        WelcomeBonus,
        ExchangeRate,
        Contact
    },

    computed:{
        ...mapState({
            loading:state=>state.loading
        }),
        ...mapGetters('adminStore',['systemSettings'])
    },

    created(){
        if(Object.entries(this.systemSettings).length == 0){
            this.getSystemSettings()
        }
    },

    methods:{
        ...mapActions('adminStore',['getSystemSettings'])
    }
}
</script>