import login from '@/views/auth/Login'
import register from '@/views/auth/Register'
let routes = [
    {
        path: '/',
        name: 'Home',
        component: login
    },
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: '/register',
        name: 'register',
        component: register
    }

]

export default routes;