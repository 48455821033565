<template>
    <div class="row mt-3">
        
        <div  class="col-md-4">
            <div v-if="eligStatusLoading">
                <b-card>
                    <b-skeleton animation="throb" width="85%"></b-skeleton>
                    <b-skeleton animation="throb" width="55%"></b-skeleton>
                    <b-skeleton animation="throb" width="70%"></b-skeleton>
                    <b-skeleton animation="throb" width="85%"></b-skeleton>
                    <b-skeleton animation="throb" width="55%"></b-skeleton>
                    <b-skeleton animation="throb" width="70%"></b-skeleton>
                    <b-skeleton animation="throb" width="85%"></b-skeleton>
                    <b-skeleton animation="throb" width="55%"></b-skeleton>
                    <b-skeleton animation="throb" width="70%"></b-skeleton>
                </b-card>
            </div>
            <div v-else class="card mb-3">
                <div class="card-header white text-green">ELIGIBILITY STATUS</div>
                <div class="card-body ">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                            <tr>
                                <th scope="col">Membership Previledge</th>
                                <th scope="col">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Grants</td>
                                <td><span class="badge text-white bg-green">Eligible</span></td>
                            </tr>
                            <tr>
                                <td>Loan</td>
                                <td><span class="badge text-white bg-red">Not Eligible</span></td>
                            </tr>
                            <tr>
                                <td>Withdrawal</td>
                                <td><span class="badge text-white bg-red">{{eligStatus.withdrawal ? 'Eligible':'Not Eligible' }}</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        
        <!--eligibity table end-->
        
        <!--Exchange rate Start-->
        <div class="col-md-4 mb-2" >
            <div class="white">
                <div class="card">
                    <div class="card-body no-p">
                        <div class="">
                            <div class="bg-success text-white lighten-2" style="height:290px">
                                <div class="pt-5 pb-2 pl-5 pr-5">
                                    <div class="float-up text-left text-center">
                                        <img  src="/assets/img/exchange.png">
                                    </div>
                                    <div class="text-center">
                                        <h5 class="font-weight-normal s-14"><strong>OFFICIAL EXCHANGE RATE</strong></h5>
                                        <h2 class=" font-bold-lighter text-success"><small>1 USD($) = 350 NGN(&#8358;)</small></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Exchange rate end-->
        
        
        <!--point value begin-->
        <div class="col-md-4">
            <div class="card mb-3">
                <div class="card-header white text-green">TOTAL POINT VALUE</div>
                <div class="card-body ">
                    <div class="float-up text-center">
                        <img  src="/assets/img/pv.png">
                    </div>
                    <div class="sc-counter s-36 text-center">1250</div>
                    <h6 class="counter-title text-center">Cumulated Point Value (CPV)</h6>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
//import
    export default {
        props:{
            eligStatus:{
                type:Object,
                required:true
            },
            eligStatusLoading:{
                type:Boolean,
                required:true
            }
        }
    }
</script>