<template>
    <div class="card no-b">
        <div class="card-header bg-white">
            <h4 class="green-text"><strong class="font-weight-bold">Wallet Detailed Calculation</strong></h4>
        </div>
        <div class="collapse show" id="invoiceCard">
            <div class="card-body p-0">
                <div class="card no-b  no-r">
                    <div class="card-body">
                        <div class="form-row" style="overflow-x:auto;">
                            <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">S/N</th>
                                            <th scope="col">Level</th>
                                            <th scope="col">Num. of downlines</th>
                                            <th scope="col">UniLevel Bonus(DB)</th>
                                            <th scope="col">Level completion Bonus(CB)</th>
                                            <th scope="col">Level Total Bonus(UB)</th>
                                            <th scope="col">Withdrawals (W)</th>
                                            <th scope="col">Level Net Bonus(UB)</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(dat,index) in analysis" :key="dat.level_name">
                                            <td>{{Number(index) + 1}}</td>
                                            <td>{{dat.level_name}}</td>
                                            <td>{{dat.num_of_downlines}}</td>
                                            <td>${{dat.downline_bonus}}</td>
                                            <td>${{dat.level_completion_bonus}}</td>
                                            <td>${{dat.level_total_bonus}}</td>
                                            <td>${{dat.withdrawals}}</td>
                                            <td>${{dat.level_net_bonus}}</td>
                                        </tr>							
                                        <tr><th colspan="7">Total net bonus</th><td>${{analysis.total_net_bonus}}</td></tr>
                                    </tbody>
                            </table>
                                                                            
                        </div>

                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        analysis:{
            type:Object,
            required:true
        }
    }
}
</script>