<template>
    <div class="row">
        <div class="col-md-12">
            <div class="row my-3">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header bg-white">
                            <h6 class="green-text">
                                <strong class="font-weight-bold">
                                    Send {{type.toUpperCae()}} 
                                    <span v-if="recipient=='user'">{{name}}</span>
                                    <span v-else>admin</span>
                                </strong>
                            </h6>
                        </div>
                        <div class="col-lg-6 mx-md-auto">
                            <br>
                            <div class="text-center">
                                <small class="mt-2"></small>
                            </div>
                            <br>
                            <form method="Post" @submit.prevent="">
                                <div class="form-group has-icon">
                                    <label>Subject</label>
                                    <input type="text" class="form-control form-control-lg" placeholder="Subject">
                                </div>

                                <div class="form-group has-icon">
                                    <label>Message</label>
                                    <textarea class="form-control form-control-lg" placeholder="Message"></textarea>
                                </div>
                                
                                <div class="text-right">
                                    <button type="submit" class="btn btn-success mt-2">
                                        <i class="icon-arrow_forward mr-2"></i>Submit 
                                    </button>
                                </div>
                            </form>
                            <br>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
export default {
    data(){
        return{
            type:this.$route.query.type,
            recipient:this.$route.query.recipient,
            name:this.$route.query.name,
            id:this.$route.query.id
        }
    },

    computed:{
        ...mapState({
            processing:state=>state.processing
        })
    },

    methods:{
        ...mapActions('authStore',[])
    }
}
</script>