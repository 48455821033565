<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-3">
                <div class="card-header white text-green">Pending Pin Purchase</div>
                <div class="card-body ">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">S/N</th>
                                    <th scope="col">Full Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Username</th>
                                    <th scope="col">Units</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Date of Payment</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="loading">
                                    <td colspan="10">
                                        <b-card>
                                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                                            <b-skeleton animation="throb" width="55%"></b-skeleton>
                                            <b-skeleton animation="throb" width="70%"></b-skeleton>
                                        </b-card>
                                    </td>
                                </tr>
                                <template v-else>
                                    <tr v-if="pendingPinPurchases.length < 1">
                                        <td colspan="8">
                                            <p class="alert alert-info text-center">
                                                There no pending pin purchases
                                            </p>
                                        </td>
                                    </tr>
                                    <tr v-else v-for="purchase,i in pendingPinPurchases" :key="i">
                                        <td>{{++i}}</td>
                                        <td>{{purchase.user.name}}</td>
                                        <td>{{purchase.user.email}}</td>
                                        <td>{{purchase.user.username}}</td>
                                        <td>{{purchase.units}}</td>
                                        <td>{{purchase.amount}}</td>
                                        <td>{{purchase.created_at}}</td>
                                        <td>
                                        <div class="dropdown">
                                            <button class="btn btn-success  dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="caret"></i>
                                            </button>
                                            <div class="dropdown-menu " aria-labelledby="dropdownMenuButton" style="position:relative">
                                                <router-link :to="{name:'admin-user-profile',params:{id:purchase.user_id}}" class="dropdown-item text-green" ><i class="icon-drivers-license-o"></i>&nbsp;&nbsp; Profile</router-link>
                                                <a href="#" class="dropdown-item text-green" @click="setId(purchase.id)" v-b-modal.approve>Approve</a>
                                                <a href="/admin/send-message/" class="dropdown-item text-green" ><i class="icon-notifications_active"></i>&nbsp;&nbsp;Notify</a>
                                                <a href="#" class="dropdown-item text-green" @click="setPop(purchase.pop_path)" v-b-modal.pop>View Pop</a>
                                                <a href="/admin/send-email/1" class="dropdown-item text-green" ><i class="icon-mail-envelope-open6"></i>&nbsp;&nbsp;Send email</a>
                                                <a href="#" class="dropdown-item text-green" @click="setId(purchase.id)" v-b-modal.disapprove>Disapprove</a>
                                            </div>
                                        </div>
                                        </td>
                                    </tr>
                                </template> 
                            </tbody>
                        </table>
                        <ul class="pagination pagination-sm no-margin float-right">
                            <li class="page-item text-green"><a href="#" class="page-link">«</a>
                            </li>
                            <li class="page-item active text-green"><a href="#" class="page-link">1</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">2</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">3</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">»</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <confirm modalId="approve" modalTitle="Approve Pin Purchase">
            <div class="alert alert-info">
                Are you sure you want to approve this Pin Purchase?
            </div>
            <b-card v-if="!purchaseId">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
            </b-card>
            <template v-else>
                <span class="btn btn-primary" v-if="processing">...processing</span>
                <button v-else class="btn btn-primary" @click="approvePinPurchase(purchaseId)">
                   <span>Yes</span>
                </button>
            </template>
        </confirm>

        <confirm v-show="!closeModal" modalId="disapprove" modalTitle="Disapprove Pin Purchase">
            <div class="alert alert-danger">
                Are you sure you want to disapprove this Pin Purchase?
            </div>
            <b-card v-if="!purchaseId">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
            </b-card>
            <template v-else>
                <span class="btn btn-primary" v-if="processing">...processing</span>
                <button v-else class="btn btn-primary" @click="disapprovePinPurchase({id:purchaseId,modalId:'disapprove'})">
                   <span>Yes</span>
                </button>
            </template>
        </confirm>

        <confirm modalId="pop" modalTitle="View Proof of Payment">
            <div class="img-response">
                <b-card v-if="!pop">
                    <b-skeleton animation="throb" width="85%"></b-skeleton>
                </b-card>
                <template v-else>
                    <img :src="`http://localhost:80/storage/${pop}`" width="400" height="400">
                </template>
            </div>
        </confirm>

    </div>
</template>
<script>
import {mapActions,mapGetters,mapState} from 'vuex'
import Confirm from '@/components/Confirm'
export default {
    name:'pending-pin-purchase',
    components:{
        Confirm
    },

    data(){
        return{
            purchaseId:null,
            pop:null
        }
    },

    computed:{
        ...mapState({
            loading:state=>state.loading,
            processing:state=>state.processing,
            closeModal:state=>state.closeModal
        }),
        ...mapGetters('adminStore',['pendingPinPurchases'])
    },

    created(){
        if(this.pendingPinPurchases.length === 0){
            this.getPendingPinPurchases()
        }
    },

    methods:{
        ...mapActions('adminStore',['getPendingPinPurchases','approvePinPurchase','disapprovePinPurchase']),

        setId(id){
            this.purchaseId = id
        },

        setPop(pop){
            this.pop = pop
        },

        // closeModal(id){
        //     this.$bvModal.hide(id)
        // }
    }
}
</script>