<template>
<div>
    <div class="card mt-3 mb-3">
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <div class="dropdown">
                        <button class="btn btn-success dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="caret"></i>&nbsp;&nbsp;  Filter Members Search</button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="position:relative">
                            <a class="dropdown-item text-green"><i class="icon-list2"></i>&nbsp; All Members</a>
                            <a class="dropdown-item text-green"><i class="icon-check_box"></i>&nbsp; Approved Members</a>
                            <a class="dropdown-item text-green"><i class="icon-minus-square"></i>&nbsp; Unapproved Members</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <select class="custom-select select2" id="filter" name="filter" required>
                        <option value="">Select Search Method</option>
                        <option value="1">username</option>
                        <option value="2">Email</option>
                        <option value="3">Phone</option>
                    </select>
                </div>

                <div class="col-md-3">												
                    <div class="form-group">
                        <input class="form-control bg-light r-0" type="tel" id="q" name="q" required placeholder="Enter Search " >
                    </div>
                </div>
                
                <div class="col-md-3">
                    <button type="submit" id="submit" class="btn btn-success"><i class="icon-search"></i></button>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="card mb-3">
                <div class="card-header white text-green">Members Information</div>
                <div class="card-body ">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">S/N</th>
                                    <th scope="col">Full Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Country</th>
                                    <th scope="col">Profile Image</th>
                                    <th scope="col">Username</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Class</th>
                                    <th scope="col">Date of Reg.</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="usersLoading">
                                    <td colspan="10">
                                        <b-card >
                                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                                            <b-skeleton animation="throb" width="55%"></b-skeleton>
                                            <b-skeleton animation="throb" width="70%"></b-skeleton>
                                        </b-card>
                                    </td>
                                </tr>
                                <tr v-else v-for="user,i in users" :key="i">
                                    <td>{{++i}}</td>
                                    <td>{{user.name}}</td>
                                    <td>{{user.email}}</td>
                                    <td>{{user.country}}</td>
                                    <td><img class="w-40px" src="assets/img/dummy/u1.png" alt="User Image"></td>
                                    <td>{{user.username}}</td>
                                    <td><span :class="['btn btn-sm',{'btn-success':user.is_approved,'btn-danger':!user.is_approved}]">{{user.is_approved ? 'Approved' : 'Not-approved'}}</span></td>
                                    <td>{{user.level.name}}</td>
                                    <td>{{user.created_at}}</td>
                                    <td>
                                    <div class="dropdown">
                                        <button class="btn btn-success  dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="caret"></i>
                                        </button>
                                        <div class="dropdown-menu " aria-labelledby="dropdownMenuButton" style="position:relative">
                                        <router-link :to="{name:'admin-user-profile',params:{id:user.id}}" class="dropdown-item text-green" ><i class="icon-drivers-license-o"></i>&nbsp;&nbsp; Profile</router-link>
                                        <a href="/admin/geneology/1" class="dropdown-item text-green" ><i class="icon-sitemap"></i>&nbsp;&nbsp;Geneology</a>
                                        <a :to="{name:'view-pop',params:{id:user.id}}" data-id="1" class="dropdown-item text-green"><i class="icon-pageview"></i>&nbsp;&nbsp;View POP</a>
                                        <router-link :to="{name:'admin.send-message',query:{recipient:'user', type:'email',name:user.username, id:user.id}}" class="dropdown-item text-green" >
                                            <i class="icon-mail-envelope-open6"></i>&nbsp;&nbsp;Send email
                                        </router-link>
                                        <router-link :to="{name:'admin.send-message',query:{recipient:'user', type:'message',name:user.username, id:user.id}}" class="dropdown-item text-green" >
                                            <i class="icon-mail-envelope-open6"></i>&nbsp;&nbsp;Send Message
                                        </router-link>
                                        <a class="dropdown-item text-green" href="/admin/disableG2FA/1"><i class="icon-lock3"></i>&nbsp;&nbsp;Disable G2FA</a> 
                                        </div>
                                    </div>
                                    </td>
                                </tr> 
                            </tbody>
                        </table>
                        <ul class="pagination pagination-sm no-margin float-right">
                            <li class="page-item text-green"><a href="#" class="page-link">«</a>
                            </li>
                            <li class="page-item active text-green"><a href="#" class="page-link">1</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">2</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">3</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">»</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
            
</template>
<script>
//import FilterPanel from '@/components/admin/users/FilterPanel'
import {mapGetters,mapActions} from 'vuex'
export default {
    components:{
        
    },

    computed:{
        ...mapGetters('adminStore',['users']),
        usersLoading: function(){return this.users.length == 0 ? true : false},
    },

    created(){
        if(this.users.length == 0){
            this.getUsers()
        }
    },

    methods:{
        ...mapActions('adminStore',['getUsers']),

        checkApprove(user){
            let status = 'btn btn-sm'
            if(user.is_approved){
                status += 'btn-success'
            }
            else{
                status += 'btn-danger'
            }
            return status
        }
    }
}
</script>