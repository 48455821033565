<template>
    <div class="row">
        <div class="col-md-12">
            <div class="row my-3">
                <!-- bar charts group -->
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header bg-white">
                            <h6 class="green-text"><strong class="font-weight-bold">Direct Downlines</strong></h6>
                        </div>
                        <div v-if="loading">
                            <b-card>
                                <b-skeleton animation="throb" width="85%"></b-skeleton>
                                <b-skeleton animation="throb" width="55%"></b-skeleton>
                                <b-skeleton animation="throb" width="70%"></b-skeleton>
                                <b-skeleton animation="throb" width="85%"></b-skeleton>
                                <b-skeleton animation="throb" width="55%"></b-skeleton>
                                <b-skeleton animation="throb" width="70%"></b-skeleton>
                                <b-skeleton animation="throb" width="85%"></b-skeleton>
                                <b-skeleton animation="throb" width="55%"></b-skeleton>
                                <b-skeleton animation="throb" width="70%"></b-skeleton>
                            </b-card>
                        </div>
                        <div v-else class="card-body" style="overflow-x:auto;">
                            <table id="example2" class="table table-bordered table-hover data-tables"
                                data-options='{ "paging": false; "searching":false}'>
                                <thead>
                                <tr>
                                    <th scope="col">S/N</th>
                                    <th scope="col">Full Name</th>
                                    <th scope="col">User Name</th>
                                    <th scope="col">Stage</th>
                                    <th scope="col">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <template v-if="downlines.length > 0">
                                        <tr v-for="(downline,index) in downlines" :key="index">
                                            <th scope="row">{{++index}}</th>
                                            <td>{{downline.referred.name}}</td>
                                            <td>{{downline.referred.username}}</td>
                                            <td>{{downline.level.name}}</td>
                                            <td><span class="text-green font-weight-bold">{{ downline.referred.is_approved ? 'Approved':'Unapproved' }}</span></td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="5">
                                                <div class="alert alert-info text-center">There are no downlines</div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- /bar charts group -->
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions,mapState,mapGetters} from 'vuex'
//import toastr from 'toastr'
export default {
    computed:{
        ...mapState({
            loading:state=>state.loading
        }),
        ...mapGetters('userStore',['downlines'])
    },
    created(){
        if(this.downlines.length==0){
            this.getDownlines()
        }
    },
    methods:{
        ...mapActions('userStore',['getDownlines'])
    }
}
</script>