<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card my-3">
                <div class="card-body">
                    <div class="card-header white text-green">Pin Registration History</div><br>
                        <div class="form-row" style="overflow-x:auto;">
                            <table id="example2" class="table table-bordered table-hover data-tables"
                                    data-options='{ "paging": true; "searching":true}'>
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Username</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                    </tr> 
                                </thead>
                                <tbody>
                                    <tr v-if="loading">
                                        <td colspan="10">
                                            <b-card>
                                                <b-skeleton animation="throb" width="85%"></b-skeleton>
                                                <b-skeleton animation="throb" width="55%"></b-skeleton>
                                                <b-skeleton animation="throb" width="70%"></b-skeleton>
                                            </b-card>
                                        </td>
                                    </tr>
                                    <template v-else>
                                        <tr v-if="pinRegistrationHistory.length < 1">
                                            <td colspan="4">
                                                <p class="alert alert-info text-center">
                                                    There no pin registration History
                                                </p>
                                            </td>
                                        </tr>
                                        <tr v-else v-for="reg,i in pinRegistrationHistory" :key="i">
                                            <th scope="row">{{++i}}</th>
                                            <td>{{reg.buyer.username}}</td>
                                            <td> 
                                                <span v-if="reg.is_successful" class="badge text-white bg-green">Successful</span>
                                                <span v-else class="badge text-white bg-red">Not-successful</span>
                                            </td>
                                            <td>{{reg.created_at}}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>	
                </div>
            </div>                         
        </div>
    </div>
</template>
<script>
import {mapActions,mapGetters,mapState} from 'vuex'
export default {
   computed:{
        ...mapState({
            loading:state=>state.loading
        }),

        ...mapGetters('userStore',['pinRegistrationHistory'])
   },

   created(){
       if(this.pinRegistrationHistory.length == 0){
           this.getPinRegistrationHistory()
       }
   },

   methods:{
       ...mapActions('userStore',['getPinRegistrationHistory'])
   }


}
</script>