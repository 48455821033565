<template>
     <div class="container-fluid animatedParent animateOnce my-3">
         <div class="row">
            <div class="card col-md-12">
                <div class="card-header bg-white">
                    <h6 class="green-text"><strong class="font-weight-bold">Incentives</strong></h6>
                </div>
            </div>
        </div>
        <div class="">
            <div class="row">
                <template v-if="loading">
                    <b-card class="col-md-12 mt-3">
                        <b-skeleton animation="throb" width="85%"></b-skeleton>
                        <b-skeleton animation="throb" width="55%"></b-skeleton>
                        <b-skeleton animation="throb" width="70%"></b-skeleton>
                        <b-skeleton animation="throb" width="85%"></b-skeleton>
                        <b-skeleton animation="throb" width="55%"></b-skeleton>
                        <b-skeleton animation="throb" width="70%"></b-skeleton>
                    </b-card>
                </template>

                <div v-else v-for="level,i in levels" class="col-md-6 mb-3" :key="i">
                    <div class="card ">
                        <div class="card-header bg-white">
                            <h4 class="green-text"><strong class="font-weight-bold">{{level.name}}</strong></h4>
                        </div>
                        <div class="card-body">
                            <div class="row p-3">
                                <div class="col-md-6">
                                    <img class="gift" :src="getImage(level.id)" height="150">
                                </div>
                                <div class="col-md-6">
                                    <div class="card-body p-3">
                                        <h6>Cash Equivalent</h6>
                                        <div class="my-3">
                                            <div>
                                                <small>₦0</small>
                                            </div>
                                        </div>
                                        <hr>
                                        <h6>Description</h6>
                                        <div class="my-3">
                                            <div>
                                                <small>Get Started with Amazing Global Network Kit.</small>
                                            </div>
                                        </div>
                                        <hr>
                                        <h6>Incentives</h6>
                                        <div class="my-3">
                                            <div>
                                                <small>T-shirt, cap, bag and mobile phone</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" >
                                    <a v-if="level.id > user.level_id" class="alert alert-info">
                                        You need to complete Level {{level.id}} To Claim
                                    </a>
                                    <template v-else>
                                        <span v-if="checkStatus(level.id) && checkStatus(level.id).status == 'approved'"  class="btn btn-success">Claimed</span>
                                        <span v-else-if="checkStatus(level.id) && checkStatus(level.id).status == 'pending'"  class="btn btn-warning">Pending</span>
                                        <div v-else>
                                            <span class="btn btn-primary" v-if="processing">...</span>
                                            <button v-else class="btn btn-primary" @click="claim(level.id)">Claim</button>
                                        </div>
                                    </template>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>
<script>
import {mapActions,mapGetters,mapState} from 'vuex'
export default {
    data(){
        return{

        }
    },

    computed:{
        ...mapState({
            loading:state=>state.loading,
            processing:state=>state.processing
        }),

        ...mapGetters(['user']),
        ...mapGetters('userStore',['levels','incentiveClaimStatuses','incentives'])
    },

    created(){
        if(Object.entries(this.user).length == 0){
            this.getUser()
        }
        if(Object.entries(this.levels).length == 0){
            this.getLevels()
        }
        if(this.incentiveClaimStatuses.length == 0){
            this.getIncentiveClaimStatuses()
        }
        if(this.incentives.length == 0){
            this.getIncentives()
        }
    },

    methods:{
        ...mapActions('userStore',['getUser','getLevels',
        'getIncentiveClaimStatuses','claimIncentive','getIncentives']),

        checkStatus(leveId){
            let status = this.incentiveClaimStatuses.find(ele=>ele.level_id===leveId);
            return status
        },

        claim(leveId){
            this.claimIncentive({level_id:leveId})
        },

        getImage(level_id){
           let incentive = this.incentives.find(ele=>ele.level_id==level_id)
           return incentive ? incentive.image_path : '/assets/img/allgifts1.png'
        }
    }
};
</script>