export default{
    usersCount(state,count){
        state.usersCount = count
    },

    paidUsersCount(state,count){
        state.paidUsersCount = count
    },

    unpaidUsersCount(state,count){
        state.unpaidUsersCount = count
    },

    withdrawalRequestCount(state,count){
        state.withdrawalRequestCount = count
    },

    users(state,{data}){
        state.users.push(...data)
    },

    showUser(state,data){
        state.showUser = data
    },

    pendingEntryPayments(state,data){
        state.pendingEntryPayments.push(...data);
    },

    proofOfPayment(state,data){
        state.proofOfPayment = data
    },

    entryPaymentHistory(state,{data}){
        state.entryPaymentHistory.push(...data)
    },

    pinPurchaseHistory(state,{data}){
        state.pinPurchaseHistory.push(...data)
    },

    pinRegistrationHistory(state,{data}){
        state.pinRegistrationHistory.push(...data)
    },

    pendingPinPurchases(state,{data}){
        state.pendingPinPurchases.push(...data)
    },

    addBankDetails(state,data){
        state.bankDetails.push(data)
    },
    updatebankDetails(state,{id,data}){
       let index = state.bankDetails.findIndex(function(bank){
           return bank.id === id
        })
        state.bankDetails[index] = data
    },
    deleteBankDetails(state,id){
        let index = state.bankDetails.findIndex(function(bank){
            return bank.id === id
         })

         state.bankDetails.splice(index,1)
    },

    systemSettings(state,data){
        state.systemSettings = data
    },

    loanRequestHistory(state,{data}){
        state.loanRequestHistory.push(...data)
    },
    pendingLoanRequestApprovals(state,{data}){
        state.pendingLoanRequestApprovals.push(...data)
    },
    filterPendingLoanRequestApprovals(state,id){
       const index = state.pendingLoanRequestApprovals.findIndex(ele=>ele.id == id)
       state.pendingLoanRequestApprovals.splice(index,1)
    },

    pendingLoanPaymentApprovals(state,{data}){
        state.pendingLoanPaymentApprovals.push(...data)
    },
    filterPendingLoanPaymentApprovals(state,id){
        const index = state.pendingLoanPaymentApprovals.findIndex(ele=>ele.id===id)
        state.pendingLoanPaymentApprovals.splice(index,1)
    },
    loanPaymentHistory(state,{data}){
        state.loanPaymentHistory.push(...data)
    }


}