import api from "../../api/admin-api";
import toastr from 'toastr';
import vm from "@/main";

export default {
    countUsers({ commit }) {
        api.registrationsCount().then(res => {
            if (res.data.success) {
                commit("usersCount", res.data.users_count);
                commit("processed", null, { root: true });
                toastr.success("Success, users count fetch successfully")
            } else {
                toastr.error("Error, unable to fetch users count")
                console.log("error");
            }
        });
    },

    countPaidUsers({ commit }) {
        api.paidMembersCount().then(res => {
            if (res.data.success) {
                commit("paidUsersCount", res.data.paid_users_count);
                commit("processed", null, { root: true });
                toastr.success("Success, paid users count fetched successfully")
            } else {
                toastr.error("Error, unable to fetch paid users count")
                console.log("error");
            }
        });
    },

    countUnpaidUsers({ commit }) {
        api.unpaidMembersCount().then(res => {
            if (res.data.success) {
                commit("unpaidUsersCount", res.data.unpaid_users_count);
                commit("processed", null, { root: true });
                toastr.success("Success, unpaid users count fetched successfully")
            } else {
                toastr.error("Error, unable to fetch unpaid users count")
                console.log("error");
            }
        }).catch(err => {
            toastr.error("Error, An error occured please try again")
            console.log(err);
        });
    },

    countWithdrawalRequests({ commit }) {
        api.withdrawalRequestCount().then(res => {
            if (res.data.success) {
                commit("withdrawalRequestCount", res.data.withdrawal_request_count);
                commit("processed", null, { root: true });
                toastr.success("Success, withdrawal request count fetched successfully")
            } else {
                toastr.success("Error, Unable to fetche withdrawal request count")
                console.log("error");
            }
        }).catch(err => {
            toastr.error("Error, An error occured please try again")
            console.log(err);
        });
    },

    sumPinPurchases({ commit }) {
        api.pinPurchaseSum().then(res => {
            if (res.data.success) {
                commit("pinPurchaseSum", res.data.pin_purchase_sum);
                commit("processed", null, { root: true });
                toastr.success("Success, pin purchase sum fetched successfully")
            } else {
                console.log("error");
                toastr.success("Error, unable to fetch pin purchase sum")
            }
        }).catch(err => {
            toastr.error("Error, An error occured please try again")
            console.log(err);
        });
    },

    getUsers({commit}){
        api.users().then(res=>{
            if(res.data.success){
                commit("users", res.data.users);
                commit("processed", null, { root: true });
                toastr.success("Success, users fetched successfully")
            }else{
                console.log("error");
                toastr.success("Error, unable to fetch users")
            }
        }).catch(err=>{
            console.log(err)
            toastr.error("An error occured, please try again")
        })
    },

    getAUser({ commit },id) {
        commit("loading", null, { root: true });
        return api.user(id).then(res => {
            if (res.data.success) {
                commit("showUser", res.data.user, 'adminStore');
                commit("loaded", null, { root: true });
            } else {
                toastr.error('Error! unable to fetch user')
                console.log(res.data);
            }
        })
        .catch(err => {
            commit('loaded',null,{root:true})
            toastr.error('An error occured, please try again')
            console.log(err);
        });
    },

    getPendingEntryPayments({commit}){
        commit('loading',null,{root:true})
        return api.pendingEntryPayments().then(res=>{
            if(res.data.success){
                commit('pendingEntryPayments',res.data.pending_entry_payments)
                toastr.success("Pending entry payments fetched succssfully")
                commit('loaded',null,{root:true})
            }else{
                toastr.error("Error! unable to fetch entry pay")
                commit('loaded',null,{root:true})
            }
        }).catch((err)=> {
            commit('loaded',null,{root:true})
            toastr.error('An error occured, please try again')
            console.log(err);
        });
    },

    getProofOfPayment({commit},id){
        commit('loading',null,{root:true})
        return api.proofOfPayment(id).then(res=>{
            if(res.data.success){
                commit('proofOfPayment',res.data.proof_of_payment)
                commit('loaded',null,{root:true})
                toastr.success("Proof of payment fetched successfully")
            }else{
                commit('loaded',null,{root:true})
                toastr.success("Error, unable to fetch proof of payment")
            }
        }).catch((err)=> {
            commit('loaded',null,{root:true})
            toastr.error('An error occured, please try again')
            console.log(err);
        });
    },

    approveProofOfPayment({commit},id){
        commit('processing',null,{root:true})
        api.approveEntryPayment(id).then(res=>{
            if(res.data.success){
                commit('processed',null,{root:true})
                toastr.success("Proof of payment approved successfully")
                vm.$router.push({name:'pending-entry-payments'})
            }else{
                commit('processed',null,{root:true})
                toastr.error(res.data.message)
            }
        }).catch(err=>{
            commit('processed',null,{root:true})
            toastr.error('An error occured, please try again')
            console.log(err);
        })
    },

    disapproveProofOfPayment({commit},id){
        commit('processing',null,{root:true})
        api.disapproveEntryPayment(id).then(res=>{
            if(res.data.success){
                commit('processed',null,{root:true})
                toastr.success("Proof of payment approved successfully")
                vm.$router.push({name:'pending-entry-payments'})
            }else{
                commit('processed',null,{root:true})
                toastr.error(res.data.message)
            }
        }).catch(err=>{
            commit('processed',null,{root:true})
            toastr.error('An error occured, please try again')
            console.log(err);
        })
    },

    getEntryPaymentHistory({commit}){
        commit('loading',null,{root:true})
        api.entryPaymentHistory().then(res=>{
            if(res.data){
                commit('entryPaymentHistory',res.data)
                commit('loaded',null,{root:true})
                toastr.success("entry payment history loded successfully")
            }else{
                commit('loaded',null,{root:true})
                toastr.error("Unable to fetch entry payment history")
            }
        }).catch(err=>{
            commit('loaded',null,{root:true})
            toastr.error('An error occured, please try again')
            console.log(err);
        })
    },

    getPinPurchaseHistory({commit}){
        commit('loading',null,{root:true})
        api.pinPurchaseHistory().then(res=>{
            if(res.data){
                commit('loaded',null,{root:true})
                commit('pinPurchaseHistory',res.data)
                toastr.success("Pin purchase history fetched successfully")
            }else{
                commit('loaded',null,{root:true})
                toastr.error("Unable to fetch pin purchase history, please try again")
            }
        }).catch(err=>{
            commit('loaded',null,{root:true})
            toastr.error("An error occured")
            console.log(err)
        })
    },

    getPinRegistrationHistory({commit}){
        commit('loading',null,{root:true})
        api.pinRegistrationHistory().then(res=>{
            if(res.data.data){
                commit('loaded',null,{root:true})
                commit('pinRegistrationHistory',res.data)
                toastr.success('Pin Registration history fetched successfully')
            }else{
                commit('loaded',null,{root:true})
                toastr.error('Unable to fetch Pin Registration history')
            }
        }).catch(err=>{
            commit('loaded',null,{root:true})
            toastr.error("An internal error occured")
            console.log(err)
        })
    },

    getPendingPinPurchases({commit}){
        commit('loading',null,{root:true})
        api.pendingPinPurchases().then(res=>{
            if(res.data.success){
                commit('loaded',null,{root:true})
                commit('pendingPinPurchases',res.data.pending_pin_purchases)
                toastr.success('Pending pin purchases successfully loaded')
            }else{
                commit('loaded',null,{root:true})
                toastr.error('Error!! Unable Pending pin purchases')
            }
        }).catch(err=>{
            commit('loaded',null,{root:true})
            toastr.error("An error occured")
            console.log(err)
        })
    },

    approvePinPurchase({commit,dispatch},id){
        commit('processing',null,{root:true})
        api.approvePinPurchase(id).then(res=>{
            if(res.data.success){
                commit('processed',null,{root:true})
                dispatch('getPendingPinPurchases')
                toastr.success('Pin purchase approved successfully')
            }else{
                commit('processed',null,{root:true})
                toastr.error('Error!! unable to approve pin purchase')
            }
        }).catch(err=>{
            commit('processed',null,{root:true})
            toastr.error("An internal error occured")
            console.log(err)
        })
    },

    disapprovePinPurchase({commit},{id,modalId}){
        commit('processing',null,{root:true})
        api.disapprovePinPurchase(id).then(res=>{
            if(res.data.success){
                commit('processed',null,{root:true})
                //dispatch('getPendingPinPurchases')
                toastr.success('Pin purchase disapproved successfully')
                if(modalId){
                    location.reload()
                }
            }else{
                commit('processed',null,{root:true})
                toastr.error('Error!! unable to disapprove pin purchase')
                
            }
        }).catch(err=>{
            commit('processed',null,{root:true})
            toastr.error("An internal error occured")
            vm.$bvModal.hide(modalId)
            console.log(err)
        })
    },

    addBankDetails({commit},data){
        commit('processing',null,{root:true})
        api.addBankDetails(data).then(res=>{
            if(res.data.success){
                commit('processed',null,{root:true})
                commit('addBankDetails',data)
                toastr.success("Bank details added successfully")
            }else{
                commit('processed',null,{root:true})
                toastr.error("Unable to add Bank details")
            }
        })
    },

    updateBankDetails({commit},{id,data}){
        commit('processing',null,{root:true})
        api.updateBankDetails(id,data).then(res=>{
            if(res.data.success){
                commit('processed',null,{root:true})
                commit('updateBankDetails',{id,data})
                toastr.success("Bank details updated successfully")
            }else{
                commit('processed',null,{root:true})
                toastr.error("Unable to update Bank details")
            }
        })
    },

    deleteBankDetails({commit},id){
        commit('processing',null,{root:true})
        api.deleteBankDetails(id).then(res=>{
            if(res.data.success){
                commit('processed',null,{root:true})
                commit('deleteBankDetails',id)
                toastr.success("Bank details deleted successfully")
            }else{
                commit('processed',null,{root:true})
                toastr.error("Unable to delete Bank details")
            }
        })
    },

    getSystemSettings({commit}){
        commit('loading',null,{root:true})
        api.systemSettings().then(res=>{
            if(res.data.success){
                commit('loaded',null,{root:true})
                commit('systemSettings',res.data.settings)
                toastr.success('System settings fetched successfully')
            }else{
                commit('loaded',null,{root:true})
                toastr.error('Error!! Unable to load system settings')
            }
        })
    },

    updateSettings({commit,dispatch},{data,field}){
        commit('processing',null,{root:true})
        api.updateSettings(data).then(res=>{
            if(res.data.success){
                commit('processed',null,{root:true})
                dispatch('getSystemSettings')
                toastr.success(`${field} updated successfully`)
            }else{
                commit('processed',null,{root:true})
                toastr.error('Unable to updated Pin price')
            }
        })
    },

    getLoanRequestHistory({commit}){
        commit('loading',null,{root:true})
        api.loanRequestHistory().then(res=>{
            if(res.data.success){
                commit('loanRequestHistory',res.data.loan_request_history)
                toastr.success('Loan request history fetched successfully')
            }else{
                toastr.error('Unable to fetch loan request history')
            }
            commit('loaded',null,{root:true})
        }).catch((err)=>{
            console.log(err)
            toastr.error('An internal error occured, please try again')
        })
    },

    getPendingLoanRequestApprovals({commit}){
        commit('loading',null,{root:true})
        api.pendingLoanRequestApprovals().then(res=>{
            if(res.data.success){
                commit('pendingLoanRequestApprovals',res.data.pending_loan_request_approvals)
            }else{
                toastr.error('Error!! unable to load pending loan request')
            }
            commit('loaded',null,{root:true})
        }).catch((err)=>{
            console.log(err)
            toastr.error('An internal error occured')
            commit('loaded',null,{root:true})
        })
    },

    approveLoanRequest({commit},{id,cb}){
        commit('processing',null,{root:true})
        api.approveLoanRequest(id).then(res=>{
            if(res.data.success){
                toastr.success('Loan request approved successfully')
                commit('filterPendingLoanRequestApprovals',id)
                cb()
            }else{
                toastr.error('Unable to approve loan request')
            }
            commit('processed',null,{root:true})
        }).catch((err)=>{
            console.log(err)
            commit('processed',null,{root:true})
            toastr.error()
        })
    },

    disapproveLoanRequest({commit},{id,cb}){
        commit('processing',null,{root:true})
        api.approveLoanRequest(id).then(res=>{
            if(res.data.success){
                toastr.success('Loan request disapproved successfully')
                commit('filterPendingLoanRequestApprovals',id)
                cb()
            }else{
                toastr.error('Unable to approve loan request')
            }
            commit('processed',null,{root:true})
        }).catch((err)=>{
            console.log(err)
            commit('processed',null,{root:true})
            toastr.error('An internal error occured')
        })
    },


    async getLoanPaymentHistory({commit}){
        try {
            commit('loading',null,{root:true})
            let res = await api.loanPaymentHistory()
            if(res.data.success){
                commit('loanPaymentHistory',res.data.data)
            }else{
                toastr.error("An error occured")
            }
            commit('loaded',null,{root:true})
        } catch (error) {
            commit('loaded',null,{root:true})
            toastr.error("An internal error occured")
            console.log(new Error(error))
        }
    },

    async getPendingLoanPaymentApprovals({commit}){
        try {
            commit('loading',null,{root:true})
            let res = await api.pendingLoanPaymentApprovals()
            if(res.data.success){
                commit('pendingLoanPaymentApprovals',res.data.data)
            }else{
                toastr.error("An error occured")
            }
            commit('loaded',null,{root:true})
        } catch (error) {
            commit('loaded',null,{root:true})
            toastr.error("An internal error occured")
            console.log(error)
        }
    },

    async approveLoanPayment({commit},{id,cb}){
        try {
            commit('processing',null,{root:true})
            let res = await api.approveLoanPayment(id)
            if(res.data.success){
                commit('filterPendingLoanPaymentApprovals',id)
                toastr.success("Payment approved successfully")
                cb()
            }else{
                toastr.error("An error occured")
            }
            commit('processed',null,{root:true})
        } catch (error) {
            toastr.error("An internal error occurred")
            commit('processed',null,{root:true})
            console.log(error)
        }
    },

    async disapproveLoanPayment({commit},{id,cb}){
        try {
            commit('processing',null,{root:true})
            let res = await api.disapproveLoanPayment(id)
            if(res.data.success){
                commit('filterPendingLoanPaymentApprovals',id)
                toastr.success("Payment disapproved successfully")
                cb()
            }else{
                toastr.error("An error occured")
            }
            commit('processed',null,{root:true})
        } catch (error) {
            toastr.error("An internal error occurred")
            commit('processed',null,{root:true})
            console.log(error)
        }
    },
    

    sendEmail({commit},id){
        commit('processing',null,{root:true})
        api.sendEmail(id).then(res=>{
            if(res.data.success){
                commit('processed',null,{root:true})
                toastr.success("Email sent successfully")
            }else{
                commit('processed',null,{root:true})
                toastr.error("Unable to send email")
            }
        }).catch(err=>{
            commit('processed',null,{root:true})
            toastr.error("An error occured")
            console.log(err)
        })
    }
}