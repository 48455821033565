<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-3">
                <div class="card-header white text-green">Pending Entry Payments</div>
                <div class="card-body ">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">S/N</th>
                                    <th scope="col">Full Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Username</th>
                                    <th scope="col">Date of Payment</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="loading">
                                    <td colspan="10">
                                        <b-card>
                                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                                            <b-skeleton animation="throb" width="55%"></b-skeleton>
                                            <b-skeleton animation="throb" width="70%"></b-skeleton>
                                        </b-card>
                                    </td>
                                </tr>
                                <template v-else>
                                    <tr v-if="pendingEntryPayments.length < 1">
                                        <td colspan="6">
                                            <p class="alert alert-info text-center">
                                                There no pending entry payments
                                            </p>
                                        </td>
                                    </tr>
                                    <tr v-else v-for="payment,i in pendingEntryPayments" :key="i">
                                        <td>{{++i}}</td>
                                        <td>{{payment.user.name}}</td>
                                        <td>{{payment.user.email}}</td>
                                        <td>{{payment.user.username}}</td>
                                        <td>{{payment.created_at}}</td>
                                        <td>
                                            <div class="dropdown">
                                                <button class="btn btn-success  dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="caret"></i>
                                                </button>
                                                <div class="dropdown-menu " aria-labelledby="dropdownMenuButton" style="position:relative">
                                                    <router-link :to="{name:'admin-user-profile',params:{id:payment.user_id}}" class="dropdown-item text-green" ><i class="icon-drivers-license-o"></i>&nbsp;&nbsp; Profile</router-link>
                                                    <router-link :to="{name:'admin-user-profile',params:{id:payment.user_id}}" class="dropdown-item text-green" ><i class="icon-drivers-license-o"></i>&nbsp;&nbsp; Approve</router-link>
                                                    <a href="/admin/send-message/" class="dropdown-item text-green" ><i class="icon-notifications_active"></i>&nbsp;&nbsp;Notify</a>
                                                    <router-link :to="{name:'view-pop', params:{pop:payment.pop}, query:{name:payment.user.username,id:payment.id} }"  class="dropdown-item text-green"><i class="icon-pageview"></i>&nbsp;&nbsp;View POP</router-link>
                                                    <a href="/admin/send-email/1" class="dropdown-item text-green" ><i class="icon-mail-envelope-open6"></i>&nbsp;&nbsp;Send email</a>
                                                    <router-link :to="{name:'admin-user-profile',params:{id:payment.user_id}}" class="dropdown-item text-green" ><i class="icon-drivers-license-o"></i>&nbsp;&nbsp; Disapprove</router-link>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template> 
                            </tbody>
                        </table>
                        <ul class="pagination pagination-sm no-margin float-right">
                            <li class="page-item text-green"><a href="#" class="page-link">«</a>
                            </li>
                            <li class="page-item active text-green"><a href="#" class="page-link">1</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">2</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">3</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">»</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions,mapGetters,mapState} from 'vuex'
export default {
    name:'pending-entry-payment',

    computed:{
        ...mapState({
            loading:state=>state.loading
        }),
        ...mapGetters('adminStore',['pendingEntryPayments'])
    },

    created(){
        if(this.pendingEntryPayments.length === 0){
            this.getPendingEntryPayments()
        }
    },

    methods:{
        ...mapActions('adminStore',['getPendingEntryPayments'])
    }
}
</script>