<template>
        <header class="blue accent-3 relative">
            <div class="container-fluid text-blue">
                <div class="row justify-content-between">
                    <ul class="nav nav-material nav-material-white responsive-tab" id="v-pills-tab" role="tablist">
                        <li>
                            <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill"  href="#v-pills-home" role="tab" aria-controls="v-pills-home"><i class="icon icon-vcard" ></i>My Profile</a>
                        </li>
                        <li>
                            <a class="nav-link" id="v-pills-payments-tab" data-toggle="pill" href="#v-pills-payments" role="tab" aria-controls="v-pills-payments" aria-selected="false"><i class="icon icon-bank "></i>Bank Details</a>
                        </li>
                        <li>
                            <a class="nav-link" id="v-pills-upload-tab" data-toggle="pill" href="#v-pills-upload" role="tab" aria-controls="v-pills-upload" aria-selected="false"><i class="icon-camera "></i>&nbsp;&nbsp;&nbsp;&nbsp;Profile Photo</a>
                        </li>
                        <li>
                            <a class="nav-link" id="v-pills-upline-tab" data-toggle="pill" href="#v-pills-upline" role="tab" aria-controls="v-pills-upline" aria-selected="false"><i class="icon icon-account_box "></i>My Upline</a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
</template>
<script>
export default {
    
}
</script>