<template>
     <div class="container py-5 mt-3">
        <div class="row">
                <!-- Column -->
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <img  src="/assets/img/feederimage.png'" style= "max-width: 100%;" alt=""/>
                        <h3>Your Bank Details</h3>
                        <p>
                            We need your bank details so that we could send/transfer cash to you when needed.
                            <strong>You can only update your bank details from here. Any further update would require an approval from the management.</strong>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">
                        Update Bank Details
                    </div>
                    <div class="card-body">
                        
                        <form class="form-horizontal" @submit.prevent="submit()">
                            <div class="form-group">
                                <label class="col-md-12">Bank Name</label>
                                <div class="col-md-12">
                                    <select v-model="form.bank_name" class="form-control form-control-line">
                                        <option :value="null" >Select bank</option>
                                        <option v-for="bank,i in banks" :key="i" :value="bank" >{{bank}}</option>
                                    </select>
                                    <span class="text-danger">{{errors.bank_name}}</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="example-email" class="col-md-12">Bank Account Name</label>
                                <div class="col-md-12">
                                    <input v-model="form.bank_account_name" type="text" class="form-control form-control-line">
                                    <span class="text-danger">{{errors.bank_account_name}}</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="example-email" class="col-md-12">Bank Account Number</label>
                                <div class="col-md-12">
                                    <input v-model="form.bank_account_number" class="form-control form-control-line">
                                    <span class="text-danger">{{errors.bank_account_number}}</span>
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <div class="col-sm-12 text-center">
                                    <button v-if="processing" class="btn btn-success" id="update-bank-details">Processing...</button>
                                    <button v-else class="btn btn-success" id="update-bank-details">Update Bank Details</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState,mapActions} from 'vuex'
export default {
    props:{
        user:{
            type:Object,
            required:true
        },
        
    },
    data(){
        return{
            form:{
                bank_name: this.user.bank_name,
                bank_account_name:this.user.bank_account_name,
                bank_account_number:this.user.bank_account_number
            },
            banks:[
                'First Bank',
                'Guaranty Trust Bank',
                'Zenith Bank',
                'Stanbic IBTC Bank',
                'Access Bank (Diamond)',
                'Ecobank Bank',
                'Fidelity Bank',
                'Access Bank',
                'Unity Bank Plc',
                'Skye Bank (Polaris)',
                'Keystone Bank Limited',
                'First City Monument Bank',
                'Wema Bank',
                'Citibank',
                'Standard Chartered Bank',
                'Heritage Bank Plc',
                'BNI',
                'United Bank for Africa',
                'Savanna Bank',
                'Sterling Bank',
                'Societe Generale',
                'NSIA Banque agnence',
                'Union Bank',
                'Polaris Bank',
                'Union Bank'
            ],
            errors:{
                bank_name:'',
                bank_account_name:'',
                bank_account_number:''
            }
        }  
    },

    created(){
        console.log(this.user)
    },

    computed:{
        ...mapState({
            processing:state=>state.processing
        }),
        emptyBank(){return !this.user.bank_name ? true:false}
    },
    methods:{
        ...mapActions('userStore',['updateBank']),
        submit(){
            this.updateBank(this.form).catch(err=>{
                if (err.response && err.response.status === 422) {
                    let errors = err.response.data.errors
                    console.log(Object.entries(errors))
                    if('bank_name' in errors){
                        this.errors.bank_name = errors.bank_name
                    }
                }
                this.$store.commit('processed',null,{root:true})
            })
        }
    }
}
</script>