<template>
    <div class="counter-box p-40 shadow2 r-5">
        <div class="float-right">
            <img :src="image">
        </div>
        <div class="sc-counter s-36">{{counter}}</div>
        <h6 class="counter-title">{{title}}</h6>
    </div>
</template>

<script>
export default {
  name: 'content-box',
  props: {
    image:{
        type:String,
        required:true,
    },
    title:{
        type:String,
        required:true
    },
    counter:{
        type:Number,
        required:true
    },
  }
}
</script>