<template>
    <div class="card">
        <div class="card-header">
            Registration fee ($)
        </div>
        <div class="card-body">
            <form class="form-horizontal form-materia" @submit.prevent="submit">
                <div class="form-group">
                    <label class="col-md-12">Registration fee</label>
                    <div class="col-md-12">
                        <input type="text" v-model="form.entry_payment" class="form-control form-control-line">
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-sm-12">
                        <span class="btn btn-success" v-if="processing">...processing</span>
                        <button v-else class="btn btn-success"><i class="icon-save mr-2"></i>Update</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {mapActions,mapState} from 'vuex'
export default {
    name:'entry-pay-setting',
    props:{
        systemSettings:{
            type:Object,
            required:true
        }
    },
    data(){
        return {
            form:{
                entry_payment:this.systemSettings.entry_payment
            }
        }
    },

    computed:{
        ...mapState({
            processing:state=>state.processing
        })
    },

    methods:{
        ...mapActions('adminStore',['updateSettings']),
        submit(){
            this.updateSettings(this.form)
        }
    }
}
</script>