
    
<!-- Pre loader -->
<template>
    
    <div>
        <side-bar/>
        <!--Sidebar End-->
        <div class="page has-sidebar-left">	
            <top-bar/>
            <div class="container-fluid animatedParent animateOnce my-3">
                <router-view/>
            </div>
        </div>
            <!-- Add the sidebar's background. This div must be placed
            immediately after the control sidebar -->
        <div class="control-sidebar-bg shadow white fixed"></div>
    </div>

</template>

<style scoped>
    @import url('/assets/css/app.css');
    .loader {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #F5F8FA;
        z-index: 9998;
        text-align: center;
    }

    .plane-container {
        position: absolute;
        top: 50%;
        left: 50%;
    }
    
    .gift {
        width: 100%;
    }
    
    .headerlogo {
        width: 100%;
    }
    

    
    
    </style>

<script>
    import SideBar from '@/components/user/SideBar'
    import TopBar from '@/components/user/TopBar'
    export default {
        components:{
            SideBar,
            TopBar
        }
    }
</script>