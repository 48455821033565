<template>
    <div>
        <tab-header/>
        <div class="container-fluid animatedParent animateOnce my-3">
            <div class="">
                <div class="tab-content" id="v-pills-tabContent">
				
					<loan-request-tab/>
					
					<pay-back-tab/>
					
					<transaction-history-tab/>
	
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import TabHeader from '@/components/user/loan/TabHeader'
import LoanRequestTab from '@/components/user/loan/LoanRequestTab'
import PayBackTab from '@/components/user/loan/PayBackTab'
import TransactionHistoryTab from '@/components/user/loan/TransactionHistoryTab'
export default {
    components:{
        TabHeader,
        LoanRequestTab,
        PayBackTab,
        TransactionHistoryTab
    }
}
</script>