<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-3">
                <div class="card-header white text-green">Pin Registration History</div>
                <div class="card-body ">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">S/N</th>
                                    <th scope="col">Buyer</th>
                                    <th scope="col">Seller</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Date</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="loading">
                                    <td colspan="10">
                                        <b-card>
                                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                                            <b-skeleton animation="throb" width="55%"></b-skeleton>
                                            <b-skeleton animation="throb" width="70%"></b-skeleton>
                                        </b-card>
                                    </td>
                                </tr>
                                <template v-else>
                                    <tr v-if="pinRegistrationHistory.length < 1">
                                        <td colspan="6">
                                            <p class="alert alert-info text-center">
                                                There no Pin Registrations
                                            </p>
                                        </td>
                                    </tr>
                                    <tr v-else v-for="purchase,i in pinRegistrationHistory" :key="i">
                                        <td>{{++i}}</td>
                                        <td>{{purchase.buyer.username}}</td>
                                        <td>{{purchase.seller.username}}</td>
                                        <td>
                                            <span :class="['btn btn-sm',{'btn-success':purchase.is_successful,'btn-danger':!purchase.is_successful}]">{{purchase.is_successful ? 'Approved' : 'Not-approved'}}</span>
                                        </td>
                                        <td>{{purchase.created_at}}</td>
                                    </tr>
                                </template> 
                            </tbody>
                        </table>
                        <ul class="pagination pagination-sm no-margin float-right">
                            <li class="page-item text-green"><a href="#" class="page-link">«</a>
                            </li>
                            <li class="page-item active text-green"><a href="#" class="page-link">1</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">2</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">3</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">»</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters,mapActions,mapState} from 'vuex'
export default {
    computed:{
        ...mapState({
            loading:state=>state.loading
        }),
        ...mapGetters('adminStore',['pinRegistrationHistory'])
    },

    created(){
        if(this.pinRegistrationHistory.length==0){
            this.getPinRegistrationHistory()
        }
    },

    methods:{
        ...mapActions('adminStore',['getPinRegistrationHistory'])
    }
}
</script>