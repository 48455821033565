import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue } from 'bootstrap-vue';


Vue.config.productionTip = false

Vue.use(BootstrapVue)
    //Vue.use(toastr)

export default new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')