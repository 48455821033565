<template>
    <header class="blue accent-3 relative">
        <div class="container-fluid text-blue">
            <div class="row justify-content-between">
                <ul class="nav nav-material nav-material-white responsive-tab" id="v-pills-tab" role="tablist">
                    <li>
                        <a class="nav-link active" id="v-pills-Request-for-cash-tab" data-toggle="pill" href="#v-pills-Request-for-cash" role="tab" aria-controls="v-pills-Request-for-cash" aria-selected="false"><i class="icon icon-money-bag "></i>Request for Loan</a>
                    </li>
                    <li>
                        <a class="nav-link" id="v-pills-Request-for-loan-tab" data-toggle="pill" href="#v-pills-Request-for-loan" role="tab" aria-controls="v-pills-Request-for-loan" aria-selected="false"><i class="icon icon-money-bag "></i>Pay Back Loan</a>
                    </li>
                    <li>
                        <a class="nav-link" id="v-pills-Withdrawal-History-tab" data-toggle="pill" href="#v-pills-Withdrawal-History" role="tab" aria-controls="v-pills-Withdrawal-History" aria-selected="false"><i class="icon icon-document-list"></i>Loan Transaction History</a>
                    </li>
                </ul>
            </div>
        </div>
    </header>
</template>
<script>
export default {
    
}
</script>