<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card my-3">
                <div class="card-body">
                    <div class="card-header white text-green">Loan Request History</div><br>
                        <div class="form-row" style="overflow-x:auto;">
                            <table id="example2" class="table table-bordered table-hover data-tables"
                                    data-options='{ "paging": false; "searching":false}'>
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Username</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Level</th>
                                        <th>Date</th>
                                    </tr> 
                                </thead>
                                <tbody>
                                    <tr v-if="loading">
                                        <td colspan="10">
                                            <b-card>
                                                <b-skeleton animation="throb" width="85%"></b-skeleton>
                                                <b-skeleton animation="throb" width="55%"></b-skeleton>
                                                <b-skeleton animation="throb" width="70%"></b-skeleton>
                                            </b-card>
                                        </td>
                                    </tr>
                                    <template v-else>
                                        <tr v-if="loanRequestHistory.length == 0">
                                            <td colspan="5">
                                                <p class="alert alert-info text-center">
                                                    There are no loan request history
                                                </p>
                                            </td>
                                        </tr>
                                        <tr v-else v-for="reg,i in loanRequestHistory" :key="i">
                                            <th scope="row">{{++i}}</th>
                                            <td>{{reg.user.username}}</td>
                                            <td>{{reg.loan_amount}}</td>
                                            <td>
                                                <span :class="['alert text-white',reg.loan_status=='owing' ? 
                                                'alert-warning bg-yellow': (reg.loan_status=='pending') ? 
                                                'alert-info bg-blue' : (reg.loan_status=='paid') ? 
                                                'alert-success bg-green' : 'alert-danger bg-red']">{{reg.loan_status}}</span>
                                                
                                            </td>
                                            <td>{{reg.level_id}}</td>
                                            <td>{{reg.created_at}}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>	
                    </div>
                </div>                        
        </div>
    </div>
</template>

<script>
import {mapActions,mapState,mapGetters} from 'vuex'
export default {

    computed:{
        ...mapState({
            loading:state=>state.loading
        }),

        ...mapGetters('adminStore',['loanRequestHistory'])
    },

    created(){
        if(this.loanRequestHistory.length == 0){
            this.getLoanRequestHistory()
        }
    },

    methods:{
        ...mapActions('adminStore',['getLoanRequestHistory'])
    }
    
}
</script>