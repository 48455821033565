import auth_api from "@/api/auth-api";
import vm from "@/main";
import toastr from "toastr";

export default {
    /**
     *
     * @param {*} param0
     * @param {*} param1
     */
    login({ commit }, form) {
        commit("processing", null, { root: true });
        return auth_api.login(form).then(res => {
            console.log(res.data);
            if (res.data.success) {
                let user = res.data.data.user;
                commit("authenticated", res.data);
                commit('user', user, { root: true })
                if (user.is_admin) {
                    vm.$router.push({ name: "admin-dashboard" });
                }
                else if (user.is_approved) {
                    vm.$router.push({ name: "user-dashboard" });
                } else {
                    vm.$router.push({ name: "unapproved" });
                }
                commit("processed", null, { root: true });
            } else {
                commit("processed", null, { root: true });
                console.log("error");
            }
        });
    },

    /**
     *
     * @param {*} param0
     * @param {*} data
     */
    register({ commit }, data) { 
        commit("processing", null, { root: true });
        return auth_api.register(data).then(res=> {
            if(res.data.success){
                toastr.success("You have successfully registered, please login")
                vm.$router.push({ name: "login" });
            }else{
                toastr.console.error("An error occured ,please try again")
            }
            commit("processed", null, { root: true });
        });
    },

    logout({ commit }) {
        commit("logout");
        vm.$router.push({name:'login'})
    }
};