<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-3">
                <div class="card-header white text-green">CLAIMED INCENTIVES</div>
                <div class="card-body ">
                    <div class="table-responsive">
                        <table id="example2" class="table table-bordered table-hover data-tables"
                                        data-options='{ "paging": false; "searching":false}'>
                            <thead>
                            <tr>
                                <th scope="col">S/N</th>
                                <th scope="col">Incentive Claimed</th>
                                <th scope="col">Level</th>
                                <th scope="col">Date</th>
                                <th scope="col">Time</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>T-shirt, cap, bag, and mobile phone</td>
                                <td>Class 1</td>
                                <td>January 20, 2020 </td>
                                <td>10:30pm</td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Home Theather, Water Dispenser, and Solar Lamp.</td>
                                <td>Class 2</td>
                                <td>March 23, 2020 </td>
                                <td>10:30pm</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>				
    </div>
</template>
<script>
export default {
    
}
</script>