<template>
    <header class="blue accent-3 relative">
        <div class="container-fluid text-blue">
            <div class="row justify-content-between">
                <ul class="nav nav-material nav-material-white responsive-tab" id="v-pills-tab" role="tablist">
                    <li>
                        <a class="nav-link active" id="v-pills-wallet-summary-tab" data-toggle="pill"  href="#v-pills-wallet-summary" role="tab" aria-controls="v-pills-wallet-sumary"><i class="icon icon-account_balance_wallet" ></i>Wallet Summary</a>
                    </li>
                    <li>
                        <a class="nav-link" id="v-pills-Wallet-Detailed-Calculation-tab" data-toggle="pill" href="#v-pills-Wallet-Detailed-Calculation" role="tab" aria-controls="v-pills-Wallet-Detailed-Calculation" aria-selected="false"><i class="icon icon-account_balance_wallet"></i>Wallet Detailed Calculation</a>
                    </li>
                    <li>
                        <a class="nav-link" id="v-pills-Withdrawal-History-tab" data-toggle="pill" href="#v-pills-Withdrawal-History" role="tab" aria-controls="v-pills-Withdrawal-History" aria-selected="false"><i class="icon icon-document-list"></i>Withdrawal History</a>
                    </li>
                    <li>
                        <a class="nav-link" id="v-pills-Request-for-cash-tab" data-toggle="pill" href="#v-pills-Request-for-cash" role="tab" aria-controls="v-pills-Request-for-cash" aria-selected="false"><i class="icon icon-money-bag "></i>Request for cash</a>
                    </li>
                </ul>
            </div>
        </div>
    </header>
</template>
<script>
export default {
    
}
</script>