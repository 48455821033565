<template>
    
    <div class="row my-3">
        <!-- bar charts group -->
        <div class="col-md-12">
            <div class="card">
                <div class="card-header bg-white">
                    <h4 class="green-text"><strong class="font-weight-bold">Wallet Summary</strong></h4>
                </div>
                <div class="card-body" style="overflow-x:auto;">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Welcome bonus</th>
                                <th scope="col">Total Direct Bonus (TRB)</th>
                                <th scope="col">Total Completion Bonus (TCB)</th>
                                <th scope="col">Total Pin Purchases (TPP)</th>
                                <th scope="col">Total withdrawals (TW)</th>
                                <th scope="col">Total Bonus (TB)</th>
                                <th scope="col">Wallet balance {TB - TW}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>${{summary.welcome_bonus}}</td>
                                <td>${{summary.total_matrix_bonus}}</td>
                                <td>${{summary.total_completion_bonus}}</td>
                                <td>${{summary.total_pin_purchase}}</td>
                                <td>${{summary.total_withdrawals}}</td>
                                <td>${{summary.total_bonus}}</td>
                                <td>${{summary.wallet_balance}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- /bar charts group -->
    
    </div>
</template>
<script>
export default {
    props:{
        summary:{
            type:Object,
            required:true
        }
    }
    
}
</script>