import { http, apiVersion } from "./api-config";

const prefix = {
    user: apiVersion + "/users",
    admin: "/admin",
    withdrawals: apiVersion + "/withdrawals",
    pinRegistrations: apiVersion + "/pin-registrations",
    pins: apiVersion + "/pins",
    //unapproved: apiVersion + "/unapproved" //unpaid users endpoint
    entryPayments: apiVersion + "/entry-payments",
    loans: apiVersion + "/loans",
    levels: apiVersion + "/levels",
    incentives: apiVersion + "/incentives",
    grants: apiVersion + "/grants",
    twoFactorAuth: apiVersion + "/2fa", //verify 2 factor authentication
    loanPayments: apiVersion + "/loan-payments"
};

const endPoints = {
    dashboard: `${prefix.user}/dashboard`,

    all: `${prefix.admin}/users`,

    user: function(id) {
        if (id) {
            return `${prefix.user}/${id}/show`;
        }
        return `${prefix.user}/user`;
    },

    upLine: function(id) {
        if (id) {
            return `${prefix.user}/${id}/upline`;
        }
        return `${prefix.user}/upline`;
    },

    eligibilityStatus: (id) => `${prefix.user}/${id}/eligibility-status`,

    sendInvitation: `${prefix.user}/send-invitation`,

    update: `${prefix.user}/user`,

    downlines: function(id) {
        return !id ?
            `${prefix.user}/downlines` :
            `${prefix.user}/${id}/downlines`;
    },

    /**
     * get wallet summary
     * @param {Number} id - user id
     */
    walletSummary: function(id) {
        return !id ?
            `${prefix.user}/wallet-summary` :
            `${prefix.user}/${id}/wallet-summary`;
    },

    /**
     * get wallet analysis
     * @param {Number} id - user id
     */
    walletAnalysis: function(id) {
        return !id ?
            `${prefix.user}/wallet-analysis` :
            `${prefix.user}/${id}/wallet-analysis`;
    },

    /**
     * get withdrawal history
     * @param {Number} id - user id
     * @returns {Array}  array of objects
     */
    withdrawalHistory: function(id) {
        return !id ?
            `${prefix.withdrawals}/user-withdrawals` :
            `${prefix.admin}/users/${id}/withdrawal-history`;
    },

    withdrawalRequest: `${prefix.withdrawals}/request`,

    updateBank: `${prefix.user}/update-bank`,

    uploadPop: `${prefix.entryPayments}/pay`,

    pinRegistration: `${prefix.pinRegistrations}/register`,

    pinPurchase: `${prefix.pins}/purchase`,

    delete: function(id) {
        return `${prefix.user}/${id}/delete`;
    },

    profile: function(id) {
        return !id ?
            `${prefix.user}/profile` :
            `${prefix.user}/${id}/profile`;
    },



    //get levels
    levels: `${prefix.levels}/`,

    /**
     * get food-vouchar statuses
     * @param {*} id - user id
     
    foodVoucherClaimStatus: function(id) {
        return `${prefix.user}/food-voucher-claims/levels/${id}/status`;
    },*/

    //get food-vouchar claim statuses
    //foodVoucherClaimStatuses: `${prefix.user}/food-voucher-claims/statuses`,

    //get incentive-claim statuses
    incentiveClaimStatuses: `${prefix.incentives}/user/statuses`,

    incentives: `${prefix.incentives}/`,

    /**
     * claim incentives
     * @param {*} id - user id
     */
    claimIncentives: `${prefix.incentives}/user/claim`,

    totalWithdrawals: function(id) {
        return !id ?
            `${prefix.user}/withdrawals/total` :
            `${prefix.admin}/users/${id}/withdrawals/total`;
    },

    withdrawalStatus: `${prefix.user}/withdrawals/status`,

    pendingPinRegApprovals: `${prefix.pinRegistrations}/pending-approvals`,

    pinPurchaseHistory: `${prefix.pins}/purchase-history`,

    loanRequest: `${prefix.loans}/request`,
    loanRequestHistory: `${prefix.loans}/request-history`,
    loanEligibility: `${prefix.loans}/eligibility`,
    recentLoan: `${prefix.loans}/recent`,

    pinMerchants: `${prefix.pins}/merchants`,
    pinRegistrationStatus: `${prefix.pinRegistrations}/status`,
    //pendingPin

    entryPaymentStatus: `${prefix.entryPayments}/status`,

    approvePinRegistration: id => {return `${prefix.pinRegistrations}/${id}/approve`},
    disapprovePinRegistration: id => {return `${prefix.pinRegistrations}/${id}/disapprove`},
    pinRegistrationHistory: `${prefix.pinRegistrations}/history`,

    grantClaimStatuses: `${prefix.grants}/user/statuses`,
    claimGrant: `${prefix.grants}/claim`,
    approveGrant: id => `${prefix.grants}/${id}/approve`,
    disapproveGrant: id => `${prefix.grants}/${id}/disapprove`,
    //pendingGrants: `${prefix.grants}/pending-approvals`

    //....................................................2fa..........................
    verify2fa: `${prefix.twoFactorAuth}/verify-qr`,
    generate2fa: `${prefix.twoFactorAuth}/generate-qr`,
    disable2fa: `${prefix.twoFactorAuth}/disable-2fa`,
    twoFactorStatus: `${prefix.twoFactorAuth}/status`,

    genealogy: `${prefix.user}/genealogy`,

    walletBalance: `${prefix.user}/wallet-balance`,

    //..............................................Loan Payment..........................//
    loanBalance:loanId => `${prefix.loanPayments}/loan-balance/${loanId}`,
    loanPayment:loanId => `${prefix.loanPayments}/${loanId}/pay`,
    loanPaymentHistory:loanId => `${prefix.loanPayments}/${loanId}/history`
};

export default {
    dashboardData() {
        return http().get(apiVersion + endPoints.dashboard);
    },

    all(query = null) {
        return http().get(endPoints.all + query);
    },

    user(id = null) {
        return http().get(endPoints.user(id));
    },

    eligibilityStatus: (id) => { return http().get(endPoints.eligibilityStatus(id)); },

    sendInvitation(form) {
        return http().post(endPoints.sendInvitation, form)
    },

    update(form) {
        return http().put(endPoints.update, form);
    },

    upLine(id) {
        return http().get(endPoints.upLine(id))
    },

    downlines(id = null) {
        return http().get(endPoints.downlines(id));
    },

    /**
     * get wallet summary
     * @param {Number} id - user id
     */
    walletSummary(id = null) {
        return http().get(endPoints.walletSummary(id));
    },

    /**
     * get wallet analysis
     * @param {Number} id - user id
     */
    walletAnalysis(id = null) {
        return http().get(endPoints.walletAnalysis(id));
    },

    withdrawalHistory(id = null) {
        return http().get(endPoints.withdrawalHistory(id));
    },

    // totalWithdrawals(id = null) {
    //     return http().get(endPoints.totalWithdrawals(id));
    // },

    withdrawalRequest(data) {
        return http().post(endPoints.withdrawalRequest, data);
    },

    updateBank(data) {
        return http().patch(endPoints.updateBank, data)
    },

    uploadPop(form) {
        return http().post(endPoints.uploadPop, form)
    },

    pinRegistration(data) {
        return http().post(endPoints.pinRegistration, data)
    },

    pinPurchase(data){
        return http().post(endPoints.pinPurchase, data)
    },



    delete(id) {
        return http().delete(endPoints.delete(id));
    },

    // updateProfileDetails(form){
    //   return http().put(endPoints.update,form)
    // }

    profile(id = null) {
        return http().get(endPoints.profile(id));
    },



    levels() {
        return http().get(endPoints.levels);
    },

    /**
     * food-voucher claim status
     
    foodVoucherClaimStatus(id) {
        return http().get(endPoints.foodVoucherClaimStatus(id));
    },*/

    /**
     * food-voucher claim satuses
     
    foodVoucherClaimStatuses() {
        return http().get(endPoints.foodVoucherClaimStatuses);
    },*/

    /**
     * incentive claim statuses
     */
    incentiveClaimStatuses() {
        return http().get(endPoints.incentiveClaimStatuses);
    },

    /**
     * claim incentives
     * @param {*} id - leve_id
     */
    claimIncentives(data) {
        return http().post(endPoints.claimIncentives,data);
    },

    incentives(){
        return http().get(endPoints.incentives)
    },

    withdrawalStatus() {
        return http().get(endPoints.withdrawalStatus);
    },

    pendingPinRegApprovals() {
        return http().get(endPoints.pendingPinRegApprovals);
    },

    pinPurchaseHistory() {
        return http().get(endPoints.pinPurchaseHistory);
    },

    loanRequest(data){
        return http().post(endPoints.loanRequest,data)
    },
    loanRequestHistory(){
        return http().get(endPoints.loanRequestHistory)
    },
    loanEligibility(){
        return http().get(endPoints.loanEligibility)
    },
    recentLoan(){
        return http().get(endPoints.recentLoan)
    },

    pinMerchants(){
        return http().get(endPoints.pinMerchants)
    },
    pinRegistrationStatus(){
        return http().get(endPoints.pinRegistrationStatus)
    },

    entryPaymentStatus(){
        return http().get(endPoints.entryPaymentStatus)
    },

    approvePinRegistration(id){
        return http().patch(endPoints.approvePinRegistration(id))
    },
    disapprovePinRegistration(id){
        return http().patch(endPoints.disapprovePinRegistration(id))
    },
    pinRegistrationHistory(){
        return http().get(endPoints.pinRegistrationHistory)
    },

    grantClaimStatuses(){
        return http().get(endPoints.grantClaimStatuses)
    },
    claimGrant(data){
        return http().post(endPoints.claimGrant,data)
    },

    verify2fa(data){
        return http().post(endPoints.verify2fa,data);
    },
    generate2fa(){
        return http().post(endPoints.generate2fa)
    },
    disable2fa(data){
        return http().patch(endPoints.disable2fa,data)
    },
    twoFactorStatus(){
        return http().get(endPoints.twoFactorStatus)
    },

    genealogy(){
        return http().get(endPoints.genealogy)
    },

    walletBalance(){
        return http().get(endPoints.walletBalance)
    },

    loanBalance(loanId){
        return http().get(endPoints.loanBalance(loanId))
    },
    loanPayment(loanId,data){
        return http().post(endPoints.loanPayment(loanId),data)
    },
    loanPaymentHistory(loanId){
        return http().get(endPoints.loanPaymentHistory(loanId))
    }

};