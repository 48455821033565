import { http } from "./api-config";

const prefix = "/auth";
const endpoints = {
    auth: {
        login: `${prefix}/login`,
        register: `${prefix}/register`,
        verify: `${prefix}/verify`,
        logout: `${prefix}/logout`,
        //csrf: "/csrf-cookie",
        authenticate2Fa: `${prefix}/verify-qr`,
        password: {
            email: `/${prefix}/email`,
            reset: `/${prefix}/reset`
        }
    }
};

export default {
    /**
     *
     * @param {*} username
     * @param {*} password
     * @param {*} cb
     */
    login(form) {
        return http().post(endpoints.auth.login, form);
    },

    /**
     *
     * @param {*} data
     * @param {*} cb
     */
    register(data) {
       return http().post(endpoints.auth.register, data)
    },

    authenticate2Fa(){
        return http().post(endpoints.auth.authenticate2Fa)
    },

    // email(email, cb) {
    //   http
    //     .post(routes.password.email, email)
    //     .then(res => {
    //       if (res.data.success) {
    //         cb();
    //       } else {
    //         console.log("error");
    //       }
    //     })
    //     .catch(err => {
    //       console.log(err);
    //       vm.$store.commit("processed");
    //     });
    // },

    // csrf() {
    //   http
    //     .get("http://localhost:8000/api/csrf-cookie")
    //     .then(() => {
    //       console.log("csrf-cookie obtained successfully");
    //     })
    //     .catch(err => {
    //       console.log("unable to get csrf-cookie" + err);
    //     });
    // }
};