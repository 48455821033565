<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-3">
                <div class="card-header white text-green">Pin Purchase History</div>
                <div class="card-body ">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">S/N</th>
                                    <th scope="col">Full Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Username</th>
                                    <th scope="col">Units</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Date of Purchase</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="loading">
                                    <td colspan="10">
                                        <b-card>
                                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                                            <b-skeleton animation="throb" width="55%"></b-skeleton>
                                            <b-skeleton animation="throb" width="70%"></b-skeleton>
                                        </b-card>
                                    </td>
                                </tr>
                                <template v-else>
                                    <tr v-if="pinPurchaseHistory.length < 1">
                                        <td colspan="8">
                                            <p class="alert alert-info text-center">
                                                There no pin purchases
                                            </p>
                                        </td>
                                    </tr>
                                    <tr v-else v-for="purchase,i in pinPurchaseHistory" :key="i">
                                        <td>{{++i}}</td>
                                        <td>{{purchase.user.name}}</td>
                                        <td>{{purchase.user.email}}</td>
                                        <td>{{purchase.user.username}}</td>
                                        <td>{{purchase.units}}</td>
                                        <td>{{purchase.amount}}</td>
                                        <td>
                                            <span :class="['btn btn-sm',{'btn-success':purchase.is_successful,'btn-danger':!purchase.is_successful}]">{{purchase.is_successful ? 'Approved' : 'Not-approved'}}</span>
                                        </td>
                                        <td>{{purchase.created_at}}</td>
                                        <td>
                                        <div class="dropdown">
                                            <button class="btn btn-success  dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="caret"></i>
                                            </button>
                                            <div class="dropdown-menu " aria-labelledby="dropdownMenuButton" style="position:relative">
                                                <router-link :to="{name:'view-pop', params:{pop:purchase.pop}, query:{name:purchase.user.username,id:purchase.id} }"  class="dropdown-item text-green"><i class="icon-pageview"></i>&nbsp;&nbsp;View POP</router-link>
                                                <router-link :to="{name:'admin-user-profile',params:{id:purchase.user_id}}" class="dropdown-item text-green" ><i class="icon-drivers-license-o"></i>&nbsp;&nbsp; Approve</router-link>
                                            </div>
                                        </div>
                                        </td>
                                    </tr>
                                </template> 
                            </tbody>
                        </table>
                        <ul class="pagination pagination-sm no-margin float-right">
                            <li class="page-item text-green"><a href="#" class="page-link">«</a>
                            </li>
                            <li class="page-item active text-green"><a href="#" class="page-link">1</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">2</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">3</a>
                            </li>
                            <li class="page-item text-green"><a href="#" class="page-link">»</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters,mapActions,mapState} from 'vuex'
export default {
    computed:{
        ...mapState({
            loading:state=>state.loading
        }),
        ...mapGetters('adminStore',['pinPurchaseHistory'])
    },

    created(){
        if(this.pinPurchaseHistory.length==0){
            this.getPinPurchaseHistory()
        }
    },

    methods:{
        ...mapActions('adminStore',['getPinPurchaseHistory'])
    }
}
</script>