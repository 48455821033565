<template>
    <div class="tab-pane fade show active" id="v-pills-Request-for-cash" role="tabpanel" aria-labelledby="v-pills-Request-for-cash-tab">
        <div class="row">
            <div class="col-md-4">
                <div class="card no-b">
                    <div class="card-header bg-white">
                        <h4 class="green-text"><strong class="font-weight-bold">Request for Loan</strong></h4>
                        <small>Make a Loan request with the form below</small>
                    </div>
                    <div class="collapse show text-center" id="invoiceCard"><br>
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="text-center"><img src="/assets/img/loan.png"></div>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <b-card v-if="recentLoanIsLoaing">
                                <b-skeleton animation="throb" width="85%"></b-skeleton>
                                <b-skeleton animation="throb" width="55%"></b-skeleton>
                                <b-skeleton animation="throb" width="70%"></b-skeleton>
                                <b-skeleton animation="throb" width="85%"></b-skeleton>
                            </b-card>
                            <template v-else>
                                <form v-if="recentLoan==null || recentLoan.loan_status!='owing'" class="form-horizontal form-materia" method="POST" @submit.prevent="submit">
                                    <div class="form-group">
                                        <div class="col-md-12">                                                                                                                                                       <input type="hidden" name="level_id" value="2">
                                            <input required type="text" v-model="form.loan_amount" class="form-control form-control-line" placeholder="Enter amount">
                                            <span v-if="errors.loan_amount" class="text-danger">{{errors.loan_amount}}</span>
                                            <div class="form-group"><br>
                                                <div class="col-sm-12 text-right ">
                                                    <span v-if="processing" class="btn btn-success">...</span>
                                                    <button v-else type="submit" class="btn btn-sm btn-success" id="cash-req"><i class="icon-arrow_forward mr-2"></i>
                                                        Request Loan
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div v-else class="alert alert-info">
                                    <p>You are currently owing, hence you cannot request for loan</p>
                                </div>
                                
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card no-b">
                    <div class="card-header bg-white">
                        <h4 class="green-text"><strong class="font-weight-bold">Eligibility</strong></h4>
                        <small>Your Loan request status</small>
                    </div>

                    <b-card v-if="recentLoanIsLoaing || levels.length==0">
                        <b-skeleton animation="throb" width="85%"></b-skeleton>
                        <b-skeleton animation="throb" width="55%"></b-skeleton>
                        <b-skeleton animation="throb" width="70%"></b-skeleton>
                        <b-skeleton animation="throb" width="85%"></b-skeleton>
                    </b-card>
                    <div v-else class="collapse show text-center" id="invoiceCard">
                        <div class="col-md-12 text-center">
                            <div class="card-body p-0"><br>
                                <img class="img-center" src="/assets/img/checkman.png"><br><br>
                                <span v-if="recentLoan==null || recentLoan.loan_status!='owing'" class="badge text-white bg-green">Eligible to - {{loanRecievable()}}</span>
                                <span v-else class="badge text-white bg-red">Not-Eligible</span>
                            </div>
                        </div>
                            <div class="card-body  text-center">
                            <small class="font-weight-light">Ensure you clear your previous loan to <br>be allowed to collect another.</small>
                        </div>                                            
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card no-b">
                    <div class="card-header bg-white">
                        <h4 class="green-text"><strong class="font-weight-bold">Current Loan</strong></h4>
                        <small>Your current loan balance</small>
                    </div>
                    <b-card v-if="recentLoanIsLoaing">
                        <b-skeleton animation="throb" width="85%"></b-skeleton>
                        <b-skeleton animation="throb" width="55%"></b-skeleton>
                        <b-skeleton animation="throb" width="70%"></b-skeleton>
                        <b-skeleton animation="throb" width="85%"></b-skeleton>
                    </b-card>
                    <div v-else class="collapse show text-center" id="invoiceCard">
                        <div class="col-md-4">
                            <div class="card-body p-0">
                                <img src="/assets/img/withdraw3.png">
                            </div>
                        </div>
                        <div class="card-body text-center">
                            <template v-if="recentLoan==null || recentLoan.loan_status!='owing'">
                                <small>You are not owing</small>
                            </template>
                            <template v-else>
                                <small>loan Account</small>
                                <h1 class="font-weight-bold text-red">{{recentLoan.loan_amount}}</h1>
                            </template>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState,mapActions,mapGetters} from 'vuex'
import toastr from 'toastr'
export default {
    data(){
        return {
            form:{
                loan_amount:null
            },
            errors:{
                loan_amount:null
            }
        }
    },

    computed:{
        ...mapState({
            processing:state=>state.processing,
            loading:state=>state.loading
        }),

        ...mapGetters(["user"]),
        ...mapGetters('userStore',['recentLoan','levels']),
        recentLoanIsLoaing(){
            if(this.recentLoan == null){
                return false
            }
            return Object.entries(this.recentLoan).length==0 ? true : false
        },

        
    },

    created(){
        if(Object.entries(this.recentLoan).length===0){
            this.getRecentLoan()
        }
        if(Object.entries(this.levels).length == 0){
            this.getLevels()
        }
        if(Object.entries(this.user).length == 0){
            this.getUser()
        }
    },

    methods:{
        ...mapActions(['getUser']),
        ...mapActions('userStore',['requestLoan','getRecentLoan','getLevels']),
        submit(){
            console.log(this.form)
            this.requestLoan(this.form).catch((err)=>{
                if(err.response.status==422){
                    if(err.response.errors){
                        this.errors = err.response.errors
                        toastr.error("Invalid data supplied")
                    }else{
                        toastr.error(err.response.message)
                    }
                }else{
                    toastr.error("An internal error occured")
                } 
                this.$store.commit('processed',null,{root:true})
            })
        },

        loanRecievable(){
           let level = this.levels.find(ele=>ele.id === this.user.level_id)
           console.log(level)
           return level.loan_receivable
        }
    }
}
</script>