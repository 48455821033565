<template>
    <div>
        <div class="container-fluid animatedParent animateOnce my-3">
            <div class="">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <div class="row">
                            <template v-if="loading">
                                <b-card class="col-md-12">
                                    <b-skeleton animation="throb" width="85%"></b-skeleton>
                                    <b-skeleton animation="throb" width="55%"></b-skeleton>
                                    <b-skeleton animation="throb" width="70%"></b-skeleton>
                                </b-card>
                            </template>
                            <template v-else>
                                <div class="col-md-6">
                                    <pin-purchase :user="user"/>
                                </div>
                            
                                <div class="col-md-6">
                                    <pin-unit :user="user"/>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PinPurchase from '@/components/user/pin/PinPurchase'
import PinUnit from '@/components/user/pin/PinUnit.vue'
import {mapActions,mapGetters,mapState} from 'vuex'
export default {
    components:{
        PinPurchase,
        PinUnit,
    },

    computed:{
        ...mapState({
            loading:state=>state.loading
        }),

        ...mapGetters(['user'])
    },

    created(){
        if(Object.entries(this.user).length==0){
            this.getUser()
        }
    },

    methods:{
        ...mapActions('userStore',['getUser'])
    }

}
</script>