<template>
    <div class="col-md-4">
        <div class="card">
            <div class="card-header white">
                <strong class="font-weight-bold"> BANK DETAILS </strong>
            </div>
            <br>
                <div class="text-center"> <img  src="assets/img/banks.png" style= "max-width: 100%;"></div>
            <div class="card-body text-center">
                <h4 class="font-weight-bold"> Guaranty Trust Bank</h4>
                <p>
                    <ul>
                        <li> Account Name    | IDEAL FLOURISH VISIONARY NETWORK</li>
                        <li> Account Number  | 0494911122</li>
                        <li></li>
                    </ul>
                </p>	     
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        bank:{
            type:Object,
            //required:true
        }
    }
}
</script>