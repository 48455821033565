import api from "../../api/user-api";
import toastr from 'toastr';
//import vm from "../../main";

export default {
    dashboardData({ commit }) {
        api.dashboardData().then(res => {
            //alert();
            //console.log("dash");
            if (res.data.success) {
                commit("dashboardData", res.data.data);
                commit("processed", null, { root: true });
            } else {
                console.log("error");
            }
        });
    },

    /**
     *
     * @param {*} param0
     * @param {*} param1
     */
    all({ commit }, { action }) {
        commit("loading", null, { root: true });
        return api
            .all()
            .then(res => {
                if (res.success) {
                    commit("all", res.data);
                    commit("processed", null, { root: true });
                } else {
                    action.error;
                }
            })
            .catch(err => {
                console.log(err);
                action.exception;
            });
    },

    /**
     * get authenticated user
     * @param {*} param0 
     * @returns 
     */
    getUser({ commit },id=null) {
        commit("loading", null, { root: true });
        return api
            .user(id)
            .then(res => {
                if (res.data.success) {
                    if(!id){
                        commit("user", res.data.user, { root: true });
                    }else{
                        commit("showUser", res.data.user, 'adminStore');
                    }
                    commit("loaded", null, { root: true });
                } else {
                    toastr.error('Error! unable to fetch authenticated user')
                    console.log(res.data);
                }
            })
            .catch(err => {
                toastr.error('An error occured, please try again')
                console.log(err);
            });
    },

    getEligibilityStatus({ commit }) {
        let user = localStorage.getItem('cmc-user');
        return api.eligibilityStatus(user).then(res => {
            if (res.data.success) {
                commit("eligibilityStatus", res.data.eligibility_status, 'userStore')
            } else {
                console.log(res)
                toastr.error("Unable to get eligibility status")
            }
        })
    },

    sendInvitation({ commit }, form) {
        commit('processing', null, { root: true })
        return api.sendInvitation(form).then(res => {
            if (res.data.success) {
                commit('processed', null, { root: true })
                toastr.success("Invitation sent successfully")
            } else {
                commit('processed', null, { root: true })
                toastr.error("Unable to send invittion email, please try again")
                console.log(res)
            }
        })
    },

    updateProfile({ commit }, form) {
        commit("processing", null, { root: true });
        return api.update(form).then(res => {
            if (res.data.success) {
                commit("user", res.data.user, { root: true });
                commit("processed", null, { root: true });
                toastr.success('User updated sucessfuly')
            } else {
                toastr.error('Unable to update user')
            }
        });
    },

    getUpline({ commit },id=null) {
        commit('loading', null, { root: true })
        return api.upLine(id).then(res => {
            if (res.data.success) {
                commit("upline", res.data.upline);
                commit("loaded", null, { root: true });
                toastr.success('Upline retrieved sucessfuly')
            } else {
                toastr.error('Unable to retrieve upline')
            }
        })
    },

    // profileData({ commit }) {
    //     commit("loading", null, { root: true });
    //     return api.profile().then(res => {
    //         if (res.data.success) {
    //             commit("profile", res.data.data);
    //             commit("loaded", null, { root: true });
    //         } else {
    //             console.log("error");
    //         }
    //     });
    // },

    getDownlines({ commit }) {
        commit("loading", null, { root: true });
        return api.downlines().then(res => {
            if (res.data.success) {
                commit("downlines", res.data.downlines);
                toastr.success('Downlines retrieved successfully')
            } else {
                toastr.error('Unable to retrieve downlines')
            }
            commit("loaded", null, { root: true });
        }).catch(err=>{
            toastr.error('An internal error occured')
            console.log(err)
            commit("loaded", null, { root: true });
        })
    },

    getWalletSummary({ commit }) {
        commit("loading", null, { root: true });
        return api.walletSummary().then(res => {
            if (res.data.success) {
                commit("walletSummary", res.data.wallet_summary);
                commit("loaded", null, { root: true });
                toastr.success('Wallet summary retrieved successfully')
            } else {
                console.log(res);
                toastr.error('Unable to retrieve Wallet summary')
            }
        });
    },

    getWalletAnalysis({ commit }, id = null) {
        commit("loading", null, { root: true });
        return api.walletAnalysis(id).then(res => {
            if (res.data.success) {
                commit("walletAnalysis", res.data.wallet_analysis);
                commit("loaded", null, { root: true });
                toastr.success('Wallet analysis retrieved successfully')
            } else {
                console.log(res);
                toastr.error('Unable to retrieve Wallet summary')
            }
        });
    },

    getWithdrawalHistory({ commit }, id = null) {
        commit("loading", null, { root: true });
        return api.withdrawalHistory(id).then(res => {
            if (res.data.success) {
                commit("withdrawalHistory", res.data.data);
                commit("loaded", null, { root: true });
                toastr.success('Withdrawal istory retrieved successfully')
            } else {
                console.log(res);
                toastr.error("unable to retrieve withdawal history")
            }
        });
    },

    withdrawalRequest({ commit }, data) {
        commit("processing", null, { root: true });
        return api.withdrawalRequest(data).then(res => {
            if (res.data.success) {
                commit("processed", null, { root: true });
                toastr.success('Withdrawal request submitted successfully')
            } else {
                console.log(res);
                toastr.error("unable to submit withdawal request")
            }
        })
    },

    updateBank({ commit }, data) {
        commit('processing', null, { root: true })
        return api.updateBank(data).then(res => {
            if (res.data.success) {
                commit('processed', null, { root: true })
                commit('user', res.data.user)
                toastr.success('bank details updated successfully')
            } else {
                console.log(res);
                toastr.error("unable to update bank")
            }
        })
    },

    pinRegistration({ commit, dispatch }, {data,cb}) {
        commit('processing', null, { root: true })
        return api.pinRegistration(data).then(res => {
            if (res.data.success) {
                toastr.success('Pin registation submitted successfully')
                dispatch('getPinRegistrationStatus')
                cb()
            } else {
                console.log(res);
                toastr.error("unable to process pin registration")
            }
            commit('processed', null, { root: true })
        }).catch(()=>{
            toastr.error('An internal error occured')
            commit('processed', null, { root: true })
        })
    },

    uploadPop({ commit, dispatch }, {data,cb}) {
        commit('processing', null, { root: true })
        return api.uploadPop(data).then(res => {
            if (res.data.success) {
                dispatch('getUser')
                cb()
                toastr.success('Proof of payment submitted successfully')
            } else {
                console.log(res);
                toastr.error("unable to process proof of payment")
            }
            commit('processed', null, { root: true })
        })
    },

    purchasePin({commit,dispatch},data){
        commit('processing', null, { root: true })
        return api.pinPurchase(data).then(res => {
            if (res.data.success) {
                commit('processed', null, { root: true })
                dispatch('getUser')
                toastr.success('Pin purchase in process, you will be notified on approval')
            } else {
                console.log(res);
                toastr.error("unable to process pin purchase")
            }
        })
    },


    getLevels({ commit }) {
        commit("loading", null, { root: true });
        return api.levels().then(res => {
            if (res.data.success) {
                commit("levels", res.data.levels);
                commit("loaded", null, { root: true });
            } else {
                toastr.error('Unable to load levels')
            }
        }).catch(err=>{
            toastr.error("An internal error occured")
            console.log(err)
        });
    },

    // foodVoucherClaimStatus({ commit }, level_id) {
    //   commit("processing", null, { root: true });
    //   return api.foodVoucherClaimStatus(level_id).then(res => {
    //     if (res.data.success) {
    //       commit("voucherClaimStatuses", {
    //         level_id: level_id,
    //         status: res.data.data
    //       });
    //       commit("processed", null, { root: true });
    //       return res.data.data;
    //     } else {
    //       console.log("error");
    //     }
    //   });
    // },

    foodVoucherClaimStatuses({ commit }) {
        commit("loading", null, { root: true });
        return api.foodVoucherClaimStatuses().then(res => {
            if (res.data.success) {
                commit("voucherClaimStatuses", res.data.data);
                commit("loaded", null, { root: true });
                return res.data.data;
            } else {
                console.log("error");
            }
        });
    },

    getIncentiveClaimStatuses({ commit }) {
        commit("loading", null, { root: true });
        api.incentiveClaimStatuses().then(res => {
            if (res.data.success) {
                commit("incentiveClaimStatuses", res.data.incentive_claim_statuses);
                //return res.data.data;
            } else {
                toastr.error('Unable to load incentive claim statuses')
            }
            commit("loaded", null, { root: true });
        }).catch(err=>{
            commit("loaded", null, { root: true });
            toastr.error("An internal error occured")
            console.log(err)
        })
    },

    getIncentives({commit}){
        commit('loading',null,{root:true})
        api.incentives().then(res=>{
            if(res.data.success){
                commit('incentives',res.data.incentives)
                //toastr.success("")
            }else{
                toastr.error('Unable to load incentives')
            }
            commit('loaded',null,{root:true})
        }).catch(err=>{
            toastr.error('An internal error occured')
            commit('loaded',null,{root:true})
            console.log(err)
        })
    },

    claimIncentive({commit},data){
        commit('processing',null,{root:true})
        api.claimIncentives(data).then(res=>{
            if(res.data.success){
                toastr.success("Incentive claim submitted successfully")
            }else{
                toastr.error(res.data.message)
            }
            commit('processed',null,{root:true})
        }).catch(err=>{
            toastr.error("An internal error occured")
            console.log(err)
            commit('processed',null,{root:true})
        })
    },

    withdrawalStatus({ commit }) { 
        commit("loading", null, { root: true });
        return api.withdrawalStatus().then(res => {
            if (res.data.success) {
                commit("withdrawalStatus", res.data.data);
                commit("loaded", null, { root: true });
                return res.data.data;
            } else {
                console.log("error");
            }
        });
    },

    getPendingPinRegApprovals({ commit }) {
        commit("loading", null, { root: true });
        return api.pendingPinRegApprovals().then(res => {
            if (res.data.success) {
                commit("pendingPinRegApprovals", res.data.pending_approvals);
                commit("loaded", null, { root: true });
            } else {
                console.log("error");
            }
        });
    },

    getPinPurchaseHistory({ commit }) {
        commit("loading", null, { root: true });
        api.pinPurchaseHistory().then(res => {
            if (res.data.data) {
                commit("pinPurchaseHistory", res.data.data);
                toastr.success('Pin purchase history fetched successfully')
                //return res.data.data;
            } else {
                toastr.error('Unable to fetch pin purchase history')
            } 
            commit("loaded", null, { root: true });
        });
    },

    requestLoan({commit},data){
        commit('processing',null,{root:true})
        
       return api.loanRequest(data).then(res=>{
            //toastr.success(res.data.message)
            if(res.data.success){
                toastr.success('Request submiteed successfully')
            }else{
                toastr.error(res.data.message)
            }
            commit('processed',null,{root:true})
        })
    },

    getLoanRequestHistory({commit}){
        commit('loading',null,{root:true})
        api.loanRequestHistory().then(res=>{
            if(res.data.success){
                commit('loanRequestHistory',res.data.loan_request_history)
            }else{
                toastr.error('Unable to fetch Loan request history')
            }
            commit('loaded',null,{root:true})
        }).catch(()=>{
            toastr.error('An error occured while fetching loan request history')
        })
    },

    getRecentLoan({commit}){
        commit('loading',null,{root:true})
        return api.recentLoan().then(res=>{
            if(res.data.success){
                commit('recentLoan',res.data.recent_loan)
                toastr.success('Recent loan fetched successfuly')
                return res.data.recent_loan
            }else{
                toastr.error('Unable to load current loan data, please try again')
            }
            commit('loaded',null,{root:true})
        })
    },

    getPinMerchants({commit}){
        commit('loading',null,{root:true})
        api.pinMerchants().then((res=>{
            if(res.data.success){
                commit('pinMerchants',res.data.pin_merchants)
                toastr.success('Pin merchants fetched successfully')
            }else{
                toastr.success('Unable to load Pin merchants')
            }
            commit('loaded',null,{root:true})
        })).catch(()=>{
            commit('loaded',null,{root:true})
            toastr.error('An internal error occured')
        })
    },

    getPinRegistrationStatus({commit}){
        commit('loading',null,{root:true})
        api.pinRegistrationStatus().then(res=>{
            if(res.data.success){
                commit('pinRegistrationStatus',res.data.pin_registration_status)
            }else{
                toastr.error('Unable to fetch pin registration status')
            }
            commit('loaded',null,{root:true})
        }).catch(()=>{
            toastr.error('An internal error occured')
        })
    },

    getEntryPaymentStatus({commit}){
        commit('loading',null,{root:true})
        api.entryPaymentStatus().then(res=>{ 
            if(res.data.success){
                commit('entryPaymentStatus',res.data.entry_payment_status)
            }else{
                toastr.error('Unable to fetch pin registration status')
            }
        }).catch(()=>{
            toastr.error('An internal error occured')
        })
    },

    approvePinRegistration({commit},{id,cb}){
        commit('processing',null,{root:true})
        api.approvePinRegistration(id).then(res=>{
            if(res.data.success){
                toastr.success('Pin registration approved successfully')
                commit('filterPendingPinRegApprovals',id)
                cb()
            }else{
                toastr.error('Unable to approve pin  registration')
            }
            commit('processing',null,{root:true})
        }).catch(()=>{
            toastr.error('An internal error occured')
            commit('processed',null,{root:true})
        })
    },

    disapprovePinRegistration({commit},{id,cb}){
        commit('processing',null,{root:true})
        api.disapprovePinRegistration(id).then(res=>{
            if(res.data.success){
                toastr.success('Pin registration disapproved successfully')
                commit('filterPendingPinRegApprovals',id)
                cb()
            }else{
                toastr.error('Unable to disapprove pin  registration')
            }
            commit('processed',null,{root:true})
        }).catch(()=>{
            toastr.error('An internal error occured')
            commit('processed',null,{root:true})
        })
    },

    getPinRegistrationHistory({commit}){
        commit('loading',null,{root:true})
        api.pinRegistrationHistory().then(res=>{
            if(res.data.success){
                commit('pinRegistrationHistory',res.data.pin_registration_history)
            }else{
                toastr.error('Unable to load pin registration history')
            }
            commit('loaded',null,{root:true})
        }).catch(()=>{
            toastr.error('An internal error occured')
            commit('loaded',null,{root:true})
        })
    },

    getGrantClaimStatuses({commit}){
        commit('loading',null,{root:true})
        api.grantClaimStatuses().then(res=>{
            if(res.data.success){
                commit('grantClaimStatuses',res.data.grant_claim_statuses)
            }else{
                toastr.error("Unable to load grant claim ststuses")
            }
            commit('loaded',null,{root:true})
        }).catch(err=>{
            toastr.error("An internal error occured")
            console.log(err)
            commit('loaded',null,{root:true})
        })
    },

    claimGrant({commit},data){
        commit('processing',null,{root:true})
        api.claimGrant(data).then(res=>{
            if(res.data.success){
                toastr.success("Grant claim submitted successfully")
            }else{
                toastr.error("An error occured")
            }
            commit('processed',null,{root:true})
        }).catch(err=>{
            toastr.error("An internal error occured")
            console.log(err)
            commit('processed',null,{root:true})
        })
    },

    generateQrCode({commit}){
        commit('processing',null,{root:true})
        return api.generate2fa().then(res=>{
            if(res.data.success){
                commit('qrCode',res.data.qr_code)
            }else{
                toastr.error("Unable to generate qr code")
            }
            commit('processed',null,{root:true})
        })
    },

    verifyQrCode({commit,dispatch},data){
        commit('processing',null,{root:true})
        return api.verify2fa(data).then(res=>{
            if(res.data.success){
                //res.data.data
                dispatch('get2FactorStatus')
            }else{
                toastr.error(res.data.message)
            }
            commit('processed',null,{root:true})
        })
    },

    get2FactorStatus({commit}){
        commit('processing',null,{root:true})
        api.twoFactorStatus().then(res=>{
            (res.data.success) ? commit('twoFactorAuthEnabled',res.data.auth_status) 
                                : toastr.error("Unable to fetch 2fa status")
            commit('processed',null,{root:true})  
        }).catch (error=>{
            toastr.error("An internal erro occured")
            commit('processed',null,{root:true})
            console.log(error)
        })
    },

    disable2fa({commit},data){
        commit('processing',null,{root:true})
        api.disable2fa(data).then(res=>{
            (res.data.success) ? commit('twoFactorAuthEnabled',false) 
                                : toastr.error("Unable to disable 2fa")
            commit('processed',null,{root:true})  
        }).catch (error=>{
            toastr.error("An internal erro occured")
            commit('processed',null,{root:true})
            console.log(error)
        })
    },

    getGenealogy({commit}){
        commit('loading',null,{root:true})
        api.genealogy().then(res=>{
            if(res.data.success){
                commit('genealogy',res.data.data)
            }else{
                toastr.error("An error occured")
            }
            commit('loaded',null,{root:true})
        }).catch(err=>{
            commit('loaded',null,{root:true})
            toastr.error("An internal error occured")
            console.log(err)
        })
    },

    getWalletBalance({commit}){
        commit('loading',null,{root:true})
        api.walletBalance().then(res=>{
            if(res.data.success){
                commit('walletBalance',res.data.data)
            }else{
                toastr.error('An error occured')
            }
            commit('loaded',null,{root:true})
        }).catch(err=>{
            toastr.error("An internal error occured")
            commit('loaded',null,{root:true})
            console.log(err)
        })
    },

    getLoanBalance({commit},id){
        commit('loading',null,{root:true})
        api.loanBalance(id).then(res=>{
            if(res.data.success){
                commit('loanBalance',res.data.data)
            }else{
                toastr.error("An error occured")
            }
            commit('loaded',null,{root:true})
        }).catch(err=>{
            console.log(err)
            toastr.error("An internal error occured")
            commit('loaded',null,{root:true})
        })
    },

    loanPayment({commit},payload){
        commit('processing',null,{root:true})
        api.loanPayment(payload.loanId,payload.data).then(res=>{
            if(res.data.success){
                toastr.success("Loan payment submitted successfully")
            }else{
                toastr.error("An error occured, please try again")
            }
            commit('processed',null,{root:true})
        }).catch(err=>{
            console.log(err)
            toastr.error("An intrnal error occured")
            commit('processed',null,{root:true})
        })
    },

    async getLoanPaymentHistory({commit},loanId){
        try {
            commit('loading',null,{root:true})
            let res = await api.loanPaymentHistory(loanId)
            if(res.data.success){
                commit('loanPaymentHistory',res.data.data)
            }else{
                toastr.error("An error occured")
            }
            commit('loaded',null,{root:true})
        } catch (error) {
            commit('loaded',null,{root:true})
            toastr.error("An internal error occured")
            console.log(new Error(error))
        }
    },


};