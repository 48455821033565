import actions from "./actions/user-actions";
import mutations from "./mutations/user-mutations";
import getters from "./getters/user-getters";

export default {
    namespaced: true,
    state() {
        return {
            loading: false,
            users: [],
            data: {
                dashboard: { eligibilityStatus: {} },
                profile: {
                    upline: {}
                },
                downlines: [],
                levels: [],
                genealogy: {},
                //claim_claim_statuses: [],
                incentive_claim_statuses: [],
                wallet: {
                    summary: {},
                    analysis: []
                },
                withdrawal_history: {
                    all: [],
                    total: 0
                },
                withdrawal_status: null,
                pending_pin_reg_approvals: [],
                pin_purchase_history: []
            },
            loanRequestHistory:[],
            loanEligibility:null,
            recentLoan:{},
            // pinPurchaseHistory: [],
            pinMerchants:[],
            pinRegistrationStatus:{},
            entryPaymentStatus:{},
            pinRegistrationHistory:[],
            grantClaimStatuses:[],
            incentives:[],
            qrCode:null,
            twoFactorAuthEnabled:null,
            genealogy:null,
            walletBalance:null,
            loanBalance:null,
            loanPaymentHistory:[]
        };
    },
    actions: {
        ...actions
    },
    mutations: {
        ...mutations
    },
    getters: {
        ...getters
    }
};