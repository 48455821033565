<template>
    <div>
        <tab-header/>
        <div class="container-fluid animatedParent animateOnce my-3">
            <div class="">
                <div class="tab-content" id="v-pills-tabContent">

                    <div class="tab-pane fade show active" id="v-pills-wallet-summary" role="tabpanel" aria-labelledby="v-pills-wallet-summary-tab">
                        <div class="row">
                            <div class="col-md-12">
                                <template v-if="walletSummaryLoading">
                                    <b-skeleton-table
                                        :rows="5"
                                        :columns="4"
                                        :table-props="{ bordered: true, striped: true }"
                                    ></b-skeleton-table>
                                </template>
                                <wallet-summary-tab v-else :summary="walletSummary"/>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="v-pills-Wallet-Detailed-Calculation" role="tabpanel" aria-labelledby="v-pills-Wallet-Detailed-Calculation-tab">
                        <div class="row">
                            <div class="col-md-12">
                                <template v-if="walletAnalysisLoading">
                                    <b-skeleton-table
                                        :rows="5"
                                        :columns="4"
                                        :table-props="{ bordered: true, striped: true }"
                                    ></b-skeleton-table>
                                </template>
                                <wallet-detailed-calculation-tab v-else :analysis="walletAnalysis"/>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="v-pills-Withdrawal-History" role="tabpanel" aria-labelledby="v-pills-Withdrawal-History-tab">
                        <div class="row">
                            <div class="col-md-12">
                                <template v-if="withdrawalHistoryLoading">
                                    <b-skeleton-table
                                        :rows="5"
                                        :columns="4"
                                        :table-props="{ bordered: true, striped: true }"
                                    ></b-skeleton-table>
                                </template>
                                <withdrawal-history-tab v-else :withdrawals="withdrawalHistory"/>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-Request-for-cash" role="tabpanel" aria-labelledby="v-pills-Request-for-cash-tab">
                        <b-card v-if="userFetching">
                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                            <b-skeleton animation="throb" width="55%"></b-skeleton>
                            <b-skeleton animation="throb" width="70%"></b-skeleton>
                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                            <b-skeleton animation="throb" width="55%"></b-skeleton>
                            <b-skeleton animation="throb" width="70%"></b-skeleton>
                        </b-card>
                        <cash-request-tab v-else @cash-request="submit" :inProcess="processing" :user="user">
                            <span class="text-danger text-sm">{{errors.amount}}</span>
                        </cash-request-tab>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TabHeader from '@/components/user/wallet/TabHeader'
import WalletDetailedCalculationTab from '@/components/user/wallet/WalletDetailedCalculationTab'
import WalletSummaryTab from '@/components/user/wallet/WalletSummaryTab'
import WithdrawalHistoryTab from '@/components/user/wallet/WithdrawalHistoryTab'
import CashRequestTab from '@/components/user/wallet/CashRequestTab'
import {mapActions,mapState,mapGetters} from 'vuex'
import toastr from 'toastr'
export default {
    components:{
        TabHeader,
        WalletDetailedCalculationTab,
        WalletSummaryTab,
        WithdrawalHistoryTab,
        CashRequestTab
    },
    data(){
        return{
            errors:{
                amount:''
            }
        }
    },
    computed:{
        ...mapState({
            loading:state=>state.loading,
            processing:state=>state.processing
        }),
        ...mapGetters(['user']),
        ...mapGetters('userStore',['walletSummary','walletAnalysis','withdrawalHistory']),
        walletSummaryLoading: function(){return Object.entries(this.walletSummary).length==0 ? true:false},
        walletAnalysisLoading: function(){return Object.entries(this.walletAnalysis).length==0 ? true:false},
        withdrawalHistoryLoading: function(){return Object.entries(this.withdrawalHistory).length==0 ? true:false},
        userFetching: function(){return Object.entries(this.user).length==0 ? true:false},
        //formartedError: function(){return String(this.errors.amount).replace('/["]','')} 
    },
    created(){
        if(Object.entries(this.user).length==0){
            this.getUser()
        }

        if(Object.entries(this.walletSummary).length==0){
            this.getWalletSummary().catch(err=>{
                console.log(err);
                toastr.error("An error occured while retrieveing wallet summary")
            })
        }

        if(Object.entries(this.walletAnalysis).length==0){
            this.getWalletAnalysis().catch(err=>{
                console.log(err);
                toastr.error("An error occured while retrieveing wallet analysis")
            })
        }
        if(Object.entries(this.withdrawalHistory.all).length==0){
            this.getWithdrawalHistory().catch(err=>{
                console.log(err);
                toastr.error("An error occured while retrieving withdrawal history")
            })
        }
    },
    methods: {
        ...mapActions('userStore',[
            'getWalletSummary',
            'getWalletAnalysis',
            'getWithdrawalHistory',
            'withdrawalRequest',
            'getUser'
        ]),
        submit(e){
            this.withdrawalRequest(e).catch(err=>{
                console.log(err.response);
                 toastr.error('An error occured while requestion gor cash')
                if (err.response && err.response.status === 422) {
                    let errors = err.response.data.errors;
                    this.errors.amount = String(errors.amount).replace('/["]/g','')
                }else{
                    this.errors.amount = err.response.data.message
                }
                this.$store.commit('processed',null,{root:true})
            })
        }
    },
}
</script>