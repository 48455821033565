<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card my-3">
                <div class="card-body">
                    <div class="card-header white text-green">Pending Loan Payment approvals</div><br>
                        <div class="form-row" style="overflow-x:auto;">
                            <table id="example2" class="table table-bordered table-hover data-tables"
                                    data-options='{ "paging": false; "searching":false}'>
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Username</th>
                                        <th>($)Loan</th>
                                        <th>($)Amount</th>
                                        <th>Level</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr> 
                                </thead>
                                <tbody>
                                    <tr v-if="loading">
                                        <td colspan="11">
                                            <b-card>
                                                <b-skeleton animation="throb" width="85%"></b-skeleton>
                                                <b-skeleton animation="throb" width="55%"></b-skeleton>
                                                <b-skeleton animation="throb" width="70%"></b-skeleton>
                                            </b-card>
                                        </td>
                                    </tr>
                                    <template v-else>
                                        <tr v-if="pendingLoanPaymentApprovals.length < 1">
                                            <td colspan="7">
                                                <p class="alert alert-info text-center">
                                                    There are no pending loan payment approvals
                                                </p>
                                            </td>
                                        </tr>
                                        <tr v-else v-for="reg,i in pendingLoanPaymentApprovals" :key="i">
                                            <th scope="row">{{++i}}</th>
                                            <td>{{reg.user.username}}</td>
                                            <td>{{reg.loan.loan_amount}}</td>
                                            <td>{{reg.amount}}</td>
                                            <td>{{reg.level_id}}</td>
                                            <td>{{reg.created_at}}</td>
                                            <td>
                                                <div class="dropdown">
                                                    <button class="btn btn-success  dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="caret"></i>
                                                    </button>
                                                    <div class="dropdown-menu " aria-labelledby="dropdownMenuButton" style="position:relative">
                                                        <b-button class="dropdown-item text-green" @click="setId(reg)" v-b-modal.approve>Approve/Disapprove</b-button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>	
                    </div>
                </div>                        
        </div>

        <confirm v-if="payment" :modalId="'approve'" :modalTitle="'Approve Loan payment'" modalSize="lg">
            <div class="row mb-3">
                <b-card v-if="processing" class="col-md-6 offset-md-3">
                    <b-skeleton animation="throb" width="85%"></b-skeleton>
                </b-card>
                <template v-else>
                    <div class="col-md-4 offset-md-4">
                        <button @click="approve()" class="btn btn-success">Approve</button>
                    </div>
                    <div class="col-md-4 ">
                        <button @click="disapprove()" class="btn btn-danger">Dispprove</button>
                    </div>
                </template>
            </div>
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <div class="card mb-3">
                        <div class="card-header white text-green"><strong>{{payment.user.username}}'s</strong> Proof of payment</div>
                        <div class="card-body ">
                            <img :src="`http://localhost:80/storage/${payment.pop_path}`" width="300" height="300">
                        </div>
                    </div>
                </div>
            </div>
        </confirm>
    </div>
</template>
<script>
import {mapActions,mapState,mapGetters} from 'vuex'
import Confirm from '@/components/Confirm'
export default {
    components:{
        Confirm
    },

    data(){
        return{
            payment:null,
            paymentId:null,
            popPath:null,
        }
    },
    
    computed:{
        ...mapState({
            loading:state=>state.loading,
            processing:state=>state.processing
        }),

        ...mapGetters('adminStore',['pendingLoanPaymentApprovals'])
    },

    created(){
        if(this.pendingLoanPaymentApprovals.length == 0){
            this.getPendingLoanPaymentApprovals()
        }
    },

    methods:{
        ...mapActions('adminStore',['getPendingLoanPaymentApprovals','approveLoanPayment','disapproveLoanPayment']),

        setId(payment){
            this.payment = payment
            this.paymentId = payment.id
            this.popPath = payment.pop_path
        },

        approve(){
            this.approveLoanPayment({id:this.paymentId,cb:()=>{
                this.$bvModal.hide('approve')
            }})
        },

        disapprove(){
            this.disapproveLoanPayment({id:this.paymentId,cb:()=>{
                this.$bvModal.hide('approve')
            }})
        }
    }
}
</script>