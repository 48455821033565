<template>
    <div class="d-flex row row-eq-height my-3">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header white">
                    <div class="row justify-content-end">
                        <div class="col">
                            <ul class="nav nav-tabs card-header-tabs nav-material">
                                <li class="nav-item">
                                    <a class="nav-link text-green" id="w1-tab1" data-toggle="tab" >Refer a Friend</a>
                                </li>	
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card-body no-p">
                    <div class="tab-content">
                        <div class="tab-pane animated fadeInRightShort show active" id="v-pills-w1-tab1" role="tabpanel" aria-labelledby="v-pills-w1-tab1">
                            <div class="row p-3">
                            
                                <div class="col-md-12">
                                    <form method="post" @submit.prevent>
                                        <div class="form-row">
                                            <div class="col-md-12 mb-3">
                                                <input type="email" v-model="form.email" class="form-control" id="validationDefault03" placeholder="Recipients Email Address" required>
                                            </div>
                                        </div>
                                        <b-button v-if="processing" variant="primary" class="btn btn-success" disabled>
                                            <b-spinner small type="grow"></b-spinner>
                                            Processing...
                                        </b-button>
                                        <button v-else @click="submit()" class="btn btn-success" type="submit">Invite a Friend</button>
                                    </form>

                                    <div class="form-row text-center">
                                        <div class="col-md-12 mb-3">
                                            <h3 class="font-weight-bold s-14 text-center">Click to copy your referral link</h3>
                                        </div>	
                                        <div class="col-md-8 mb-3" >
                                            <input type="text" class="form-control" value="https://capitalmakingclub.com/goke154" id="referrallink" readonly >
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <input type="button" class="btn btn-success" onclick="myFunction()" value="Copy Referral Link" id="button">
                                        </div>	
                                    </div>	
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-md-6">
            <div class="card">
                <div class="card-header white">
                    <div class="row justify-content-end">
                        <div class="col">
                            <ul class="nav nav-tabs card-header-tabs nav-material">
                                <li class="nav-item">
                                    <a class="nav-link text-green" id="w1-tab1" data-toggle="tab" >General Message</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card-body no-p">
                    <div class="tab-content">
                        <div class="tab-pane animated fadeInRightShort show active" id="v-pills-w1-tab1" role="tabpanel" aria-labelledby="v-pills-w1-tab1">
                            <div class="row p-3">
                                <div class="col-md-12 text-center">
                                    <h3 class="font-weight-bold s-14 text-center">Welcome to Capital Making Club</h3>
                                    <span class="font-weight-lighter ">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis malesuada nisl magna, eu finibus orci scelerisque placerat. Quisque eu posuere mi. Nulla finibus tortor odio, nec accumsan neque vehicula ac. Aenean eget purus a neque volutpat euismod sed in nunc. Donec in ligula sed ante gravida eleifend. Etiam in tortor semper, blandit ante ac, finibus urna. Nunc eu orci fringilla enim aliquam euismod commodo at sem. Aliquam ultricies eros vitae eros viverra, id commodo nibh sodales. Cras vel mi in odio porttitor pharetra. Praesent vel odio lacus. Cras suscipit, sapien ut imperdiet ornare, libero elit dictum leo, ac vehicula dui turpis at elit. Cras blandit diam ac lacus gravida, id ultricies ante tristique. Integer facilisis diam vitae metus scelerisque, a luctus ante interdum.</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions,mapState} from 'vuex'
import toastr from 'toastr'
export default {
    data(){
        return{
            form:{
                email:''
            }
        }
    },
    computed:{
        ...mapState({
            processing: state => state.processing
        })
    },

    methods:{
        ...mapActions('userStore',['sendInvitation']),

        submit(){
            this.sendInvitation(this.form).catch( (err) => {
               if (err.response && err.response.status === 422) {
                    this.errors = err.response.data.errors;
                    toastr.warning("Ivalid input")
                    console.log("error-" + JSON.stringify(err.response.data));
                } else {
                    console.log('')
                    toastr.error("An erorr occured, please ty again")
                }
                this.$store.commit("processed", null, { root: true });
            })
        }
    }
}
</script>