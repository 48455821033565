export default{
    usersCount(state){
        return state.usersCount
    },

    paidUsersCount(state){
        return state.paidUsersCount
    },

    unpaidUsersCount(state){
        return state.unpaidUsersCount
    },

    pinPurchaseSum(state){
        return state.pinPurchaseSum
    },

    withdrawalRequestCount(state){
        return state.withdrawalRequestCount
    },

    users(state){
        return state.users
    },

    showUser(state){
        return state.showUser
    },

    pendingEntryPayments(state){
        return state.pendingEntryPayments
    },

    proofOfPayment(state){
        return state.proofOfPayment
    },

    entryPaymentHistory(state){
        return state.entryPaymentHistory
    },

    pinPurchaseHistory(state){
        return state.pinPurchaseHistory
    },

    pinRegistrationHistory(state){
        return state.pinRegistrationHistory
    },

    pendingPinPurchases(state){
        return state.pendingPinPurchases
    },

    systemSettings(state){
        return state.systemSettings
    },

    bankDetails(state){
        return state.bankDetails
    },

    pendingLoanRequestApprovals(state){
        return state.pendingLoanRequestApprovals
    },
    loanRequestHistory(state){
        return state.loanRequestHistory
    },

    loanPaymentHistory(state){
        return state.loanPaymentHistory
    },
    pendingLoanPaymentApprovals(state){
        return state.pendingLoanPaymentApprovals
    }
}