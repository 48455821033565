export default {
    /**
     *
     * @param {*} state
     */
    all(state, users) {
        state.users = users;
    },

    dashboardData(state, data) {
        state.data.dashboard = data;
    },

    upline(state, data) {
        state.data.profile.upline = data
    },

    profile(state, data) {
        state.data.profile = data;
    },

    downlines(state, {data}) {
        state.data.downlines.push(...data);
    },

    eligibilityStatus(state, data) {
        state.data.dashboard.eligibilityStatus = data
    },

    walletSummary(state, data) {
        state.data.wallet.summary = data;
    },

    walletAnalysis(state, data) {
        state.data.wallet.analysis = data;
    },

    withdrawalHistory(state, data) {
        state.data.withdrawal_history.all.length > 0 ?
            state.data.withdrawal_history.all.push(data.withdrawals) :
            state.data.withdrawal_history.all = data.withdrawals;

        state.data.withdrawal_history.total = data.total_withdrawals;
    },


    levels(state, data) {
        state.data.levels = data;
    },

    voucherClaimStatus(state, data) {
        state.data.voucher_claim_statuses.push(data);
    },

    voucherClaimStatuses(state, data) {
        state.data.voucher_claim_statuses = data;
    },

    incentiveClaimStatuses(state, data) {
        state.data.incentive_claim_statuses = data;
    },

    totalWithrawals(state, data) {
        state.data.withdrawal_history.total = data.total_withdrawals;
    },

    withdrawalStatus(state, data) {
        state.data.withdrawal_status = data;
    },

    pendingPinRegApprovals(state, {data}) {
        state.data.pending_pin_reg_approvals.push(...data);
    },

    filterPendingPinRegApprovals(state, id) { 
       let index = state.data.pending_pin_reg_approvals.findIndex(ele=>ele.id === id);
       if(index != -1){
            state.data.pending_pin_reg_approvals.splice(index,1)
       }
    },

    pinPurchaseHistory(state, data) {
        state.data.pin_purchase_history.push(...data);
    },

    loanRequestHistory(state,{data}){
        state.loanRequestHistory.push(...data)
    },
    loanEligibility(state,data){
        state.loanEligibility = data
    },
    recentLoan(state,data){
        state.recentLoan = data
    },

    pinMerchants(state,data){
        state.pinMerchants = data
    },
    pinRegistrationStatus(state,data){
        state.pinRegistrationStatus = data
    },
    pinRegistrationHistory(state,{data}){
        state.pinRegistrationHistory.push(...data)
    },

    entryPaymentStatus(state,data){
        state.entryPaymentStatus = data
    },

    grantClaimStatuses(state,data){
        state.grantClaimStatuses = data
    },

    incentives(state,data){
        state.incentives = data
    },

    qrCode(state,data){
        state.qrCode = data
    },
    twoFactorAuthEnabled(state,data){
        state.twoFactorAuthEnabled = data
    },

    genealogy(state,data){
        state.genealogy = data
    },

    walletBalance(state,data){
        state.walletBalance = data
    },

    loanBalance(state,data){
        state.loanBalance = data
    },

    loanPaymentHistory(state,{data}){
        state.loanPaymentHistory.push(...data)
    }

};