import { http, apiVersion } from "./api-config";

const prefix = {
    user: apiVersion + "/users",
    //admin: apiVersion +"/admin",
    withdrawals: apiVersion + "/withdrawals",
    pinRegistrations: apiVersion + "/pin-registrations",
    pins: apiVersion + "/pins",
    //unapproved: apiVersion + "/unapproved" //unpaid users endpoint
    entryPayments: apiVersion + "/entry-payments",
    entryPaymentHistory: apiVersion + "/entry-payment-history",
    loans: apiVersion + "/loans",
    systemSettings: apiVersion + "/settings",
    loanPayments: apiVersion + "/loan-payments"
};

const endPoints = {
    users: `${prefix.user}/`,

    user: function(id) {
        if (id) {
            return `${prefix.user}/${id}/show`;
        }
        return `${prefix.user}/user`;
    },

    sendEmail:function(id) {
        if (id) {
            return `${prefix.user}/${id}/send-email`;
        }
        return `${prefix.user}/send-email`;
    },

    registrationsCount: `${prefix.user}/users-count`,
    paidMembersCount: `${prefix.user}/paid-users-count`,
    unpaidMembersCount: `${prefix.user}/unpaid-users-count`,
    loanClaims: `${prefix.loans}/claims`,
    withdrawalRequestCount: `${prefix.withdrawals}/request-count`,

    pinPurchaseSum: `${prefix.pins}/purchaseSum`,
    pinPurchaseHistory: `${prefix.pins}/purchase-history`,
    pendingPinPurchases: `${prefix.pins}/pending-purchases`,
    approvePinPurchase: id=>{return `${prefix.pins}/${id}/approve`},
    disapprovePinPurchase: id=>{return `${prefix.pins}/${id}/disapprove`},

    pinRegistrationHistory: `${prefix.pinRegistrations}/history`,

    pendingEntryPayments: `${prefix.entryPayments}/pending`,
    approveEntryPayment:id=>{return `${prefix.entryPayments}/${id}/approve`}, 
    disaproveEntryPayment:id=>{return `${prefix.entryPayments}/${id}/disapprove`},
    proofOfPayment:id=>{ return `${prefix.entryPaymentHistory}/${id}/proof-of-payment`},
    entryPaymentHistory:`${prefix.entryPaymentHistory}`,

    bankDetails: `${prefix.systemSettings}/bank-details`,
    addBankDetails: `${prefix.systemSettings}/add-bank-details`,
    updateBankDetails: id=> {return `${prefix.systemSettings}/${id}/update-bank-details`} ,
    deleteBankDetails: id=> {return `${prefix.systemSettings}/${id}/delete-bank-details`} ,

    updateSettings: `${prefix.systemSettings}/update`,
    systemSettings: `${prefix.systemSettings}/show`,

    pendingLoanRequestApprovals: `${prefix.loans}/pending-approvals`,
    approveLoanRequest:id=>{return `${prefix.loans}/${id}/approve-request`},
    disapproveLoanRequest:id=>{return `${prefix.loans}/${id}/disapprove-request`},
    loanRequestHistory: `${prefix.loans}/history`,

    pendingLoanPaymentApprovals: `${prefix.loanPayments}/pending-approvals`,
    approveLoanPayment:id=>{return `${prefix.loanPayments}/${id}/approve`},
    disapproveLoanPayment:id=>{return `${prefix.loanPayments}/${id}/disapprove`},
    loanPaymentHistory: `${prefix.loanPayments}/history`

};

export default {
    registrationsCount(){
       return http().get(endPoints.registrationsCount)
    },
    unpaidMembersCount(){
       return http().get(endPoints.unpaidMembersCount)
    },
    paidMembersCount(){
       return http().get(endPoints.paidMembersCount)
    },

    loanClaims(){
        return http().get(endPoints.loanClaims)
    },
    withdrawalRequestCount(){
        return http().get(endPoints.withdrawalRequestCount)
    },
    pinPurchaseSum(){
        return http().get(endPoints.pinPurchaseSum)
    },
    users(){
        return http().get(endPoints.users)
    },
    user(id){
        return http().get(endPoints.user(id))
    },
    pendingEntryPayments(){
        return http().get(endPoints.pendingEntryPayments)
    },
    proofOfPayment(id){
        return http().get(endPoints.proofOfPayment(id))
    },
    approveEntryPayment(id){
        return http().patch(endPoints.approveEntryPayment(id))
    },
    disapproveEntryPayment(id){
        return http().patch(endPoints.disaproveEntryPayment(id))
    },
    entryPaymentHistory(){
        return http().get(endPoints.entryPaymentHistory)
    },
    pinPurchaseHistory(){
        return http().get(endPoints.pinPurchaseHistory)
    },
    pinRegistrationHistory(){
        return http().get(endPoints.pinRegistrationHistory)
    },
    pendingPinPurchases(){
        return http().get(endPoints.pendingPinPurchases)
    },
    approvePinPurchase(id){
        return http().patch(endPoints.approvePinPurchase(id))
    },
    disapprovePinPurchase(id){
        return http().patch(endPoints.disapprovePinPurchase(id))
    },
    sendEmail(id=null){
        return http().post(endPoints.sendEmail(id))
    },
    
    bankDetails(){
        return http().post(endPoints.bankDetails)
    },
    addBankDetails(data){
        return http().post(endPoints.addBankDetails,data)
    },
    updateBankDetails(id,data){
        return http().put(endPoints.updateBankDetails(id),data)
    },
    deleteBankDetails(id){
        return http().delete(endPoints.deleteBankDetails(id))
    },

    updateSettings(data){
        return http().patch(endPoints.updateSettings,data)
    },
    systemSettings(){
        return http().get(endPoints.systemSettings)
    },

    approveLoanRequest(id){
        return http().patch(endPoints.approveLoanRequest(id))
    },
    disapproveLoanRequest(id){
        return http().patch(endPoints.disapproveLoanRequest(id))
    },
    pendingLoanRequestApprovals(){
        return http().get(endPoints.pendingLoanRequestApprovals)
    },
    loanRequestHistory(){
        return http().get(endPoints.loanRequestHistory)
    },

    approveLoanPayment(id){
        return http().patch(endPoints.approveLoanPayment(id))
    },
    disapproveLoanPayment(id){
        return http().patch(endPoints.disapproveLoanPayment(id))
    },
    pendingLoanPaymentApprovals(){
        return http().get(endPoints.pendingLoanPaymentApprovals)
    },
    loanPaymentHistory(){
        return http().get(endPoints.loanPaymentHistory)
    },
}