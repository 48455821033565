<template>
    <div>
        <header class="blue accent-3 relative">
            <div class="container-fluid text-blue">
                <div class="row justify-content-between">
                    <ul class="nav nav-material nav-material-white responsive-tab" id="v-pills-tab" role="tablist">
                        <li>
                            <a class="nav-link active" id="v-pills-wallet-summary-tab" data-toggle="pill"  href="#v-pills-wallet-summary" role="tab" aria-controls="v-pills-wallet-sumary"><i class="icon icon-lock4" ></i>Manage Google 2Factor Authenticator</a>
                        </li>
                        <li>
                            <a class="nav-link" id="v-pills-Wallet-Detailed-Calculation-tab" data-toggle="pill" href="#v-pills-Wallet-Detailed-Calculation" role="tab" aria-controls="v-pills-Wallet-Detailed-Calculation" aria-selected="false"><i class="icon  icon-key6 "></i>Change Password</a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>

        <div class="">
            <div class="tab-content" id="v-pills-tabContent">

                <two-factor-auth-tab/>

                <change-password-tab/>
            </div>
        </div>
    </div>
</template>
<script>
import TwoFactorAuthTab from '@/components/user/security/TwoFactorAuthTab'
import ChangePasswordTab from '@/components/user/security/ChangePasswordTab'
export default {
    components:{
        TwoFactorAuthTab,
        ChangePasswordTab
    }
}
</script>