<template>
    <div class="container-fluid animatedParent animateOnce my-3">
        <div class="animated ">
			<!--Style Start 3-->
            <div v-if="userLoading">
                <b-card>
                    <b-skeleton animation="throb" width="85%"></b-skeleton>
                    <b-skeleton animation="throb" width="55%"></b-skeleton>
                    <b-skeleton animation="throb" width="70%"></b-skeleton>
                </b-card>
            </div>
			<status-panel v-else :user="user" :walletBalance="walletBalance"/>
			<!--Style End 3-->
			
			<!--eligibity table begin-->
			<eligibility-status 
                :eligStatusLoading="eligStatusLoading" 
                :eligStatus="dashboardData.eligibilityStatus"
            />
			<!--epoint value end-->

            <incentive-status/>
					
			<referral-panel/>
			
            <incentive-claim/>

        </div>
    </div>
</template>

<script>
import StatusPanel from '@/components/user/StatusPanel'
import EligibilityStatus from '@/components/user/EligibilityStatus'
import IncentiveStatus from '@/components/user/IncentiveStatus'
import ReferralPanel from '@/components/user/ReferralPanel'
import IncentiveClaim from '@/components/user/IncentiveClaim'
import {mapState,mapActions,mapGetters} from 'vuex'
export default {
    components:{
        StatusPanel,
        EligibilityStatus,
        IncentiveStatus,
        ReferralPanel,
        IncentiveClaim
    },
    
    computed:{
        ...mapState({
            loading: state => state.loading
        }),
        ...mapGetters(["user"]),
        ...mapGetters('userStore',["dashboardData","walletBalance"]),
        eligStatusLoading: function() {return Object.entries(this.dashboardData.eligibilityStatus).length==0 ? true:false},
        userLoading: function(){return Object.entries(this.user).length==0 || this.walletBalance==null  ? true:false}
    },

    mounted(){
        if(Object.entries(this.user).length==0){
            this.getUser()
        }
        //console.log(this.dashboardData);
        if( Object.entries(this.dashboardData.eligibilityStatus).length==0){
            this.getEligibilityStatus()
        }

        if(this.walletBalance==null){
            this.getWalletBalance()
        }
    },
    methods: {
        ...mapActions(['getUser']),
        ...mapActions('userStore',['getEligibilityStatus','getWalletBalance']),
         
    },
}
</script>