<template>
    <div class="tab-pane fade" id="v-pills-payments" role="tabpanel" aria-labelledby="v-pills-payments-tab">
        <div class="row">
            <div class="col-md-12">
                <div class="card no-b">
                    <div class="card-header bg-white text-green">
                        <h4 class="card-title green-text"><strong class="font-weight-bold">Bank Account Details</strong></h4>
                    </div>
                    <div class="collapse show" id="invoiceCard">
                        <div class="card-body p-0">
                            
                                <div class="card no-b  no-r">
                                    <div class="card-body">
                                        <div class="form-row">
                                            <div class="col-md-8">
                                                <div class="form-group m-0">
                                                    <label for="name" class="col-form-label s-12">Bank Name</label>
                                                    <input disabled value="Guaranty Trust Bank" placeholder="Enter full Name" class="form-control r-0 light s-12 " type="text">
                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col-6 m-0">
                                                        <label for="cnic" class="col-form-label s-12"><i class=""></i>Bank Account Name</label>
                                                        <input disabled value="Bawa Ali Raba" placeholder="Enter Form B or CNIC Number" class="form-control r-0 light s-12 date-picker" type="text">
                                                    </div>
                                                    <div class="form-group col-6 m-0">
                                                        <label for="dob" class="col-form-label s-12"><i class=" mr-2"></i>Bank Account Number</label>
                                                        <input disabled value="0027521969" placeholder="email address" class="form-control r-0 light s-12 datePicker"  type="">
                                                    </div>
                                                </div>
                                                    <div class="card-body float-right">
                                                            <input type="hidden" name="id" value="960">
                                                        <button type="submit" class="btn btn-success btn-lg"><i class="icon-save mr-2"></i>Update Data</button>
                                                    </div>
                                            </div>
                                            <br>
                                            <div class="col-md-4">
                                                <div class="float-center">
                                                    <img class="img-responsive" src="/assets/img/online-banking.png"> 
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <br>
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>