<template>
    <div class="tab-pane active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <div class="row">
            <div class="col-md-3">
        
                <div class="card-header bg-success">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item"><i class="icon icon-person text-success"></i><strong class="s-12 green-text">Full name</strong> <span class="float-right s-12">{{user.name}}</span></li>
                        <li class="list-group-item"><i class="icon icon-phone2 text-success"></i><strong class="s-12 green-text">Phone</strong> <span class="float-right s-12">{{user.phone}}</span></li>
                        <li class="list-group-item"><i class="icon icon-mail-envelope-open6 text-success"></i><strong class="s-12 green-text">Email</strong> <span class="float-right s-12">{{user.email}}</span></li>
                        <li class="list-group-item"><i class="icon icon-user-circle-o text-success"></i> <strong class="s-12 green-text">Username</strong> <span class="float-right s-12">{{user.username}}</span></li>
                        <li class="list-group-item"><i class="icon icon-level-up  text-success"></i><strong class="s-12 green-text">Level</strong> <span class="float-right s-12">{{user.level.name}}</span></li>
                    </ul>
                </div>
                <div class=" my-3" >
                    <div class="card" style="background-color:#52BE80;">
                        <div class="card-body text-center  p-5 ">
                            <div class="avatar avatar-xl mb-3 ">
                                <img class="user_avatar" src="/assets/img/dummy/u1.png" alt="User Image">
                            </div>
                            <div>
                                <h6 class="p-t-10 text-white">{{user.name}}</h6>
                                <span class="text-white">{{user.email}}</span>
                            </div>
                        </div>
                    </div>	
                </div>
                
            </div>
            <div class="col-md-9">
                <div class="row my-3">
                    <!-- bar charts group -->
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header bg-white">
                                <h6 class="green-text"><strong class="font-weight-bold">Profile Data</strong></h6>
                            </div>
                            <div class="card-body">
                                <form method="POST" @submit.prevent>
                                    <div class="card no-b  no-r">
                                        <div class="card-body">
                                            <div class="form-row">
                                                <div class="col-md-12">
                                                    <div class="form-group m-0">
                                                        <label for="name" class="col-form-label s-12 green-text">Full Name</label>
                                                        <input name="name" v-model="form.name" placeholder="Enter full Name" class="form-control r-0 light s-12 " type="text">
                                                    </div>
            
                                                    <div class="form-row">
                                                        <div class="form-group col-6 m-0">
                                                            <label for="cnic" class="col-form-label s-12 green-text"><i class="icon-phon"></i>Phone Number</label>
                                                            <input name="phone" v-model="form.phone" placeholder="Enter Form B or CNIC Number" class="form-control r-0 light s-12 date-picker" type="text">
                                                        </div>
                                                        <div class="form-group col-6 m-0">
                                                            <label for="dob" class="col-form-label s-12 green-text"><i class="icon-emai mr-2"></i>Email</label>
                                                            <input name="email" disabled :value="user.email" placeholder="email address" class="form-control r-0 light s-12 datePicker"  type="email">
                                                        </div>
                                                    </div>
            
                                                    <div class="form-group m-0">
                                                        <label for="dob" class="col-form-label s-12 green-text">Gender</label>
                                                        <br>
                                                        <div class="">
                                                            <select v-model="form.gender" class="select2 form-control" >
                                                                <template v-for="val in gender">
                                                                    <option :key="val" >{{val}}</option>
                                                                </template>
                                                            </select>   
                                                        </div>   
                                                    </div>
                                                    
                                                    <div class="form-group m-0">
                                                        <label for="name" class="col-form-label s-12 green-text">country</label>
                                                        <input v-model="form.country" placeholder="Country" class="form-control r-0 light s-12 " type="text">
                                                    </div>
                                                    
                                                    <div class="form-group m-0">
                                                        <label for="address" class="col-form-label s-12 green-text">Address</label>
                                                        <textarea name="address" v-model="form.address" rows="5" type="text" class="form-control r-0 light s-12" id="address"
                                                        placeholder="Enter Address"></textarea>
                                                    </div>	
                                                </div>

                                            </div>
                                        </div>	
                                        
                                        <div class="card-body">
                                            <button v-if="processing" class="btn btn-success btn-lg">
                                                <span>...Processing</span>
                                            </button>
                                            <button v-else @click="submit()" type="submit" class="btn btn-success btn-lg"><i class="icon-save mr-2"></i>Update Data</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- /bar charts group -->
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions,mapState} from 'vuex'
import toastr from 'toastr'
export default {
    props:{
        user:{
            type:Object,
            required:true
        }
    },
    data() {
        return {
            form:{
                name:this.user.name,
                //email:'',
                phone:this.user.phone,
                gender:this.user.gender,
                country:this.user.country,
                address:this.user.address
            },
            gender:['Select gender','male','female']
        }
    },
    computed:{
        ...mapState({
            processing: state => state.processing
        }),
    },

    created(){
        console.log(this.user)
        if(Object.entries(this.user).length == 0){
            toastr.warning("user data not loaded")
        }
    },
    methods:{
        ...mapActions('userStore',['updateProfile']),

        submit(){
            this.updateProfile(this.form).catch(err=>{
                if (err.response && err.response.status === 422) {
                    this.errors = err.response.data.errors;
                    toastr.warning("Ivalid input")
                    console.log("error-" + JSON.stringify(err.response.data));
                } else {
                    console.log(err)
                    toastr.error("An erorr occured, please ty again")
                }
                this.$store.commit("processed", null, { root: true });
            })
        }
    }
}
</script>