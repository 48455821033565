<template>
<div class="container-fluid animatedParent animateOnce my-3">
    <div class="row my-5">
        <div class="col-lg-4">
            <b-card v-if="usersCountLoading">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>

            <content-box v-else class="gradient text-white"
             :image="''"
             :title="'REGISTRATIONS'"
             :counter="usersCount"/>
        </div>

        <div class="col-lg-4">
            <b-card v-if="paidUsersCountLoading">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <content-box v-else class="gradient text-white"
             :image="''"
             :title="'PAID MEMBERS'"
             :counter="paidUsersCount"/>
        </div>

        <div class="col-lg-4">
            <b-card v-if="unpaidUsersCountLoading">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <content-box v-else class="gradient text-white"
             :image="''"
             :title="'UNPAID MEMBERS'"
             :counter="unpaidUsersCount"/>
        </div>
    </div>

    <div class="row my-5">
        
        <div class="col-lg-4">
            <b-card v-if="usersCountLoading">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>

            <content-box v-else class="white"
             :image="''"
             :title="'LOAN CLAIMED'"
             :counter="usersCount"/>
        </div>

        <div class="col-lg-4">
            <b-card v-if="paidUsersCountLoading">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <content-box v-else class="white"
             :image="''"
             :title="'WITHDRAWAL REQUESTS'"
             :counter="paidUsersCount"/>
        </div>

        <div class="col-lg-4">
            <b-card v-if="unpaidUsersCountLoading">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>
            <content-box v-else class="white"
             :image="''"
             :title="'PIN PURCHASED'"
             :counter="unpaidUsersCount"/>
        </div>
        
    </div>

    <div class="row my-5">
        <div class="col-lg-12">
            <b-card v-if="usersCountLoading">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
                <b-skeleton animation="throb" width="55%"></b-skeleton>
                <b-skeleton animation="throb" width="70%"></b-skeleton>
            </b-card>

            <content-box v-else class="gradient text-white"
             :image="''"
             :title="'COMPANY WALLET'"
             :counter="usersCount"/>
        </div>
    </div>

    <div class="d-flex row row-eq-height my-3">
        <div class="col-md-6">
            <front-page-pop-up/>
        </div>
        <div class="col-md-6">
            <general-message-form/>
        </div>
    </div>

</div>
</template>

<script>
import ContentBox from '@/components/admin/dashboard/ContentBox'
import FrontPagePopUp from '@/components/admin/dashboard/FrontPagePopUp'
import GeneralMessageForm from '@/components/admin/dashboard/GeneralMessageForm'
import {mapActions,mapGetters} from 'vuex'
export default {
    components:{
        ContentBox,
        FrontPagePopUp,
        GeneralMessageForm
    },
    data(){
        return{

        }
    },

    computed:{
        ...mapGetters('adminStore',['usersCount','paidUsersCount','unpaidUsersCount']),
        usersCountLoading: function(){return this.usersCount==null ? true : false},
        paidUsersCountLoading: function(){return this.paidUsersCount==null ? true : false},
        unpaidUsersCountLoading: function(){return this.unpaidUsersCount==null ? true : false},
    },

    created(){
        if(this.usersCount==null){
            this.countUsers()
        }
        if(this.paidUsersCount==null){
            this.countPaidUsers()
        }
        if(this.unpaidUsersCount==null){
            this.countUnpaidUsers()
        }
    },

    methods:{
        ...mapActions('adminStore',['countUsers','countPaidUsers','countUnpaidUsers'])
    }
}
</script>