import Layout from '@/components/admin/Layout'
import Dashboard from '@/views/admin/Dashboard'
import Profile from '@/views/user/Profile'
import Users from '@/views/admin/Users'
import PendingEntryPayments from '@/views/admin/PendingEntryPayments'
import PendingPinPuchases from '@/views/admin/PendingPinPurchaseApprovals'
import ViewPop from '@/views/admin/ViewPop'
import EntryPaymentHistory from '@/views/admin/EntryPaymentHistory'
import PinPurchaseHistory from '@/views/admin/PinPurchaseHistory'
import PinRegistrationHistory from '@/views/admin/PinRegistrationHistory'
import Security from '@/views/user/Security'
import SendMessage from '@/components/SendMessage'
import SystemSettings from '@/views/admin/SystemSettings'
import PendingLoanRequestApprovals from '@/views/admin/PendingLoanRequestApprovals'
import LoanRequestHistory from '@/views/admin/LoanRequestHistory'
import PendingLoanPaymentApprovals from '@/views/admin/PendingLoanPaymentApprovals'
import LoanPaymentHistory from '@/views/admin/LoanPaymentHistory'

let routes = [
    {
        path: '/admin',
        component: Layout,
        children: [
            {
                name: 'admin-dashboard',
                path: 'dashboard',
                component: Dashboard
            },
            {
                name: 'admin-profile',
                path: 'profile',
                component: Profile
            },
            {
                name: 'users',
                path: 'users',
                component: Users
            },
            {
                path: 'users/:id/profile',
                name: 'admin-user-profile',
                component: () =>
                        import ( /* webpackChunkName: "admin-user-profile" */ '../views/user/Profile.vue')
            },
            {
                path:'pending-entry-payments',
                name:'pending-entry-payments',
                component:PendingEntryPayments
            },
            {
                path:'pending-pin-purchases',
                name:'pending-pin-purchases',
                component:PendingPinPuchases
            },
            {
                path:'entry-payments/view-pop/:pop',
                name:'view-pop',
                component:ViewPop
            },
            {
                path:'entry-payment-history',
                name:'entry-payment-history',
                component:EntryPaymentHistory
            },
            {
                path:'pin-purchase-history',
                name:'admin.pin-purchase-history',
                component:PinPurchaseHistory
            },
            {
                path:'pin-registration-history',
                name:'admin.pin-registration-history',
                component:PinRegistrationHistory
                
            },
            {
                path:'security',
                name:'admin-security',
                component:Security
            },
            {
                path:'send-email',
                name:'admin.send-email',
                component:SendMessage
            },
            {
                path:'system-settings',
                name:'system-settings',
                component:SystemSettings
            },
            {
                path:'pending-loan-request-approvals',
                name:'pending-loan-request-approvals',
                component:PendingLoanRequestApprovals
            },
            {
                component:LoanRequestHistory,
                path:'loan-request-history',
                name:'loan-request-history',
            },
            {
                component:LoanPaymentHistory,
                path:'loan-payment-history',
                name:'loan-payment-history',
            },
            {
                path:'pending-loan-payment-approvals',
                name:'pending-loan-payment-approvals',
                component:PendingLoanPaymentApprovals
            },
        ]
    }
]


export default routes