<template>
    
    <div class="card no-b">
        <div class="card-header bg-white">
            <h4 class="green-text"><strong class="font-weight-bold">Withdrawal History</strong></h4>
        </div>
        <div class="collapse show" id="invoiceCard">
            <div class="card-body p-0">
                <div class="card no-b  no-r">
                    <div class="card-body">
                        <div class="form-row" style="overflow-x:auto;">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">S/N</th>
                                        <th scope="col">Level</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Withdrawal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="withdrawals.all.length>0">
                                        <tr v-for="data,index in withdrawals.all" :key="index">
                                            <td>{{++index}}</td>
                                            <td>{{data.level_id}}</td>
                                            <td>{{data.created_at}}</td>
                                            <td>{{data.amount}}</td>
                                        </tr>														
                                        <tr>
                                            <th colspan="3">Total withdrawals (TW)</th><td>${{withdrawals.total}}</td>
                                        </tr>
                                    </template>
                                    <tr v-else>
                                        <td colspan="4">
                                            <p class="alert alert-info text-center">There are no withdrawals</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
            
</template>
<script>
export default {
    props:{
        withdrawals:{
            type:Object,
            required:true
        }
    }
}
</script>