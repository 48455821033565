<template>
    <main>
        <div id="primary" class="green4 p-t-b-100 height-full ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 mx-md-auto">
                        <div class="text-center">
                            <img src="assets/img/loginimage2.png" alt="">
                            <p class="p-t-b-20 text-white">Let the Journey Begin...</p>
                        </div>
                        <form @submit.prevent="signUp()">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group has-icon"><i class="icon-user"></i>
                                        <input required v-model="form.name" type="text" class="form-control form-control-lg no-b"
                                            placeholder="Full Name">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group has-icon"><i class="icon-verified_user"></i>
                                        <input v-model="form.username" type="text" class="form-control form-control-lg no-b"
                                            placeholder="username">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group has-icon"><i class="icon-envelope-o"></i>
                                        <input v-model="form.email" type="text" class="form-control form-control-lg no-b"
                                            placeholder="Email">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group has-icon"><i class="icon-phone"></i>
                                        <input v-model="form.phone" type="text" class="form-control form-control-lg no-b"
                                            placeholder="phone">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group has-icon"><i class="icon-user-plus"></i>
                                        <input v-model="form.referrer" type="text" class="form-control form-control-lg no-b"
                                            placeholder="Referral Username">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group has-icon"><i class="icon-lock"></i>
                                        <input v-model="form.password" type="text" class="form-control form-control-lg no-b"
                                            placeholder="password">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group"><i ></i>
                                        <select v-model="form.country" class="form-control form-control-lg no-b" name="country">
                                            <option value="">Select country</option>
                                           <option v-for="country,i in countries" :value="country" :key="i">{{country}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <span v-if="processing" class="btn btn-success">....</span>
                                    <input v-else type="submit" class="btn btn-success btn-lg btn-block" value="Register">
                                    <p class="forget-pass text-white text-center">Do You have an account already? 
                                        <router-link :to="{name:'login'}"> 
                                            <b>Click Here to Login</b>
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<style scoped>
    .plane-container {
        position: absolute;
        top: 50%;
        left: 50%;
    }
    
    .green4 {
    background-color: #52BE80!important;
    }
    
    .responsive {
    max-width: 100%;
    }
</style>

<script>
import {mapActions,mapState} from 'vuex'
import toastr from 'toastr'
export default {
    name:'register',
    data() {
        return {
            form:{
                name:'',
                username:'',
                email:'',
                phone:'',
                password:'',
                referrer: '',
                country:''
            },

            countries:[
                "algeria",
                "angola",
                "benin",
                "botswana",
                "burkina Faso",
                "burundi",
                "cameroon",
                "cape-verde",
                "central-african-republic",
                "chad",
                "comoros",
                "congo-brazzaville",
                "congo-kinshasa",
                "ivory-coast",
                "djibouti",
                "egypt",
                "equatorial-guinea",
                "eritrea",
                "ethiopia",
                "gabon",
                "gambia",
                "ghana",
                "guinea",
                "guinea-bissau",
                "kenya",
                "lesotho",
                "liberia",
                "libya",
                "madagascar",
                "malawi",
                "mali",
                "mauritania",
                "mauritius",
                "mayotte",
                "morocco",
                "mozambique",
                "namibia",
                "niger",
                "nigeria",
                "rwanda",
                "reunion",
                "saint-helena",
                "senegal",
                "seychelles",
                "sierra-leone",
                "somalia",
                "south-africa",
                "sudan",
                "south-sudan",
                "swaziland",
                "Sao-tome-príncipe",
                "tanzania",
                "togo",
                "tunisia",
                "uganda",
                "western-sahara",
                "zambia",
                "zimbabwe"
            ],

            errors:{
                name:'',
                username:'',
                email:'',
                phone:'',
                password:'',
                referrer: '',
                country:''
            }
        }
    },

    computed:{
        ...mapState({
            processing:state=>state.processing
        })
    },

    methods:{
        ...mapActions('authStore',['register']),

        signUp(){
            this.register(this.form).catch(err=>{
                if(err.response.status==422){
                    err.response.data.errors
                    toastr.error("One or more fields have invalid data")
                }else{
                    console.log(err)
                    toastr.error("An internal error occured")
                }
                this.$store.commit('processed',null,{root:true})
            })
        }
    }
}
</script>