<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card my-3">
                <div class="card-body">
                    <div class="card-header white text-green">PIN PURCHASE HISTORY</div><br>
                        <div class="form-row" style="overflow-x:auto;">
                            <template v-if="loading">
                                <b-skeleton-table
                                    :rows="5"
                                    :columns="5"
                                    :table-props="{ bordered: true, striped: true }"
                                ></b-skeleton-table>
                            </template>
                            <table v-else class="table table-bordered table-hover data-tables" data-options='{ "paging": true; "searching":true}'>
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Payment Method</th>
                                        <th>Units &nbsp;&nbsp;</th>
                                        <th>Cost &nbsp;&nbsp;</th>
                                        <th>Pin Transaction Status &nbsp;&nbsp;</th>
                                        <th>Date</th>
                                    </tr> 
                                </thead>
                                <tbody>
                                    <tr v-for="purchase,i in pinPurchaseHistory" :key="i">
                                        <th scope="row">{{++i}}</th>
                                        <td>{{purchase.payment_mode}}</td>
                                        <td>{{purchase.units}}</td>
                                        <td>{{purchase.amount}}</td>
                                        <td>
                                            <span v-if="purchase.is_successful" class="badge text-white bg-green">Successful</span>
                                            <span v-else class="badge text-white bg-red">Not-successful</span>
                                        </td>
                                        <td>{{purchase.created_at}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>	
                    </div>
                </div>
                                               
        </div>
    </div>
</template>
<script>
import {mapActions,mapGetters,mapState} from 'vuex'
export default {
    computed:{
        ...mapState({
            loading:state=>state.loading
        }),

        ...mapGetters('userStore',['pinPurchaseHistory'])
    },

    created(){
        if(this.pinPurchaseHistory.length ==0){
            this.getPinPurchaseHistory()
        }
    },

    methods:{
        ...mapActions('userStore',['getPinPurchaseHistory'])
    }
}
</script>