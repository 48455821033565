<template>
    <div class="tab-pane fade" id="v-pills-Request-for-loan" role="tabpanel" aria-labelledby="v-pills-Request-for-loan-tab">
        <div class="row">
            <div class="col-md-8">
                    <div class="card">
                        <div class="card-body white">
                            <strong class="font-weight-bold text-success">Loan Pay Back</strong><br><br>
                            <ul class="nav nav-tabs profile-tab" role="tablist">
                                <li>
                                    <a class="nav-link active"  data-toggle="tab" href="#pop" role="tab" ><i class="icon icon-account_balance"></i>Pay via Bank</a>
                                </li>
                                <li>
                                    <a class="nav-link"  data-toggle="tab" href="#wallet" role="tab" ><i class="icon icon-account_balance_wallet"></i>Pay via Wallet</a>
                                </li>
                            </ul>
                        </div>
                            <div class="card-body">
                                <div class="tab-content">
                                <div class="tab-pane active" id="pop" role="tabpanel"><br>
                                    <form @submit.prevent="submitPayment()" id="pay-back-pop" enctype="multipart/form-data">
                                        <div class="form-group m-0">
                                            <label for="name" class="col-form-label s-12">Amount</label>
                                            <input name="amount" :max="loanBalance" required class="form-control" type="number">
                                            <label for="name" class="col-form-label s-12">Proof Of Payment (POP)</label>
                                            <input name="pop" required class="form-control dropify" type="file">
                                            <br>
                                        </div>
                                        <div class="text-right">
                                            <span v-if="processing" class="btn btn-success mt-2">....</span>
                                            <button v-else type="submit" class="btn btn-success mt-2">
                                                <i class="icon-arrow_forward mr-2"></i>Pay Loan
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            <div class="tab-pane" id="wallet" role="tabpanel"><br>
                                <div class="card-header white text-center">
                                    <div class="col-md-12"><img class="img-responsive " src="/assets/img/wallet.png"></div><br>
                                    <b-card v-if="walletBalanceLoading">
                                        <b-skeleton animation="throb" width="85%"></b-skeleton>
                                        <b-skeleton animation="throb" width="55%"></b-skeleton>
                                    </b-card>
                                    <div v-else class="sc-counter s-36 font-weight-bold text-center text-green">{{walletBalance}}</div>
                                    <h6 class="counter-title text-center"> Wallet Balance ($)</h6>
                                </div>
                                <br>

                                <form method="POST">
                                    <div class="form-group text-center">
                                        <div class="text-center">
                                            <button type="submit" class="btn btn-success mt-2">
                                                <i class="icon-arrow_forward mr-2"></i>Confirm Loan Repayment
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card no-b">
                    <div class="card-header bg-white">
                        <h4 class="green-text"><strong class="font-weight-bold">Loan Wallet</strong></h4>
                        <small>Your current loan balance</small>
                    </div>
                    <div class="collapse show text-center" id="invoiceCard"><br>
                        <div class="col-md-12">
                            <div class="card-body p-0">
                                <img src="/assets/img/loan.png">
                            </div>
                        </div>
                        <b-card v-if="loading">
                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                            <b-skeleton animation="throb" width="55%"></b-skeleton>
                            <b-skeleton animation="throb" width="70%"></b-skeleton>
                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                        </b-card>
                        <div class="card-body text-center">
                            <small>loan Account</small>
                            <h1 class="font-weight-bold text-red">${{loanBalance}}</h1>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState,mapGetters,mapActions} from 'vuex'
export default {
    data(){
        return{
            form:{
                amount:null
            },
            errors:{ 
                amount:null,
                pop: null
            }
        }
    },

    computed:{
        ...mapState({
            processing:state=>state.processing,
            loading:state=>state.loading
        }),

        ...mapGetters('userStore',['loanBalance','recentLoan','walletBalance']),

        walletBalanceLoading(){
            return this.walletBalance == null ? true : false
        }
    },

    created(){

        if(Object.entries(this.recentLoan).length == 0){
            this.getRecentLoan().then(res=>{
                    this.getLoanBalance(res.id)
            }).catch(err=>{
                console.log(err)
                this.$store.commit('loaded',null,{root:true})
            })
        }

        if(this.walletBalance == null){
            this.getWalletBalance()
        }
    },

    methods:{
        ...mapActions('userStore',['getLoanBalance','getRecentLoan','loanPayment','getWalletBalance']),

        submitPayment(){
            let form  = document.getElementById('pay-back-pop')
            let data = new FormData(form)
            this.loanPayment({loanId:this.recentLoan.id,data:data})
        }

    }
}
</script>