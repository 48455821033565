<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-3 offset-md-2">
                <router-link :to="{name:'pending-entry-payments'}">
                    <button class="btn btn-primary">Back</button>
                </router-link>
            </div>
            <b-card v-if="processing" class="col-md-6">
                <b-skeleton animation="throb" width="85%"></b-skeleton>
            </b-card>
            <template v-else>
                <div class="col-md-3">
                    <button @click="approveProofOfPayment(id)" class="btn btn-success">Approve</button>
                </div>
                <div class="col-md-3">
                    <router-link :to="{name:'pending-entry-payments'}">
                        <button class="btn btn-danger">Disapprove</button>
                    </router-link>
                </div>
            </template>
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="card mb-3">
                    <div class="card-header white text-green"><strong>{{name}}'s</strong> Proof of payment</div>
                    <div class="card-body ">
                        <img :src="`http://localhost:80/storage/${pop}`" width="500" height="500">
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import {mapActions,mapState} from 'vuex'
export default {
    data(){
        return{
            id:this.$route.query.id,
            name:this.$route.query.name,
            pop:this.$route.params.pop
        }
    },
    computed:{
        ...mapState({
            processing:state=>state.processing
        }),
    },

    created(){
        
    },

    methods:{
        ...mapActions('adminStore',['approveProofOfPayment','disapproveProofOfPayment'])
    }
}
</script>