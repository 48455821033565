<template>
    <div class="tab-pane fade show active" id="v-pills-wallet-summary" role="tabpanel" aria-labelledby="v-pills-wallet-summary-tab">
        <div class="row">
            <div v-if="twoFactorAuthEnabled" class="col-md-6 offset-md-3">
                <div class="card">
                    <div class="card-header bg-white">
                        <h6 class="green-text"><strong class="font-weight-bold">Disable 2FA</strong></h6>
                    </div>
                    <div class="col-lg-6 mx-md-auto">
                        <br>
                        <div class="text-center">
                            <small class="mt-2">To proceed with disabling 2FA, enter your generated code on your auth App.</small>
                        </div>
                        <br>
                        <form @submit.prevent="disableAuth()">
                            <div class="form-group has-icon"><label>Enter auth code</label>
                                <input v-model="twoFactorCode" type="text" class="form-control form-control-lg">
                            </div>
                            <div class="text-right">
                                <span class="btn btn-success" v-if="processing">...</span>
                                <button v-else type="submit" class="btn btn-success mt-2">
                                    <i class="icon-arrow_forward mr-2"></i>Submit 
                                </button>
                            </div>
                        </form>
                        <br>
                        <br>
                    </div>
                </div>
            </div>
            <div v-else class="col-md-12">
                <div class="row my-3">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-header white text-center">
                                    <img src="/assets/img/step1.png" class="img-responsive">
                            </div>
                            <div class="card-body">
                                <!-- Big Heading -->
                                <div class="text-center">
                                    <h4 class="font-weight-bold">Download App</h4><br>
                                    <img class="img-responsive" src="/assets/img/GA.png">
                                </div>
                                <div class="text-center">
                                    <h4 class="font-weight-bold">Let's Begin The Protection...</h4>
                                    <small>Click below to Download Google Authenticator</small><br><br>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en
                                    "><img class="img-responsive" src="/assets/img/playstore.png"></a>
                                    <a target="_blank" href="https://apps.apple.com/us/app/google-authenticator/id388497605
                                    "><img class="img-responsive" src="/assets/img/apple.png"></a>
                                </div><br>
                            </div>
                        </div>
                    </div>	
                    
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-header white text-center">
                                    <img src="/assets/img/step2.png" class="img-responsive">
                            </div>
                            <img src="" class="img-responsive">
                            <div class="card-body">
                                
                                <form method="post" id="generateQr">
                                    <div class="text-center">
                                        <h4 class="font-weight-bold">Scan QRcode</h4>
                                        <img class="img-responsive" :src="qrImage">
                                    </div>
                                    <div class="text-center">
                                        <h5 class="font-weight-bold">Click To Generate QR Code </h5>
                                        <small>You are steps away from protecting your Back-Office</small><br><br>
                                        <span class="btn btn-success" v-if="processing">...</span>
                                        <button v-else class="btn btn-success"  id="generate-qr-code" @click="generateQr()">
                                            <i class="icon-arrow_forward mr-2"></i>Generate
                                        </button>
                                    </div>
                                </form><br>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-header white text-center">
                                <img src="/assets/img/step3.png" class="img-responsive">
                            </div>
                            <img src="" class="img-responsive">
                            
                            <div class="card-body">
                                <div class="text-center">
                                    <h4 class="font-weight-bold">Complete Your Protection</h4>
                                    <small>Enter the code generated from Google Authenticator</small><br><br>
                                </div>
                                <div class="text-center"><img class="img-responsive text-center" src="/assets/img/lock1.png"></div>
                                <form class="form-horizontal " @submit.prevent="">
                                    <label class="col-form-label"></label>
                                    <input v-model="twoFactorCode"  placeholder="Enter generated code" :disabled="qrExists" type="number" class="form-control form-control-line">
                                    <span class="btn btn-success" v-if="processing">...</span>
                                    <button v-else class="text-center btn  btn-success mt-2" :disabled="qrExists"  id="verify-qr-code" @click="verifyQr()">
                                        <i class="icon-arrow_forward mr-2"></i>Submit</button>
                                </form>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions,mapGetters,mapState} from 'vuex'
import toastr from 'toastr'
    export default {
        data(){
            return {
                twoFactorCode:null
            }
        },

        computed:{
            ...mapState({
                processing:state=>state.processing,
                loading:state=>state.loading
            }),

            ...mapGetters('userStore',['qrCode','twoFactorAuthEnabled']),

            qrExists(){
                return this.qrCode ==null ? true : false
            },
            qrImage(){
                return this.qrCode ==null ? '/assets/img/qrcode.png' : this.qrCode
            }
        },

        created(){
            if(this.twoFactorAuthEnabled == null){
                this.get2FactorStatus()
            }
        },

        methods:{
            ...mapActions('userStore',['generateQrCode','verifyQrCode','get2FactorStatus','disable2fa']),

            generateQr(){
                this.generateQrCode().then(()=>{
                    //this.$forceUpdate();
                }).catch(err=>{
                    toastr.error("An internal error occured")
                    console.log(err)
                    this.$store.commit('processed',null,{root:true})
                })
            },

            verifyQr(){
                if(!this.twoFactorCode){
                    toastr.error("the qr code field is required")
                    return
                }
                this.verifyQrCode({qr_code:this.twoFactorCode,enable_2fa:true}).catch(err=>{
                    if(err.response.status == 422){
                        toastr.error(err.response.data.message)
                    }else{
                        toastr.error("An internal error occured")
                        console.log(err)
                    }
                    this.$store.commit('processed',null,{root:true})
                })
            },
            
            disableAuth(){
                this.disable2fa({qr_code:this.twoFactorCode})
            }
        }

    }
</script>