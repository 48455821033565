<template>
    <div>
        <profile-header/>

        <div class="container-fluid animatedParent animateOnce my-3">
            <div class="animated ">
                <div class="tab-content" id="v-pills-tabContent">
                    <div v-if="loading">
                        <b-card>
                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                            <b-skeleton animation="throb" width="55%"></b-skeleton>
                            <b-skeleton animation="throb" width="70%"></b-skeleton>
                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                            <b-skeleton animation="throb" width="55%"></b-skeleton>
                            <b-skeleton animation="throb" width="70%"></b-skeleton>
                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                            <b-skeleton animation="throb" width="55%"></b-skeleton>
                            <b-skeleton animation="throb" width="70%"></b-skeleton>
                        </b-card>
                    </div>
                    <template v-else>
                        <my-profile-tab v-if="$route.params.id" :user="showUser"/>
                        <my-profile-tab v-else :user="user"/>

                        <bank-details-tab/>

                        <profile-photo-tab/>

                        <upline-tab :upline="profile.upline"/>
                    </template> 
                                     
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProfileHeader from '@/components/user/profile/ProfileHeader'
import MyProfileTab from '@/components/user/profile/MyProfileTab'
import BankDetailsTab from '@/components/user/profile/BankDetailsTab'
import ProfilePhotoTab from '@/components/user/profile/ProfilePhotoTab'
import UplineTab from '@/components/user/profile/UplineTab'
import {mapActions,mapState,mapGetters} from 'vuex'
import toastr from 'toastr'
export default {
    components:{
        ProfileHeader,
        MyProfileTab,
        BankDetailsTab,
        ProfilePhotoTab,
        UplineTab
    },
    data(){
        return{
            userId : this.$route.params.id,
            //urls:window.history.pre
        }
    },
    computed:{
        ...mapState({
            processing: state => state.processing,
            loading: state=>state.loading,
        }),
        ...mapGetters(['user']),
        ...mapGetters('userStore',['profile']),
        ...mapGetters('adminStore',['showUser']),
    },

    watch: {
        $route(to, from) {//will come back
            let id = to.params.id
            if(id>0) {
                this.getAUser(id)
            }else{
                this.getUser()
            } 
            console.log(from)
            
            this.getUpline(id).catch(err=>{
                console.log(err)
                toastr.error("unable to retrieve upline")
            })
        },
        userId: function(newId,old){
            this.getAUser(newId)
            console.log(old)
        }
    },

    //mounted3

    created(){
        let id = this.$route.params.id
        localStorage.setItem('watch_id',id);
        let user = id ? this.showUser : this.user
        if(Object.entries(user).length==0){
                id ? this.getAUser(id) : this.getUser()
                console.log(user)
        }
        if(Object.entries(this.profile.upline).length==0){
            this.getUpline(id).catch(err=>{
                console.log(err)
                toastr.error("unable to retrieve upline")
            })
        }
    },
    methods:{
        ...mapActions('userStore',['getUser','getUpline']),
        ...mapActions('adminStore',['getAUser']),
        
    }
}
</script>