import actions from './actions/admin-actions'
import getters from './getters/admin-getters'
import mutations from './mutations/admin-mutations'

export default{
    namespaced: true,

    state(){
        return{
            usersCount:null,
            pinPurchaseSum:null,
            paidUsersCount:null,
            unpaidUsersCount:null,
            withdrawalRequestCount:null,
            users:[],
            showUser:{},
            pendingEntryPayments:[],
            //proofOfPayment:'',
            entryPaymentHistory:[],
            pinPurchaseHistory:[],
            pinRegistrationHistory:[],
            pendingPinPurchases:[],
            bankDetails:[],
            systemSettings:{},
            loanRequestHistory:[],
            pendingLoanRequestApprovals:[],
            loanPaymentHistory:[],
            pendingLoanPaymentApprovals:[]
        }
    },

    actions:{
        ...actions
    },
    getters:{
        ...getters
    },
    mutations:{
        ...mutations
    }
    

}