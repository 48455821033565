<template>
    <div class="card">
        <div class="card-header white">
            <a class="nav-link text-green" id="w1-tab1" data-toggle="tab">Send General Message</a>        
        </div>
        <div class="card-body no-p">
            <div class="row p-3">
                <div class="col-md-12">
                    <form>
                        <div class="form-row">
                            <div class="col-md-12 mb-3">
                                <input type="text" class="form-control" id="validationDefault03" placeholder="Subject" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-12 mb-3">
                                <textarea type="text" class="form-control" id="validationDefault03" placeholder="Message" required></textarea>
                            </div>
                        </div>
                        <button class="btn btn-success" type="submit"><i class="icon-arrow_forward mr-2"></i>Send</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>