<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card my-3">
                <div class="card-body">
                    <div class="card-header white text-green">Pending pin approvals</div><br>
                        <div class="form-row" style="overflow-x:auto;">
                            <table id="example2" class="table table-bordered table-hover data-tables"
                                    data-options='{ "paging": false; "searching":false}'>
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Username</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr> 
                                </thead>
                                <tbody>
                                    <tr v-if="loading">
                                        <td colspan="10">
                                            <b-card>
                                                <b-skeleton animation="throb" width="85%"></b-skeleton>
                                                <b-skeleton animation="throb" width="55%"></b-skeleton>
                                                <b-skeleton animation="throb" width="70%"></b-skeleton>
                                            </b-card>
                                        </td>
                                    </tr>
                                    <template v-else>
                                        <tr v-if="pendingPinRegApprovals.length < 1">
                                            <td colspan="4">
                                                <p class="alert alert-info text-center">
                                                    There no pending pin registration
                                                </p>
                                            </td>
                                        </tr>
                                        <tr v-else v-for="reg,i in pendingPinRegApprovals" :key="i">
                                            <th scope="row">{{++i}}</th>
                                            <td>{{reg.buyer.username}}</td>
                                            <td>{{reg.created_at}}</td>
                                            <td>
                                                <div class="dropdown">
                                                    <button class="btn btn-success  dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="caret"></i>
                                                    </button>
                                                    <div class="dropdown-menu " aria-labelledby="dropdownMenuButton" style="position:relative">
                                                        <b-button class="dropdown-item text-green" @click="setId(reg.id)" v-b-modal.approve>Approve</b-button>
                                                        <b-button class="dropdown-item text-green" @click="setId(reg.id)" v-b-modal.disapprove>Disapprove</b-button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>	
                    </div>
                </div>                        
        </div>

        <confirm :modalId="'approve'" :modalTitle="'Approve Pin Registration'">
            <div class="alert alert-info">
                Are you sure you want to confirm this approval?
                <span class="btn btn-primary" v-if="processing">...</span>
                <button v-else class="btn btn-primary" @click="approve()">Approve</button>
            </div>
        </confirm>

        <confirm :modalId="'disapprove'" :modalTitle="'Dispprove Pin Registration'">
            <div class="alert alert-info">
                Are you sure you want to confirm this disapproval?
                <span class="btn btn-primary btn-disabled" v-if="processing">...</span>
                <button v-else class="btn btn-primary" @click="disapprove()">Dispprove</button>
            </div>
        </confirm>
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import Confirm from '@/components/Confirm.vue'
export default {
    components:{
        Confirm
    },

    data(){
        return{
            regId:null,
            modalShow:false
        }
    },

    computed:{
      
        ...mapState({
            loading:state=>state.loading,
            processing:state=>state.processing
        }),

        ...mapGetters('userStore',['pendingPinRegApprovals'])
    },

    created(){
        if(this.pendingPinRegApprovals.length == 0){
            this.getPendingPinRegApprovals()
        }
    },

    methods:{
        ...mapActions('userStore',['getPendingPinRegApprovals','approvePinRegistration','disapprovePinRegistration']),

        approve(){
            this.approvePinRegistration({id:this.regId, cb: ()=>{
                this.$bvModal.hide('approve')
            }})
            
        },

        disapprove(){
            this.disapprovePinRegistration({id:this.regId, cb: ()=>{
                this.$bvModal.hide('disapprove')
            }})
        },

        setId(id){
            this.regId = id
        }
    }
    
}
</script>