<template>
    <div>
        <b-card v-if="loading">
            <b-skeleton animation="throb" width="85%"></b-skeleton>
            <b-skeleton animation="throb" width="55%"></b-skeleton>
            <b-skeleton animation="throb" width="70%"></b-skeleton>
        </b-card>
        <form v-else id="contactFormAdvanced" @submit.prevent="submit()">
            <div class="form-row">
                <div v-if="user.cycled_out" class="form-group col-md-12">
                    <select class="form-control" name="ref_id">
                        <option value="">Select a new referer</option>
                    </select>
                </div><hr>
                <div class="form-group col-md-12">
                    
                    <select class="form-control" v-model="form.seller_id">
                        <option :value="null">Select pin merchant</option>
                        <option v-for="seller in pinMerchants" :value="seller.id" :key="seller.id">{{seller.username}}</option>
                    </select>
                </div>
                    
            </div>
            <div class="form-row">
                <div class="form-group col-md-12 mb-5">
                    <span v-if="processing" class="btn btn-primary btn-disabled">...</span>
                    <input v-else type="submit" id="contactFormSubmit" value="Send" class="btn btn-primary btn-modern pull-right" data-loading-text="Loading...">
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import {mapState,mapActions,mapGetters} from 'vuex'
export default {
    props:{
        user:{
            type:Object,
            required:true
        }
    },
    data() {
        return {
            form:{
                seller_id:null
            }
        }
    },
    computed:{
        ...mapState({
            processing:state=>state.processing,
            loading:state=>state.loading
        }),
        ...mapGetters('userStore',['pinMerchants']),
    },

    created(){
        if(this.pinMerchants.length == 0){
            this.getPinMerchants()
        }
    },

    methods:{
        ...mapActions('userStore',['pinRegistration','getPinMerchants']),
        submit(){
            this.pinRegistration({data:this.form, cb:()=>{
                this.$emit('reg-submitted')
            }})
        }
    }
}
</script>