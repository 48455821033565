<template>
    <div class="row text-white no-gutters no-m shadow">
        <div class="col-lg-3">
            <div class="green  p-40">
                <div class="float-up text-center">
                    <span class="icon icon-verified_user s-48"></span>
                </div>
                <div class="s-36 text-center">Active</div>
                <h6 class="counter-title text-center">Verified Status</h6>
            </div>
            
        </div>
        
        <div class="col-lg-3">
            <div class="green  p-40">
                <div class="float-up text-center">
                    <span class="icon icon-bar-chart s-48"></span>
                </div>
                <div class=" s-36 text-center">{{user.level.name}}</div>
                <h6 class="counter-title text-center">Level</h6>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="green counter-box p-40">
                <div class="float-up text-center">
                    <span class="icon icon-wallet s-48"></span>
                </div>
                <div class="sc-counter s-36 text-center">{{walletBalance}}</div>
                <h6 class="counter-title text-center">Wallet ($)</h6>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="green counter-box p-40">
                <div class="float-up text-center">
                    <span class="icon icon-money-bag s-48"></span>
                </div>
                <div class="sc-counter s-36 text-center">550</div>
                <h6 class="counter-title text-center">Loan Collected ($)</h6>
            </div>
        </div>
    </div>
</template>
<script>
//import {mapState,mapGetters,mapActions} from 'vuex'
export default {
    props:{
        user:{
            type:Object,
            required:true
        },
        walletBalance:{
            type:Number,
            required:true
        }
    },
    
}
</script>