<template>
    <div class="">
        <div class="card no-b">
            <div class="card-header bg-white">
                <h4 class="green-text"><strong class="font-weight-bold">PIN UNIT</strong></h4>
            </div>
            <div class="collapse show text-center" id="invoiceCard">
                    <div class="card-body  text-center">
                        <img src="/assets/img/pins.png"><br><br>
                    <h1 class="font-weight-bold text-green">{{user.pin_units}}</h1>
                    <small>Pin in your wallet</small>
                </div>                                            
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        user:{
            type:Object,
            required:true
        }
    },
}
</script>