<template>
    <div class="card" id="bank">
        <div class="card-header">
            Bank Settings
        </div>
        <div class="card-body">
            <form class="form-horizontal form-materia" @submit.prevent="submit">
                <input type="hidden" name="_token" value="yKOJ6VLPGcVBL5A0Lvl5MAKrWsTSvmSJRYk89DP3">
                    <div class="form-group">
                        <label class="col-md-12">Bank Name</label>
                        <div class="col-md-12">
                            <select v-model="form.bank_name" class="form-control form-control-line">
                                <option :value="null">Select Bank</option>
                                <option v-for="bank,i in banks" :key="i" :value="bank">{{bank}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="example-email" class="col-md-12">Bank Account Name</label>
                        <div class="col-md-12">
                            <input v-model="form.bank_account_name"  type="text" class="form-control form-control-line">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="example-email" class="col-md-12">Bank Account Number</label>
                        <div class="col-md-12">
                            <input v-model="form.bank_account_number"   type="number" class="form-control form-control-line">
                        </div>
                    </div>
                    
                    <div class="form-group">
                        <div class="col-sm-12">
                            <button class="btn btn-success" id="add-bank-details"><i class="icon-save mr-2"></i>Add Bank</button>
                        </div>
                    </div>
            </form> 
        </div>
    </div>
</template>

<script>
import {mapActions,mapState} from 'vuex'
export default {
    name:'bank-details',
    data(){
        return {
            form:{
                bank_name:null ,
                bank_account_name:null,
                bank_account_number:null
            },
            banks:[
                'First Bank',
                'Guaranty Trust Bank',
                'Zenith Bank',
                'Stanbic IBTC Bank',
                'Access Bank (Diamond)',
                'Ecobank Bank',
                'Fidelity Bank',
                'Access Bank',
                'Unity Bank Plc',
                'Skye Bank (Polaris)',
                'Keystone Bank Limited',
                'First City Monument Bank',
                'Wema Bank',
                'Citibank',
                'Standard Chartered Bank',
                'Heritage Bank Plc',
                'BNI',
                'United Bank for Africa',
                'Savanna Bank',
                'Sterling Bank',
                'Societe Generale',
                'NSIA Banque agnence',
                'Union Bank',
                'Polaris Bank',
                'Union Bank'
            ],
        }
    },

    computed:{
        ...mapState({
            processing:state=>state.processing
        })
    },

    methods:{
        ...mapActions('adminStore',['addBankDetails']),
        submit(){
            this.addBankDetails(this.form)//.catch()
        }
    }
}
</script>