<template>
    <div class="card">
        <div class="card-header">
            Pin Price ($)
        </div>
        <div class="card-body">
            <form class="form-horizontal form-materia" @submit.prevent="submit">
                <div class="form-group">
                    <label class="col-md-12">Pin Price</label>
                    <div class="col-md-12">
                        <input type="text" v-model="form.pin_price" min="0" class="form-control form-control-line">
                        <span class="text-danger" v-if="errors.pin_price">{{errors.pin_price}}</span>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-sm-12">
                        <span class="btn btn-success" v-if="processing">...</span>
                        <button v-else class="btn btn-success"><i class="icon-save mr-2"></i>Update</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
import toastr from 'toastr'
export default {
    name:'pin-setting',
    props:{
        systemSettings:{
            type:Object,
            required:true
        }
    },
    data(){
        return{
            form:{
                pin_price:this.systemSettings.pin_price
            },
            errors:{
                pin_price:null,
            }
        }
    },

    computed:{
        ...mapState({
            processing:state=>state.processing
        }),
    },

    methods:{
        ...mapActions('adminStore',['updateSettings']),
        submit(){
            this.updateSettings({data:this.form,field:'Pin price'}).catch(err=>{
                if (err.response && err.response.status === 422) {
                    this.errors = err.response.data.errors;
                    toastr.warning("Ivalid input")
                    console.log("error-" + JSON.stringify(err.response.data));
                }else{
                     toastr.error("An error occured while updating Pin price")
                }
                this.$store.commit('processed',null,{root:true})
            })
        }
    },
}
</script>