<template>
     <div class="tab-pane fade" id="v-pills-Wallet-Detailed-Calculation" role="tabpanel" aria-labelledby="v-pills-Wallet-Detailed-Calculation-tab">
        <div class="row">
            <div class="col-md-12">
                <div class="row my-3">
                    <!-- bar charts group -->
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header bg-white">
                                <h6 class="green-text"><strong class="font-weight-bold">Change Password</strong></h6>
                            </div>
                            <div class="col-lg-6 mx-md-auto">
                                <br>
                                <div class="text-center">
                                    <small class="mt-2">To proceed with the change of your password, enter your email.</small>
                                </div>
                                <br>
                                <form action="https://xvelopers.com/demos/html/paper-panel/dashboard2.html">
                                    <div class="form-group has-icon"><label>Enter Email</label>
                                        <input type="text" class="form-control form-control-lg" placeholder="">
                                    </div>
                                    <input type="hidden" name="user_id" value="278">
                                    <div class="text-right"><button type="submit" class="btn btn-success mt-2"><i class="icon-arrow_forward mr-2"></i>Submit </button></div>
                                </form>
                                <br>
                                <br>
                            </div>
                        </div>
                    </div>
                    <!-- /bar charts group -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>