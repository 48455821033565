<template>
    <div class="tab-pane fade" id="v-pills-upline" role="tabpanel" aria-labelledby="v-pills-upload-tab">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <template v-if="uplineLoading">
                    <b-card>
                        <b-skeleton animation="throb" width="85%"></b-skeleton>
                        <b-skeleton animation="throb" width="55%"></b-skeleton>
                        <b-skeleton animation="throb" width="70%"></b-skeleton>
                        <b-skeleton animation="throb" width="85%"></b-skeleton>
                        <b-skeleton animation="throb" width="55%"></b-skeleton>
                        <b-skeleton animation="throb" width="70%"></b-skeleton>
                        <b-skeleton animation="throb" width="85%"></b-skeleton>
                        <b-skeleton animation="throb" width="55%"></b-skeleton>
                        <b-skeleton animation="throb" width="70%"></b-skeleton>
                    </b-card>
                </template> 
                <div v-else class=" my-3">
                    <div class="card" style="background-color:#52BE80;">
                        <div class="card-body text-center  p-5 ">
                            <div class="avatar avatar-xxl mb-3 ">
                                <img class="user_avatar" src="/assets/img/dummy/u13.png" alt="">
                            </div>
                            <h5 class="text-white font-weight-bold">{{upline.name}}</h5><br>
                            <div class="table-responsive">
                                <table class="table table-bordered text-white">
                                    <tbody>
                                    <tr>
                                        <td><i class="icon icon-account_circle"></i>&nbsp; Username</td>
                                        <td>{{upline.username}}</td>
                                    </tr>
                                    <tr>
                                        <td><i class="icon icon-mail-envelope-closed4"></i>&nbsp; Email</td>
                                        <td>{{upline.email}}</td>
                                    <tr>
                                        <td><i class="icon icon-level-up"></i>&nbsp; Level</td>
                                        <td>{{upline.level.name}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        upline:{
            type:Object,
            required:true
        }
    },
    computed:{
        uplineLoading: function(){return Object.entries(this.upline).length==0 ? true:false}
    }
}
</script>