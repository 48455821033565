import Layout from '@/components/user/Layout'
import Dashboard from '@/views/user/Dashboard'
import Profile from '@/views/user/Profile'
import Downlines from '@/views/user/Downlines'
import Genealogy from '@/views/user/Genealogy'
import Wallet from '@/views/user/Wallet'
import Loan from '@/views/user/Loan'
import Security from '@/views/user/Security'
import Grant from '@/views/user/Grant'
import Incentives from '@/views/user/Incentives'
import Unapproved from '@/views/user/Unapproved'
import PinPurchase from '@/views/user/PinPurchase'
import PinPurchaseHistory from '@/views/user/PinPurchaseHistory'
import PendingPinRegistrationApprovals from '@/views/user/PendingPinRegistrationApprovals'
import PinRegistrationHistory from '@/views/user/PinRegistrationHistory'
let routes = [

    {
        path: '/uapproved',
        name: 'unapproved',
        component: Unapproved

    },
    {
        path: '/user',
        component: Layout,
        children: [

            {
                name: 'user-dashboard',
                path: 'dashboard',
                component: Dashboard
            },
            {

                name: 'user-profile',
                path: 'profile',
                component: Profile
            },

            {
                name: 'user-downlines',
                path: 'downlines',
                component: Downlines
            },

            {
                name: 'user-genealogy',
                path: 'genealogy',
                component: Genealogy
            },

            {
                name: 'user-wallet',
                path: 'wallet',
                component: Wallet
            },
            {
                name: 'user-loan',
                path: 'loan',
                component: Loan
            },
            {
                name: 'user-security',
                path: 'security',
                component: Security
            },
            {
                name: 'user-grant',
                path: 'grant',
                component: Grant
            },
            {
                name: 'user-incentives',
                path: 'incentives',
                component: Incentives
            },
            {
                name: 'pin-purchases',
                path: 'pin-purchases',
                component: PinPurchase
            },
            {
                name: 'pin-purchase-history',
                path: 'pin-purchase-history',
                component: PinPurchaseHistory
            },
            {
                name: 'pending-pin-registration-approvals',
                path: 'pending-pin-registration-approvals',
                component: PendingPinRegistrationApprovals
            },
            {
                name: 'pin-registration-history',
                path: 'pin-registration-history',
                component: PinRegistrationHistory
            }
        ]
    }
]

export default routes;