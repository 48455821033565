<template>
    <div class="">
        <div class="card">
            <div class="card-header white">
                <strong class="font-weight-bold text-success">PURCHASE PIN</strong>
            </div>
            <br>
            <div class="col-md-4">
                <div class="card-body p-0">
                    <img class="img-responsive text-center" src="/assets/img/wallets.png">
                </div>
            </div>
            <div class="card-body">
                <ul class="nav nav-tabs profile-tab" role="tablist">
                    <li>
                        <a class="nav-link active"  data-toggle="tab" href="#pop" role="tab" ><i class="icon icon-account_balance"></i>Pay via Bank</a>
                    </li>
                    <li>
                        <a class="nav-link"  data-toggle="tab" href="#wallet" role="tab" ><i class="icon icon-account_balance_wallet"></i>Pay via Wallet</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane active" id="pop" role="tabpanel"><br>
                        <form id="pin-purchase-via-pop-form" method="POST" @submit.prevent="submit('pin-purchase-via-pop-form')" enctype="multipart/form-data">
                            <div class="form-group">
                                <label for="pin-units" class="col-form-label">Pin Unit</label>
                                <input name="pins" required placeholder="Enter Pin Units" class="form-control" min="1" type="number">
                            </div>
                            <div class="form-group m-0">
                                <label for="name" class="col-form-label s-12">Proof Of Payment (POP)</label>
                                <input name="image" required class="form-control dropify" type="file">
                                <br>
                            </div>
                            <input type="hidden" name="payment_mode" value="pop">
                            <div class="text-right">
                                <button v-if="processing" disabled class="btn btn-success mt-2">...processing</button>
                                <button v-else type="submit" class="btn btn-success mt-2">
                                    <i class="icon-arrow_forward mr-2"></i>Submit 
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane" id="wallet" role="tabpanel"><br>
                        <div class="card-header white text-center">
                            <img class="img-responsive " src="/assets/img/wallet.png">
                            <div class="sc-counter s-36 font-weight-bold text-center text-green">{{user.wallet}}</div>
                            <h6 class="counter-title text-center">Available Wallet($)</h6>
                        </div><br>
                        <form id="pin-purchase-via-wallet-form" method="POST" @submit.prevent="submit('pin-purchase-via-wallet-form')">
                            <div class="form-group">
                                <label for="inputAddress" class="col-form-label">Pin Unit</label>
                                <input name="pins" required placeholder="Enter Pin Units" class="form-control" min="1" type="number">
                            </div>
                            <input type="hidden" name="payment_mode" value="wallet">
                            <div class="text-right">
                                <button v-if="processing" disabled class="btn btn-success mt-2">...processing</button>
                                <button v-else type="submit" class="btn btn-success mt-2"><i class="icon-arrow_forward mr-2"></i>Submit </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions,mapState} from 'vuex';
import toastr from 'toastr';
export default {
    props:{
        user:{
            type:Object,
            required:true
        }
    },

    data(){
        return {

        }
    },

    computed:{
        ...mapState({
            processing:state=>state.processing
        })
    },

    methods:{
        ...mapActions('userStore',['purchasePin']),

        submit(id){
            let form = document.getElementById(id);
            let formData = new FormData(form);
            this.purchasePin(formData).catch(err=>{
                console.log(err);
                if (err.response && err.response.status === 422) {
                    this.errors = err.response.data.errors;
                    toastr.error(err.response.data.message)
                }else{
                    toastr.error("An error occured")
                }
                this.$store.commit('processed',null,{root:true})
            })
        }
    }
}
</script>