<template>
    <aside class="main-sidebar fixed offcanvas shadow" data-toggle='offcanvas'>
        <section class="sidebar">
            <div class="w-80px mt-3 mb-3 ml-3 ">
                <img src="/assets/img/basic/logo6.png" alt="">
            </div>
            <div class="relative">
                <a data-toggle="collapse" href="#userSettingsCollapse" role="button" aria-expanded="false"
                aria-controls="userSettingsCollapse" class="btn-fab btn-fab-sm absolute fab-right-bottom fab-top btn-success shadow1 ">
                    <i class="icon icon-arrow-down"></i>
                </a>
                <div class="user-panel p-3 light mb-2">
                    <div>
                        <div class="float-left image">
                            <img class="user_avatar" src="/assets/img/dummy/u2.png" alt="User Image">
                        </div>
                        <div class="float-left info">
                            <h6 class="font-weight-light mt-2 mb-1">Alexander Pierce</h6>
                            <a class="text-success" href="#" ><i class="icon-circle text-success blink"></i> Online</a>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="collapse multi-collapse" id="userSettingsCollapse">
                        <div class="list-group mt-3 shadow">
                            <a href="index.html" class="list-group-item list-group-item-action ">
                                <i class="mr-2 icon icon-sign-out text-green"></i><span class="green-text">Logout</span>
                            </a>
                            <a href="#" class="list-group-item list-group-item-action"><i
                                    class="mr-2 icon-key5 text-green"></i><span class="green-text">Change Password</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="sidebar-menu">
                <li class="treeview active">
                    <router-link :to="{name:'user-dashboard'}" >
                        <i class="icon icon-barometer2 green-text s-18"></i>
                        <span class="green-text">Dashboard</span> 
                    </router-link>
                </li>
                <li class="treeview">
                    <router-link :to="{name:'user-profile'}">
                        <i class="icon icon-vcard green-text s-18"></i>
                        <span class="green-text">Profile</span>
                    </router-link>
                </li>
                <li class="treeview">
                    <router-link :to="{name:'user-downlines'}">
                        <i class="icon icon-people green-text s-18"></i>
                        <span class="green-text">Downlines</span>
                    </router-link>
                </li>
                <li class="treeview ">
                    <router-link :to="{name:'user-genealogy'}">
                        <i class="icon icon-sitemap green-text s-18"></i><span class="green-text">Geneology</span>
                    </router-link>
                </li>
                <li class="treeview">
                    <a class="green-text"><i class="icon icon-account_balance green-text s-18"></i>My Finance<i
                        class="icon icon-angle-left s-18 pull-right"></i></a>
                    <ul class="treeview-menu">
                        <li>
                            <router-link :to="{name:'user-wallet'}">
                                <i class="icon icon-account_balance_wallet"></i>Wallet
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'user-loan'}">
                                <i class="icon icon-money-bag "></i>Loan
                            </router-link>
                        </li>
                        <li><router-link :to="{name:'user-grant'}">
                                <i class="icon icon-money"></i>Grant
                            </router-link>
                        </li>
                         <li><router-link :to="{name:'user-incentives'}">
                                <i class="icon icon-gift"></i>Incentive
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="treeview">
                    <a href="#">
                        <i class="icon icon-chat_bubble green-text s-18"></i> <span class="green-text">Notification</span>
                    </a>
                </li>
                <li class="treeview">
                    <a class="green-text"><i class="icon icon-account_balance green-text s-18"></i>Pin Transactions<i
                        class="icon icon-angle-left s-18 pull-right"></i></a>
                    <ul class="treeview-menu">
                        <li>
                            <router-link :to="{name:'pin-purchases'}">
                                <i class="icon icon-account_balance_wallet"></i>Purchase Pin
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'pin-purchase-history'}">
                                <i class="icon icon-account_balance_wallet"></i>Pin Purchase History
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'pending-pin-registration-approvals'}">
                                <i class="icon icon-money-bag "></i>Pending Registration Approvals
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'pin-registration-history'}">
                                <i class="icon icon-money-bag "></i>Pin Registration History
                            </router-link>
                        </li>
                        
                    </ul>
                </li>
                <li class="treeview">
                    <router-link :to="{name:'user-security'}">
                        <i class="icon icon-lock3 green-text s-18"></i>
                        <span class="green-text">Security</span>
                    </router-link>
                </li>
                <!-- <li class="treeview active ">
                    <a class="green-text" href="#">
                        <i class="icon icon-payment green-text s-18 "></i> <span class="green-text">PayInsta</span>
                        <i class="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                    <ul class="treeview-menu">
                        <li><a class="green-text" href="panel-page-chat.html"><i class="icon icon-wifi"></i>Buy Data</a>
                        </li>
                        <li><a class="green-text" href="panel7-tasks.html"><i class="icon icon-phone_in_talk"></i>Buy Airtime</a>
                        </li>
                        <li><a class="green-text" href="panel-page-calendar.html"><i class="icon icon-payment"></i>Electricity Bills</a>
                        </li>
                        <li><a class="green-text" href="panel-page-calendar2.html"><i class="icon icon-tv"></i>Cable Subscription</a>
                        </li>
                    </ul>
                </li> -->
            </ul>
        </section>
    </aside>
</template>
<script>
export default {
    
}
</script>