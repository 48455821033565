<template>
    <div>
        <header class="blue accent-3 relative">
            <div class="container-fluid text-green">
                <div class="row justify-content-between">
                    <ul class="nav nav-material nav-material-white responsive-tab" id="v-pills-tab" role="tablist">
                        <li>
                            <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home"><i class="icon icon-sitemap" ></i>Downlines Tree</a>
                        </li>
                        <li>
                            <a class="nav-link" id="v-pills-payments-tab" data-toggle="pill" href="#v-pills-payments" role="tab" aria-controls="v-pills-payments" aria-selected="false"><i class="icon icon-sitemap "></i>Network Tree</a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>

        <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row my-3">
                            <!-- bar charts group -->
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-header bg-white">
                                        <h4 class="green-text"><strong class="font-weight-bold">Downlines Tree</strong></h4>
                                        <small>View tree structure of your direct downlines</small>
                                    </div>
                                    <div class="card-body">
                                        <b-card v-if="loading">
                                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                                            <b-skeleton animation="throb" width="55%"></b-skeleton>
                                            <b-skeleton animation="throb" width="70%"></b-skeleton>
                                            <b-skeleton animation="throb" width="85%"></b-skeleton>
                                            <b-skeleton animation="throb" width="55%"></b-skeleton>
                                        </b-card>
                                        <organization-chart v-else :datasource="genealogy" :pan='true'></organization-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="v-pills-payments" role="tabpanel" aria-labelledby="v-pills-payments-tab">
                <div class="row">
                    <div class="col-md-12">
                         <div class="row my-3">
                              <div class="col-md-12">
                                <div class="card no-b">
                                    <div class="card-header bg-white">
                                        <h4 class="green-text"><strong class="font-weight-bold">Network Tree</strong></h4>
                                        <small>View tree structure of your Network</small>
                                    </div>
                                    <div class="collapse show" id="invoiceCard">
                                        <div class="card-body p-0">
                                            <div class="card no-b  no-r">
                                                <div class="card-body">

                                                </div>
                                                <br>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import OrganizationChart from 'vue-organization-chart'
import 'vue-organization-chart/dist/orgchart.css'
import {mapState,mapGetters,mapActions} from 'vuex'
export default {
    components:{
        OrganizationChart
    },
    data(){
        return {
            ds:{
                "id": 1,
                "name": "Stage1",
                "title": "ji",
                "size": "ji",
                "children":[
                    {
                        "id": 20,
                        "name": "Stage1",
                        "title": "hhdh"
                    },
                    {
                        "id": 10,
                        "name": "Stage1",
                        "title": "hhdh"
                    }
                ] 
            }
        }
    },
    computed:{
        ...mapState({
            loading:state=>state.loading
        }),

        ...mapGetters('userStore',['genealogy'])
    },
    methods:{
        ...mapActions('userStore',['getGenealogy'])
    },

    created(){
        if(this.genealogy == null){
            this.getGenealogy()
        }
    }
}
</script>