<template>
    <div class="d-flex row row-eq-height my-3">
        <div class="col-md-8">
            <div class="card">
                <div class="card-header white">
                    <div class="row justify-content-end">
                        <div class="col">
                            <ul class="nav nav-tabs card-header-tabs nav-material">
                                <li class="nav-item">
                                    <a class="nav-link active show" id="w1-tab1" data-toggle="tab" href="#v-pills-w1-tab1">Incentives</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card-body no-p">
                    <div class="tab-content">
                        <div>
                            <div class="row p-3">
                                <div class="col-md-6">
                                    <img class="img-responsive" height="300px" src="/assets/img/allgifts1.png">
                                </div>
                                <div class="col-md-6">
                                    <div class="card-body pt-0">
                                        <h6>Cash Equivalent</h6>
                                        <div class="my-3">
                                            <div>
                                                <small>None</small>
                                            </div>
                                        </div>
                                        <hr>
                                        <h6>Description</h6>
                                        <div class="my-3">
                                            <div>
                                                <small>None</small>
                                            </div>
                                        </div>
                                        <hr>
                                        <h6>Incentives</h6>
                                        <div class="my-3">
                                            <div>
                                                <small>None</small>
                                            </div>
                                        </div>										
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div class="col-md-12" >
                    <div class="form-row">
                        <div class="col-md-12 mb-3">

                            <button class="btn btn-success" type="submit" >Claim Incentive</button>
                        </div>
                    </div>
                </div>
                <br>
            </div>
            <div class="row row-eq-height">

            </div>
        </div>
        <div class="col-md-4">
            <div class="white">
                <div class="card">
                    <div class="card-body no-p">
                        <div class="tab-content">
                            <div class="tab-pane animated fadeIn show active" id="v-pills-tab1" role="tabpanel" aria-labelledby="v-pills-tab1">
                                <div class="bg-success text-white lighten-2">
                                    <div class="pt-5 pb-2 pl-5 pr-5">
                                        <h5 class="font-weight-normal s-14"><strong>GRANT INCENTIVE</strong></h5>
                                        <span class="s-48 font-bold-lighter text-success"><small>$</small>5.7</span>
                                        <div class="float-center">
                                            <img class="gift" src="/assets/img/grant.png">
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>