import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/router/auth-routes'
import user from '@/router/user-routes'
import admin from '@/router/admin-routes'

Vue.use(VueRouter)

 const routes = [
     //{
//         path: '/',
//         name: 'Home',
//         component: Home
//     },
//     {
//         path: '/about',
//         name: 'About',
//         // route level code-splitting
//         // this generates a separate chunk (about.[hash].js) for this route
//         // which is lazy-loaded when the route is visited.
//         component: () =>
//             import ( /* webpackChunkName: "about" */ '../views/About.vue')
//     },
    ...auth,
    ...user,
    ...admin
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router