<template>
    <div class="tab-pane fade" id="v-pills-upload" role="tabpanel" aria-labelledby="v-pills-upload-tab">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="card no-b">
                    <div class="card-header bg-white text-green">
                        <h4 class="card-title green-text"><strong class="font-weight-bold">Upload Profile Picture</strong></h4>
                    </div>
                    <div class="collapse show" id="invoiceCard">
                        <div class="card-body p-0">
                            <div class="card no-b  no-r">
                                <div class="card-body">
                                    <div class="form-row">
                                        <div class="col-md-8 offset-md-2">
                                            <form class="form-horizontal" id="update-profile-photo-form" action="/user/updateProfilePhoto" method="POST" enctype="multipart/form-data">
                                                <div class="form-group">
                                                    <div class="col-md-12">
                                                        <input required type="file" name="img" data-default-file="http://amazinglobalnetwork.com/profile-images/default.png" class="form-control form-control-line dropify">
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="col-sm-12">
                                                        <input type="hidden" name="id" value="278">
                                                        <button class="btn btn-success" id="update-profile-photo">Update Profile Photo</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>                    
    </div>
</template>
<script>
export default {
    
}
</script>